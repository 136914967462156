export const SCHEMA_PATH_URL = `${process.env.REACT_APP_SCHEMA_PATH_URL}`;
export const TOKEN_AUTH = process.env.REACT_APP_TOKEN_AUTH;
export const GOOGLE_VISION_API = process.env.REACT_APP_GOOGLE_CLOUD_VISION_API;
export const GOOGLE_VISION_API_KEY =
  process.env.REACT_APP_GOOGLE_CLOUD_VISION_API_KEY;
export const WO_VERSION = process.env.REACT_APP_WO_VERSION || '';
export const GET_PACKAGE_URL = process.env.REACT_APP_GET_PACKAGE_URL || '';

//Firebase config

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
