import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      backgroundColor: '#151515',
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      padding: 20,
    },
  });
};
