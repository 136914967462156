import React from 'react';
import {Switch} from 'react-native';
import {makeStyles} from './index.styles';

interface PrimarySwitchProps {
  switchStyle: SwitchStyle;
  onValueChange: () => void;
  isEnabled: boolean;
}

interface SwitchStyle {
  trackColorFalse: string;
  trackColorTrue: string;
  iosColor: string;
  thumbColorFalse: string;
  thumbColorTrue: string;
  style?: any;
}

export const PrimarySwitch = ({
  switchStyle,
  onValueChange,
  isEnabled,
}: PrimarySwitchProps) => {
  const {
    trackColorFalse,
    trackColorTrue,
    iosColor,
    thumbColorFalse,
    thumbColorTrue,
    style,
  } = switchStyle;

  const styles = makeStyles();
  return (
    <Switch
      trackColor={{false: trackColorFalse, true: trackColorTrue}}
      thumbColor={isEnabled ? thumbColorTrue : thumbColorFalse}
      ios_backgroundColor={iosColor}
      onValueChange={onValueChange}
      value={isEnabled}
      style={style}
    />
  );
};

PrimarySwitch.defaultProps = {};
