import React, {useEffect, useState} from 'react';
import {Camera} from 'expo-camera';

import {
  Footer,
  Header,
  ContainerScreen,
  PersonnelRecordContent,
  PrimaryIconLink,
  SideMenu,
  warehousesProps,
  PrimaryLoader,
} from '../../components';
import {callGoogleVisionApi} from '../../common/integrations/googleVision';
import {useGetCatalogZonesAndActionsQuery} from '../../common/integrations/wo-api/generates';
import client from '../../common/integrations/wo-api/client';
import {BaseContext} from '../../context';
import {makeStyles} from './index.styles';

interface dataProps {
  name: string;
  idCarNumber: string;
  firstActivity: string;
  lastActivity: string;
}

export const PersonnelRecord = () => {
  const ReadContext = React.useContext(BaseContext);
  const [sideMenu, setSideMenu] = useState(false);
  const [data, setData] = useState<dataProps>();
  const [isPartner, setIsPartner] = useState('');
  const [zoneNames, setZoneNames] = useState<Array<warehousesProps>>([]);
  const [zoneName, setZoneName] = useState('');
  const [actionNames, setActionNames] = useState<Array<warehousesProps>>([]);
  const [actionName, setActionName] = useState('');
  const [cameraRef, setCameraRef] = useState(null);
  const [imageUri, setImageUri] = useState('');
  const [readedText, setReadedText] = useState('');
  const [flash, setFlash] = useState(Camera.Constants.FlashMode.off);

  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);

  // TO DO: Verificar si hay zonas especificas que se necesitarán para este escaneo

  /*const {
    status: catalogQueryStatus,
    data: catalogQueryData,
    error: catalogQueryError,
    isFetching: zonesQueryIsFetching,
  } = useGetCatalogZonesAndActionsQuery(client, {
    filter: {warehouseId: {eq: ReadContext.whId}},
  });

  useEffect(() => {
    if (catalogQueryData) {
      setZoneNames(catalogQueryData.zones.nodes);
      setActionNames(catalogQueryData.actionTypes.nodes);
      setZoneName(catalogQueryData.zones.nodes[0].id);
      setActionName(catalogQueryData.actionTypes.nodes[0].id);
    }
  }, [catalogQueryData]);
*/
  useEffect(() => {
    (async () => {
      const hasCamPermissions = await Camera.getPermissionsAsync();
      // @ts-ignore
      setHasPermission(hasCamPermissions.status === 'granted');
    })();
  }, []);

  const actionTypesOptions = [
    {id: '1', name: 'Ingreso'},
    {id: '2', name: 'Salida'},
  ];
  const zonesNameOptions = [
    {id: '1', name: 'iOffice'},
    {id: '2', name: 'iHub'},
    {id: '3', name: 'iBath'},
    {id: '4', name: 'iCamps'},
  ];

  const sideMenuOptions = [
    {name: 'Home', route: '/home', icon: 'ios-home'},
    {
      name: 'Categorías iVoy',
      route: '/search-categories',
      icon: 'apps-outline',
    },
  ];

  const courierInformation = [
    {
      columnOne: 'Usuario: ',
      columnTwo: isPartner,
    },
    {
      columnOne: 'Nombre: ',
      columnTwo: data?.name,
    },
    {
      columnOne: 'Matrícula: ',
      columnTwo: data?.idCarNumber,
    },
    {
      columnOne: 'Miembro desde: ',
      columnTwo: data?.firstActivity,
    },
    {
      columnOne: 'Última actividad: ',
      columnTwo: data?.lastActivity,
    },
  ];

  const styles = makeStyles({backgroundColor: '#DEE3F3'});
  const optionsFooter = [
    {name: 'Home', icon: 'ios-home', route: '/home'},
    {
      name: 'Enviar',
      icon: 'send-outline',
      onPress: () => console.log('Enviando registro de personal'),
    },
  ];
  /*
  if (zonesQueryIsFetching) {
    return <PrimaryLoader />;
  }*/
  const handleSideMenu = () => {
    setSideMenu(!sideMenu);
  };

  // Camera methods

  const handleTakePhoto = async () => {
    setReadedText('Recognizing ...');
    const features = [
      {type: 'TEXT_DETECTION', maxResults: 5},
      {type: 'DOCUMENT_TEXT_DETECTION', maxResults: 5},
    ];
    if (cameraRef) {
      // @ts-ignore
      let photo = await cameraRef.takePictureAsync();
      const base64Img = photo.base64.slice(22);
      setImageUri(base64Img);
      await callGoogleVisionApi(base64Img, setReadedText, features);
    }
  };

  // Search id card number methods

  const handleSearchButton = () => {
    setData({
      name: 'Diego iVoy',
      idCarNumber: '12345',
      firstActivity: '13/may/2019',
      lastActivity: '12/may/2021',
    });
    setIsPartner('Mensajero');
  };

  // Style variables

  const buttonInfo = {
    buttonName: 'search-outline',
    buttonSize: 20,
    buttonStyle: styles.searchButton,
    buttonIconStyle: styles.searchIcon,
  };
  const iconHeaderInfo = {
    iconName: 'md-card-outline',
    iconColor: '#942E83',
    iconSize: 16,
    iconStyle: styles.categoryIcon,
  };
  const textHeaderInfo = {
    textContent: 'Registro de personal',
    textColor: '#121212',
    fontSize: 16,
    fontWeight: 700,
  };
  const indicatorInfo = {
    width: 230,
  };
  return (
    <>
      {sideMenu && (
        <SideMenu
          sideMenuOptions={sideMenuOptions}
          onPress={() => console.log('pressed')}
          onPressOutside={() => handleSideMenu()}
        />
      )}
      <ContainerScreen
        header={
          <Header
            title={'Escaneo'}
            style={styles.header}
            complement={
              <PrimaryIconLink
                onPress={handleSideMenu}
                style={styles.menuIconStyle}
                iconName={'menu'}
                iconSize={28}
              />
            }
          />
        }
        footer={<Footer options={optionsFooter} />}>
        <PersonnelRecordContent
          onPressSearch={handleSearchButton}
          buttonInfo={buttonInfo}
          iconHeaderInfo={iconHeaderInfo}
          textHeaderInfo={textHeaderInfo}
          indicatorInfo={indicatorInfo}
          data={data}
          isPartner={isPartner}
          zoneName={zoneName}
          actionName={actionName}
          zoneNames={zonesNameOptions}
          actionNames={actionTypesOptions}
          onChangeZone={(value: string) => setZoneName(value)}
          onChangeAction={(value: string) => setActionName(value)}
          type={type}
          setCameraRef={setCameraRef}
          onPressPhoto={handleTakePhoto}
          permissions={hasPermission}
          photo={imageUri}
          readedText={readedText}
          setReadedText={setReadedText}
          autoFocus={Camera.Constants.AutoFocus.on}
          courierData={courierInformation}
        />
      </ContainerScreen>
    </>
  );
};
