import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {Camera} from 'expo-camera';

import {PrimaryIconLink} from '../PrimaryIconLink';
import {PrimaryText} from '../PrimaryText';
import {makeStyles} from './index.styles';

interface PrimaryCameraProps {
  setCameraRef: (value: any) => void;
  onPressPhoto: () => void;
  type: any;
  autoFocus?: any;
  readedText?: string;
  setReadedText?: (value: string) => void;
}

export const PrimaryCamera = ({
  setCameraRef,
  onPressPhoto,
  type,
  autoFocus,
  readedText,
  setReadedText,
}: PrimaryCameraProps) => {
  // Style Variable
  const styles = makeStyles();
  return (
    <View style={styles.container}>
      <View style={styles.cameraButtonsContainer}>
        {readedText !== '' && setReadedText && (
          <PrimaryIconLink
            onPress={() => setReadedText('')}
            style={{}}
            iconName={'remove-circle'}
            iconSize={28}
            iconStyle={styles.swipeCameraIcon}
          />
        )}
      </View>
      <Camera
        style={styles.camera}
        type={type}
        autoFocus={autoFocus}
        ref={(ref) => {
          // @ts-ignore
          setCameraRef(ref);
        }}>
        <View style={styles.cameraContent}>
          {readedText !== '' && (
            <View style={styles.textReaded}>
              <PrimaryText
                content={readedText}
                color={'#fff'}
                fontWeight={700}
              />
            </View>
          )}
        </View>
      </Camera>
      <View style={styles.cameraButtonsContainer}>
        <TouchableOpacity onPress={onPressPhoto}>
          <View
            style={[styles.cameraPhotoIconContainer, styles.sizesContainer]}>
            <View
              style={[styles.cameraPhotoIconContainer, styles.sizesInner]}
            />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};
