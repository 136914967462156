import React from 'react';
import {View} from 'react-native';
import {makeStyles} from './index.styles';

interface PrimaryOutputStatusProps {
  backgroundColor?: any;
  width?: number;
  height?: number;
}

export const PrimaryOutputStatus = ({
  backgroundColor,
  width,
  height,
}: PrimaryOutputStatusProps) => {
  const styles = makeStyles({backgroundColor, width, height});
  return <View style={styles.container} />;
};

PrimaryOutputStatus.defaultProps = {
  backgroundColor: '#000',
  width: 13,
  height: 13,
};
