import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  backgroundColor?: ColorValue;
  width?: number | string;
}

export const makeStyles = ({backgroundColor, width}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      backgroundColor,
      width,
      color: '#fff',
      fontWeight: 'bold',
      padding: 7,
      borderRadius: 4,
    },
  });
};
