import React from 'react';
import {View} from 'react-native';
import {makeStyles} from './index.styles';

import {PrimaryText} from '../PrimaryText';
import {PrimaryIndicator} from '../PrimaryIndicator';

interface CountersComponentProps {
  counter: any;
}

export const PrimaryCounter = ({counter}: CountersComponentProps) => {
  const styles = makeStyles({});

  return (
    <View style={styles.container}>
      <PrimaryIndicator width={'50%'} />
      <PrimaryText content={counter.name} fontSize={14} fontWeight={'700'} />
      <PrimaryText
        content={counter.packages}
        fontSize={13}
        fontWeight={'300'}
      />
    </View>
  );
};

PrimaryCounter.defaultProps = {
  counter: {
    name: 'Counter 1',
    packages: 23,
  },
};
