import {storage} from '../../../storage';
import {useEffect, useState} from 'react';

export const useStorageItem = (
  storageItemName: string,
  setStateFunction?: (newState: any) => void,
): any => {
  const [storageItem, setStorageItem] = useState<any>();

  useEffect(() => {
    setStateFunction
      ? //@ts-ignore
        setStateFunction(storage.cache?.[storageItemName]?.rawData)
      : //@ts-ignore
        setStorageItem(storage.cache?.[storageItemName]?.rawData);
  }, [storageItemName, setStateFunction]);

  return setStateFunction ? undefined : storageItem;
};

//1)
// useLoadManifest('manifestData', setManifestInput);

//2)
// const manifestData = useLoadManifest('manifestData');

// useEffect(() => {
//   //@ts-ignore
//   setManifestInput(manifestData);
// }, [manifestData]);
