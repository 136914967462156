import React from 'react';
import {View, TouchableOpacity, TextInput, Platform} from 'react-native';
import {useAuth0} from '@auth0/auth0-react';
import Clipboard from '@react-native-clipboard/clipboard';
import Icon from 'react-native-vector-icons/Ionicons';

import {handleAnalytics} from '../../../common/integrations/firebase';
import {WO_VERSION} from '../../../config';
import {makeStyles} from './index.styles';

interface InputSearchProps {
  style?: any;
  onChange: (args: any) => void;
  placeholder?: string;
  value?: string;
  onPressButtonScan?: (args: any) => void;
  onPressButtonSearch?: (args: any) => void;
  onKeyPress?: (args: any) => void;
  refInput?: (args: any) => void;
  iconName: string;
}

export const InputSearch = ({
  style,
  onChange,
  placeholder,
  value,
  onPressButtonScan,
  onPressButtonSearch,
  onKeyPress,
  refInput,
  iconName,
}: InputSearchProps) => {
  const {user} = useAuth0();
  const styles = makeStyles(style);

  const handleClipboard = async () => {
    const {name, email} = user;
    const userProps = {name, email, version: WO_VERSION};
    handleAnalytics('search_scan_copying', {}, userProps);

    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      Platform.OS === ('android' || 'ios')
        ? Clipboard.setString('')
        : //@ts-ignore
          await window.navigator.clipboard.writeText('');
    }
  };

  return (
    <View removeClippedSubviews={true} style={[styles.container, style]}>
      <View style={styles.iconRow}>
        <TouchableOpacity onPress={onPressButtonSearch}>
          <Icon name={iconName} style={styles.iconSearch} />
        </TouchableOpacity>
      </View>
      <TextInput
        ref={refInput}
        contextMenuHidden={true}
        style={styles.input}
        placeholder={placeholder}
        value={value}
        onChangeText={onChange}
        placeholderTextColor={styles.placeholder.color}
        onKeyPress={onKeyPress}
        onSelectionChange={handleClipboard}
        multiline
      />
      <TouchableOpacity onPress={onPressButtonScan}>
        <Icon name="scan-outline" size={20} style={styles.iconScan} />
      </TouchableOpacity>
    </View>
  );
};

InputSearch.defaultProps = {
  style: {
    borderWidth: 1,
    borderColor: '#000000',
    borderRadius: 4,
  },
  onChange: () => console.log('action'),
  placeholder: 'Número de guía',
  onPressButtonScan: () => console.log('action'),
  onPressButtonSearch: () => console.log('action'),
  refInput: (ref: any) => null,
};
