import {StyleSheet} from 'react-native';

interface StyleProps {}

export const makeStyles = ({}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      flex: 1,
    },
    flatList: {
      width: '100%',
      height: '100%',
    },
    textScan: {
      textAlign: 'center',
      paddingTop: '50%',
    },
  });
};
