import React from 'react';
import {TouchableOpacity, Text} from 'react-native';
import {makeStyles} from './index.styles';
import Icon from 'react-native-vector-icons/Ionicons';
import {Link} from '../../../navigation/react-router';

export interface OptionProps {
  name: string;
  icon: string;
  style?: any;
  route?: any;
  iconStyle?: any;
  onPress?(): void;
}

export const Option = ({
  route,
  onPress,
  name,
  icon,
  style,
  iconStyle,
}: OptionProps) => {
  const styles = makeStyles(style);
  if (route) {
    return (
      <Link
        to={route}
        component={TouchableOpacity}
        style={[styles.container, style]}>
        <>
          <Icon name={icon} style={[styles.icon, iconStyle]} />
          <Text style={styles.limpiar}>{name}</Text>
        </>
      </Link>
    );
  }
  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, style]}>
      <>
        <Icon name={icon} style={[styles.icon, iconStyle]} />
        <Text style={styles.limpiar}>{name}</Text>
      </>
    </TouchableOpacity>
  );
};

Option.defaultProps = {
  route: null,
  name: 'Limpiar',
  icon: 'broom',
  style: {
    height: 72,
    width: 72,
    alignSelf: 'center',
  },
};
