import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      padding: 35,
      alignItems: 'center',
    },
    categoriesContainer: {
      width: '100%',
      maxWidth: '50%',
      minWidth: 300,
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: 12,
      marginTop: 40,
      alignItems: 'center',
    },
    containerOption: {
      width: '18%',
      minWidth: 100,
      height: 0,
      minHeight: 114,
      maxHeight: 159,
      paddingTop: '21%',
      borderRadius: 4,
      margin: '2%',
      alignItem: 'center',
      justifyContent: 'center',
    },
  });
};
