import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  width?: number | string;
  backgroundColor?: ColorValue;
}

export const makeStyles = ({width, backgroundColor}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      width,
      maxWidth: 800,
      alignSelf: 'center',
      paddingHorizontal: 20,
    },
    loaderStyle: {
      width: '100%',
      height: 'calc(100vh - 237px)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconRow: {
      width: '100%',
    },
    icon: {
      color: backgroundColor ? backgroundColor : '#ff35c2',
      fontSize: 40,
    },
    containerTrackingNumber: {
      flex: 1,
      borderRadius: 4,
      paddingVertical: 25,
      backgroundColor: '#0064e0',
      alignItems: 'center',
      textAlign: 'center',
    },
    packageItemsContainer: {
      width: '100%',
      height: 'fit-content',
      marginTop: 10,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    orderContainer: {
      height: 'fit-content',
      flexGrow: 2,
      borderRadius: 4,
      paddingVertical: 25,
      paddingHorizontal: 24,
      backgroundColor: '#00e0da',
      marginRight: 15,
      alignItems: 'center',
      justifyContent: 'center',
    },
    destinyContainer: {
      flexDirection: 'row',
      height: 'fit-content',
      flexGrow: 3,
      borderRadius: 4,
      paddingVertical: 25,
      paddingHorizontal: 24,
      backgroundColor: backgroundColor,
      alignItems: 'center',
      justifyContent: 'center',
    },
    trackingNumberText: {
      color: '#fff',
      fontSize: 18,
      fontWeight: '700',
    },
    destinyText: {
      fontSize: 24,
      fontWeight: '700',
      color: '#fff',
    },
    zoneCoverageText: {
      fontSize: 18,
      fontWeight: '900',
      color: '#fff',
    },
    packageInfoTitle: {
      position: 'absolute',
      color: '#f1f1f2',
      fontSize: 11,
      letterSpacing: 0.5,
      fontWeight: '900',
      bottom: 5,
      right: 5,
    },
    packageInfoValue: {
      marginTop: 5,
      color: '#000',
      fontSize: 15,
      fontWeight: 700,
    },
    primaryItemList: {
      width: '100%',
      backgroundColor: '#F8F9FC',
    },
    printIconContainer: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
    printIconContainerLeft: {
      position: 'absolute',
      top: 5,
      left: 5,
    },
    printIconLink: {
      color: '#fff',
    },
    backgroundTech: {
      backgroundColor: 'transparent',
    },
  });
};
