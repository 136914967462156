import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    modalContainer: {
      backgroundColor: 'rgba(232,239,248,0.4)',
    },
    modal: {
      position: 'absolute',
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      top: 0,
      right: 0,
      height: 'fit-content',
      minHeight: 250,
      backgroundColor: 'red',
      paddingTop: 0,
      paddingBottom: 0,
    },
    container: {
      width: 250,
      paddingTop: 20,
      paddingBottom: 40,
      borderBottomLeftRadius: 5,
      backgroundColor: '#000',
    },
    menuButton: {
      width: 22,
      height: 22,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-end',
      marginRight: 20,
    },
    menuIcon: {
      color: '#fff',
    },
    user: {
      width: '100%',
      padding: 5,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 5,
    },
    userSvg: {
      width: '100%',
      height: '5rem',
      resizeMode: 'contain',
    },
    icon: {
      marginRight: 10,
    },
    userIcon: {
      width: 80,
      height: 80,
      marginBottom: 5,
    },
    menu: {
      flexDirection: 'row',
      width: '100%',
      height: 37,
      alignItems: 'center',
      justifyContent: 'center',
    },
    categoriesContainer: {
      alignItems: 'center',
    },
    logoutButtonContainer: {
      width: '100%',
      height: 27,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 10,
    },
    logoutButtonCont: {
      width: '50%',
    },
    logoutButton: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#66cdaa',
    },
    logoutButtonTitle: {
      marginTop: 0,
      color: '#000',
    },
    versionContainer: {
      alignSelf: 'center',
      marginTop: 10,
    },
    appVersion: {
      fontSize: 11,
      fontStyle: 'italic',
      color: '#fff',
    },
    link: {
      textDecorationStyle: undefined,
    },
  });
};
