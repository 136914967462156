import {StyleSheet, ColorValue} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};
