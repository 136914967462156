import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      maxHeight: 600,
    },
    headerContainer: {
      paddingHorizontal: 35,
      paddingTop: 35,
    },
    ocrContainer: {
      width: '100%',
      marginTop: 10,
      marginBottom: 25,
      paddingHorizontal: 35,
    },
    searchContainer: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingHorizontal: 35,
    },
    outputInformation: {
      width: '100%',
      marginTop: 25,
      paddingVertical: 10,
      backgroundColor: '#DEE3F3',
      paddingHorizontal: 35,
    },
    outputInner: {
      width: '100%',
      flexDirection: 'row',
      marginTop: 12,
      marginBottom: 5,
    },
    innerInfo: {
      width: '100%',
      justifyContent: 'center',
      paddingLeft: 25,
    },
    outputRow: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 10,
    },
    registryContainer: {
      width: '100%',
      paddingHorizontal: 35,
      paddingTop: 10,
    },
    selectionContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: 7,
    },
    dropsContainer: {
      width: '45%',
      marginTop: 20,
    },
    personnelRegistry: {
      width: '100%',
      paddingHorizontal: 35,
      marginBottom: 10,
    },
    inputStyle: {
      width: '80%',
      height: 28,
      borderWidth: 1,
      borderColor: '#000',
      borderRadius: 4,
    },
    cameraContainer: {
      width: 300,
      height: 300,
      alignSelf: 'center',
    },
    camera: {
      width: '100%',
      height: '100%',
    },
    cameraButton: {
      backgroundColor: 'transparent',
      width: 37,
      height: 37,
      borderRadius: 4,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cameraIcon: {
      color: '#000',
      fontWeight: '700',
    },
    imageContainer: {
      width: '40%',
      maxWidth: 130,
      height: '10rem',
      resizeMode: 'contain',
      borderWidth: 4,
      borderColor: '#000',
      borderRadius: 4,
    },
    profileImage: {
      flex: 1,
    },
  });
};
