import React, {useCallback} from 'react';
import {FlatList, View, Text} from 'react-native';
import {makeStyles} from './index.styles';
import {PrimaryPackage} from '../../';

interface PackagesListProps {
  packages: any;
  onPress?: (id: number) => void;
  isDelete: boolean;
}

export const PackagesList = ({
  packages,
  onPress,
  isDelete,
}: PackagesListProps) => {
  const styles = makeStyles({});

  const keyExtractor = useCallback((item, index) => index.toString(), []);
  const color = {backgroundColor: isDelete ? '#f6f6fe' : '#F8F9FC'};
  return (
    <View style={styles.container}>
      <FlatList
        style={styles.flatList}
        keyExtractor={keyExtractor}
        windowSize={15}
        data={packages}
        inverted={true}
        renderItem={(itemData) => (
          <PrimaryPackage
            key={itemData.item.internalId}
            onPress={() => !!onPress && onPress(itemData.item.internalId)}
            pack={itemData.item}
            style={color}
          />
        )}
      />
      {packages.length === 0 && (
        <Text style={styles.textScan}>No hay paquetes escaneados</Text>
      )}
    </View>
  );
};

PackagesList.defaultProps = {
  onPress: () => console.log('Delete item'),
};
