import {StyleSheet} from 'react-native';

import shadow from '../../../common/style';

interface StyleProps {
  height: number | string;
  justifyContent:
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-evenly'
    | undefined;
}

export const makeStyles = ({
  height,
  justifyContent: justify,
}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      height,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      flexDirection: 'row',
      justifyContent: justify || 'space-between',
      alignContent: 'center',
      paddingHorizontal: '7%',
      paddingVertical: '4%',
      ...shadow({
        elevation: 7,
        color: 'rgba(227, 228, 229, 0.5)',
        opacity: 0.15,
        radius: 10,
        offsetWidth: 0,
        offsetHeight: -7,
      }),
    },
  });
};
