import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      flex: 1,
      paddingBottom: 30,
      textAlign: 'center',
    },
    listContainer: {
      height: 100,
    },
    list: {
      flexDirection: 'column-reverse',
      flex: 1,
    },
    errorContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    title: {
      color: '#383434',
      fontSize: 17,
      fontWeight: '700',
      marginBottom: 15,
    },
    subTitle: {
      fontSize: 14,
      marginBottom: 5,
    },
    highlightedText: {
      backgroundColor: '#eef0f7',
      marginBottom: 5,
      width: 'fit-content',
      paddingVertical: 5,
      paddingHorizontal: 10,
      alignSelf: 'center',
      color: '#000',
      borderRadius: 4,
    },
  });
};
