import React from 'react';
import {View} from 'react-native';
import {makeStyles} from './index.styles';

interface PrimaryIndicatorProps {
  backgroundColor?: any;
  width?: any;
  height?: any;
  style?: any;
}

export const PrimaryIndicator = ({
  backgroundColor,
  width,
  height,
  style,
}: PrimaryIndicatorProps) => {
  const styles = makeStyles({backgroundColor, width, height});
  return <View style={[styles.container, style]} />;
};

PrimaryIndicator.defaultProps = {
  backgroundColor: '#000',
  width: '20%',
  height: 4,
};
