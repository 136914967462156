import React from 'react';
import {View, Text} from 'react-native';
import {makeStyles} from './index.styles';

import {PrimaryOutputStatus} from '../PrimaryOutputStatus';

interface PrimaryPackageProps {
  item: any;
  style: any;
}

export const PrimaryListItem = ({item, style}: PrimaryPackageProps) => {
  const styles = makeStyles(style);
  return (
    <View style={[styles.container, style]}>
      <PrimaryOutputStatus backgroundColor={item.color} />
      <View style={styles.contentContainer}>
        <Text style={styles.titleText}>{item.title}</Text>
        <Text style={styles.valueText}>{item.value}</Text>
      </View>
    </View>
  );
};

PrimaryListItem.defaultProps = {
  item: {
    title: 'Key',
    value: 'Value',
    color: '#D0021B',
  },
  onPress: () => console.log('Delete item'),
};
