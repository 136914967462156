import React from 'react';
import {makeStyles} from './index.styles';
import {View, ScrollView} from 'react-native';

interface ContainerScreenProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export const ContainerScreen = ({
  header,
  children,
  footer,
}: ContainerScreenProps) => {
  const styles = makeStyles();
  return (
    <View style={styles.container}>
      {header}
      <ScrollView style={styles.scroll}>{children}</ScrollView>
      {footer}
    </View>
  );
};
