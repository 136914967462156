import React, {useEffect, useState} from 'react';
import {PrimaryText} from '../../index';

interface CountdownProps {
  seconds: number;
  setSeconds: (seconds: number) => void;
}

export const Countdown = (props: CountdownProps): JSX.Element => {
  useEffect(() => {
    let interval = setInterval(() => {
      if (props.seconds > 0) {
        props.setSeconds(props.seconds - 1);
      } else {
        return;
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <PrimaryText
      fontSize={20}
      fontWeight={'bold'}
      content={`${props.seconds}`}
    />
  );
};
