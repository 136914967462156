import {StyleSheet} from 'react-native';

interface StyleProps {
  height?: number | string;
  width?: number | string;
}

export const makeStyles = ({height, width}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      height,
      width,
    },
    orderRow: {
      flex: 1,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: 'rgba(145,163,176,1)',
      height: 20,
      width: 82,
      textAlign: 'center',
      fontSize: 13,
      marginTop: 19,
      marginLeft: 22,
      fontWeight: 700,
    },
    normalText: {
      color: 'black',
      height: 20,
      width: 82,
      textAlign: 'center',
      fontSize: 15,
      marginTop: 19,
      marginLeft: 22,
    },
  });
};
