import React, {useEffect, useState, useCallback} from 'react';
import {Camera} from 'expo-camera';

import {
  ConfirmContent,
  Footer,
  Header,
  ContainerScreen,
  Option,
  PrimaryText,
  SideMenu,
  PrimaryIconLink,
  PrimaryLoader,
  ConfirmModal,
  ManifestErrorsModal,
} from '../../components';
import {getStorageItem, saveOnStorage} from '../../common/utils';
import {
  useCreateManyActionsMutation,
  useGetAuthorizationTokenQuery,
} from '../../common/integrations/wo-api/generates';
import client from '../../common/integrations/wo-api/client';
import {Sound} from '../../common/sound/sound';
import {makeStyles} from './index.styles';
import {useAuth0} from '@auth0/auth0-react';

export const Confirm = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const [result, setResult] = useState('');
  const [selectedPicker, setSelectedPicker] = useState('');
  const [inputValue, onChangeInputValue] = useState('');
  const [userOutput, setUserOutput] = useState('');
  const [outputColor, setOutputColor] = useState('#D0021B');
  const [textColor, setTextColor] = useState('#fff');
  const [hasPermission, setHasPermission] = useState();
  const [hasErrors, setHasErrors] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const scanningSound = new Sound('/scanning.mp3');
  const scanningSoundError = new Sound('/error.mp3');
  const [showModalErrors, setShowModalErrors] = useState<boolean>(false);
  const {getIdTokenClaims} = useAuth0();
  const [loadToken, setLoadToken] = useState(true);

  const keepingsData = getStorageItem('keepings');
  const cacheData = getStorageItem('movementObj');
  const whId = getStorageItem('whId');
  const styles = makeStyles({
    backgroundColor: '#DEE3F3',
    textColor: textColor,
    textBackgroundColor: outputColor,
  });

  const {
    status: QueryAuthTokenStatus,
    data: QueryAuthTokenData,
    refetch: QueryAuthTokenReFetch,
  } = useGetAuthorizationTokenQuery(client, {}, {refetchOnWindowFocus: false});

  useEffect(() => {
    (async () => {
      const hasCamPermissions = await Camera.getPermissionsAsync();
      // @ts-ignore
      setHasPermission(hasCamPermissions.status === 'granted');
    })();
  }, []);

  useEffect(() => {
    // @ts-ignore
    const lastComment = getStorageItem('movementObj').comments;
    onChangeInputValue(lastComment);
  }, []);

  const sideMenuOptions = [
    {name: 'Home', route: '/home', icon: 'ios-home'},
    {
      name: 'Categorías iVoy',
      route: '/search-categories',
      icon: 'apps-outline',
    },
  ];

  const {
    status: manyMutationStatus,
    data: manyMutationData,
    error: manyMutationError,
    mutate: manyMutationMutate,
  } = useCreateManyActionsMutation(client);

  const handleCleanPackages = useCallback(() => {
    const obj = {
      warehouseId: cacheData.warehouseId,
      warehouseName: 'Hub Mex',
      comments: '',
      transactions: [
        {
          zoneId: '',
          zoneName: '',
          actionTypeId: '',
          actionTypeName: 'INGRESO',
          comments: '',
          packages: [],
        },
        {
          zoneId: '',
          zoneName: '',
          actionTypeId: '',
          actionTypeName: 'PENDIENTE',
          comments: '',
          packages: [],
        },
      ],
    };
    const clearManifest = {
      id: '',
      count: '',
      guides: [],
    };
    saveOnStorage('manifestExceeds', []);
    saveOnStorage('manifestData', clearManifest);
    saveOnStorage('manifestMissing', []);
    saveOnStorage('errorsButton', false);
    saveOnStorage('packagesList', []);
    saveOnStorage('movementObj', obj);
  }, [cacheData.warehouseId]);

  useEffect(() => {
    if (manyMutationData !== null && !!manyMutationData) {
      const newModalData = {
        id: manyMutationData?.createManyActions?.manifest?.id || '',
        count:
          manyMutationData?.createManyActions?.manifest?.packages.count || '',
      };
      setModalData(newModalData);
      setModalIsOpen(true);
    }
    if (manyMutationError !== undefined && manyMutationError !== null) {
      // @ts-ignore
      setHasErrors(manyMutationError.response.errors);
    }
    if (manyMutationStatus === 'success') {
      handleCleanPackages();
    }
  }, [
    handleCleanPackages,
    manyMutationData,
    manyMutationError,
    manyMutationStatus,
  ]);

  useEffect(() => {
    if (result === '') {
      setOutputColor('#D0021B');
      setTextColor('#fff');
      setUserOutput('Sin autorización. Escanea tu código para poder continuar');
    } else {
      if (result === 'rescan') {
        setOutputColor('#D0021B');
        setTextColor('#fff');
        setUserOutput('Código expirado. Vuelve a escanear');
      } else {
        setOutputColor('#50E3C2');
        setTextColor('#000');
        setUserOutput('Código escaneado');
      }
    }
  }, [result]);

  const setTimeOut = (value: string, ms: number) => {
    setTimeout(function () {
      setResult(value);
    }, ms);
  };

  const send = async () => {
    setHasErrors([]);
    let token: string;
    if (whId === 'Q3xN1P4jEA7kGqE3kw_C5CAL') {
      token = await QueryAuthTokenReFetch().then((r) => {
        let tkn;
        if (r.status === 'success') {
          tkn = r.data?.getAuthorizationToken?.authToken || '';
        } else {
          tkn = result;
        }
        return tkn;
      });
    } else {
      token = result;
    }

    if (token === '' || token === 'rescan') {
      scanningSoundError.play();
    } else {
      const finalData = {...cacheData};
      finalData.comments = finalData.comments || ' ';
      finalData.transactions.forEach(
        (transaction: any) => (transaction.comments = finalData.comments),
      );

      manyMutationMutate({
        input: finalData,
        authToken: token,
      });
      setTimeOut('rescan', 30000);
      if (keepingsData) {
        saveOnStorage('keepings', false);
      }
    }
  };

  const optionsFooter = [
    {name: 'Home', icon: 'ios-home', route: '/home'},
    {
      name: 'Enviar',
      icon: 'send-outline',
      onPress: () => {
        send();
      },
    },
  ];

  function saveCommentOnStorage(comment: string) {
    const trans = cacheData.transactions;
    const newTransaction = Object.values(trans).map((item: any) => {
      return {...item, comments: ''};
    });
    const obj = {
      ...cacheData,
      comments: comment,
      transactions: newTransaction,
    };
    saveOnStorage('movementObj', obj);
  }

  const onChange = (e: any) => {
    onChangeInputValue(e);
    saveCommentOnStorage(e);
  };

  const handleSelectedPicker = (picker: string) => {
    setSelectedPicker(picker);
  };

  const handlePickerStyle = (picker: string, icon: string) => {
    if (selectedPicker === picker) {
      return (
        <Option
          name={picker}
          icon={icon}
          onPress={() => {
            handleSelectedPicker(picker);
          }}
          style={styles.pickerOptionSelected}
        />
      );
    }
    return (
      <Option
        name={picker}
        icon={icon}
        onPress={() => {
          handleSelectedPicker(picker);
        }}
      />
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getIdTokenClaims();
        await saveOnStorage('token', token.__raw);
        client.setHeader('Authorization', token.__raw);

        setLoadToken(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getIdTokenClaims]);

  if (loadToken) {
    return <PrimaryLoader />;
  }

  const handleScanOutput = () => {
    return (
      <>
        {hasErrors?.length > 0 && manyMutationStatus !== 'success'
          ? hasErrors.map((item, index) => {
              return (
                <PrimaryText
                  key={index} // @ts-ignore
                  content={item.message}
                  style={[styles.highlightText, styles.errorText]}
                />
              );
            })
          : null}
        <PrimaryText
          content={`${userOutput}`}
          style={styles.highlightText}
          color={outputColor}
        />
      </>
    );
  };

  const handleScan = (data: any) => {
    if (data && data !== result) {
      scanningSound.play();
      setResult(data);
      setTimeOut('rescan', 30000);
    }
  };

  const handleError = (err: any) => {
    console.error('qr scan error', err);
  };

  const handleSideMenu = () => {
    setSideMenu(!sideMenu);
  };

  const style = {width: 250, height: 250};

  return (
    <>
      {sideMenu && (
        <SideMenu
          sideMenuOptions={sideMenuOptions}
          onPress={() => console.log('pressed')}
          onPressOutside={() => handleSideMenu()}
        />
      )}
      {showModalErrors && (
        <ManifestErrorsModal
          onPressOutside={() => setShowModalErrors(false)}
          onPressNext={() => {}}
          buttonTitle={'Continuar'}
        />
      )}
      {modalIsOpen && <ConfirmModal data={modalData} />}
      <ContainerScreen
        header={
          <Header
            title={'Validación'}
            style={styles.header}
            complement={
              <PrimaryIconLink
                onPress={handleSideMenu}
                style={styles.menuIconStyle}
                iconName={'menu'}
                iconSize={28}
              />
            }
          />
        }
        footer={<Footer options={optionsFooter} />}>
        <ConfirmContent
          inputValue={inputValue}
          onChangeInputValue={onChange}
          error={manyMutationError}
          selectedPicker={selectedPicker}
          setSelectedPicker={setSelectedPicker}
          handleScanOutput={handleScanOutput}
          handlePickerStyle={handlePickerStyle}
          handleScan={handleScan}
          handleError={handleError}
          permissions={hasPermission}
          isLoading={manyMutationStatus}
          style={style}
          showModalErrors={() => setShowModalErrors(true)}
        />
      </ContainerScreen>
    </>
  );
};
