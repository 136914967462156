import React, {useState} from 'react';
import {View} from 'react-native';
import QrReader from 'react-qr-reader';

import {PrimaryIconLink} from '../PrimaryIconLink';
import {makeStyles} from './index.styles';

interface ScannerCameraProps {
  handleScan: (data: any) => void;
  handleError: (data: any) => void;
  style: any;
}

export const ScannerCamera = ({
  handleError,
  handleScan,
  style,
}: ScannerCameraProps) => {
  const [frontCam, setFrontCam] = useState(true);
  // Style Variable
  const styles = makeStyles();
  return (
    <View style={styles.container}>
      <View style={styles.cameraButtonsContainer}>
        <PrimaryIconLink
          onPress={() => setFrontCam((prevState) => !prevState)}
          style={{}}
          iconName={'ios-camera-reverse-outline'}
          iconSize={28}
          iconStyle={styles.swipeCameraIcon}
        />
      </View>
      <QrReader
        onError={handleError}
        onScan={handleScan}
        facingMode={frontCam ? 'user' : 'environment'}
        style={style}
      />
    </View>
  );
};
