import {StyleSheet} from 'react-native';

export const makeStyles = () => {
  return StyleSheet.create({
    row: {
      flexDirection: 'row',
      marginBottom: 10,
    },
    title: {
      marginTop: '-10px',
      marginBottom: '24px',
      marginHorizontal: 'auto',
    },
    qrContainer: {
      marginHorizontal: 'auto',
      marginBottom: 30,
    },
  });
};
