import axios, {AxiosError} from 'axios';
import {JSPMPrint} from '../JSPM';

const printUrl = process.env.REACT_APP_PRINT_URL || '';

// react-query fetch with axios
export async function sendPrint(
  packageInfo: any,
  config: any,
  setDisplayToast: any,
) {
  const [routeId, routeSequence] = packageInfo.order.split(' ');
  const route = routeId ? routeId.slice(routeId.length - 4) : '-';
  const [guide, guideClient, multiguideSequence] =
    packageInfo.trackingNumber.split('\n');
  const data: any = {
    printer: config.printer || '',
    order: route,
    guide: guide || '',
    guideClient: guideClient || '',
    total: 0,
    subseq: multiguideSequence?.slice(1, -1) || '',
    sequence: routeSequence?.slice(1, -1) || '',
    zone: packageInfo.zoneName || '',
    deviceId: config.device || '',
  };

  // 1 for Cloud, 2 for Local
  if (config.localPrint === '1' && config.printer.includes(':')) {
    await axios({
      method: 'POST',
      url: printUrl,
      data,
    })
      .then((result: any) => {
        console.log('se mandó la impresión');
      })
      .catch(function (error: AxiosError) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (
            error.response.data?.details
              ?.toLowerCase()
              .includes('is not connected')
          ) {
            setDisplayToast(true);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log('Error en la petición');
      });
  } else {
    JSPMPrint(data);
  }
}
