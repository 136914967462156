import React from 'react';
import {ActivityIndicator, View} from 'react-native';
import {makeStyles} from './index.styles';

import {PrimaryText} from '../PrimaryText';

interface PrimaryLoaderProps {
  color?: any;
  style?: any;
  title?: string;
}

export const PrimaryLoader = ({color, style, title}: PrimaryLoaderProps) => {
  const styles = makeStyles({});
  return (
    <View style={[styles.container, style]}>
      <ActivityIndicator size={'large'} color={color} />
      <PrimaryText
        content={title ? title : 'Cargando ...'}
        color={color}
        fontSize={16}
        fontWeight={500}
        margin={20}
      />
    </View>
  );
};

PrimaryLoader.defaultProps = {
  color: '#cd177a',
};
