import React, {useCallback, useEffect, useState} from 'react';
import {Camera} from 'expo-camera';

import {IdCardContent, PrimaryLoader} from '../../components';
import {storage} from '../../storage';
import {useAuth0} from '@auth0/auth0-react';
import client from '../../common/integrations/wo-api/client';

export function IdCard() {
  const {getIdTokenClaims} = useAuth0();
  const [loadToken, setLoadToken] = useState(true);

  const handlePermissions = useCallback(async () => {
    await Camera.requestPermissionsAsync();
  }, []);

  useEffect(() => {
    handlePermissions();
  }, [handlePermissions]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getIdTokenClaims();
        await saveOnStorage('token', token.__raw);
        client.setHeader('Authorization', token.__raw);

        setLoadToken(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getIdTokenClaims]);

  async function saveOnStorage(key: string, data: any) {
    await storage.save({
      key,
      data,
    });
  }

  if (loadToken) {
    return <PrimaryLoader />;
  }

  return <IdCardContent />;
}
