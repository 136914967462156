import React, {useState, useEffect} from 'react';
import {TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import QRCode from 'react-qr-code';
import {useAuth0} from '@auth0/auth0-react';

import {
  ContainerScreen,
  Countdown,
  DropdownList,
  Footer,
  Header,
  HomeNotificationsContent,
  PrimaryIconLink,
  PrimaryLoader,
  PrimaryText,
  SideMenu,
  warehousesProps,
  Modal,
  ManifestDetail,
} from '../../';
import {
  useGetAuthorizationTokenQuery,
  useGetManifestsQuery,
  useGetUnfinishedMovementsQuery,
  useGetWarehousesQuery,
} from '../../../common/integrations/wo-api/generates';
import {makeStyles} from './index.styles';
import {BaseContext} from '../../../context';
import {storage} from '../../../storage';
import client from '../../../common/integrations/wo-api/client';
import {WO_VERSION} from '../../../config';

export const IdCardContent = () => {
  //@ts-ignore
  const getStorage = storage.cache;
  const styles = makeStyles({width: '100%', backgroundColor: 'transparent'});
  const [sideMenu, setSideMenu] = useState(false);
  const ReadContext = React.useContext(BaseContext);
  const [loadStorage, setLoadStorage] = React.useState(false);
  const [warehousesData, setWarehousesData] = React.useState<
    Array<warehousesProps>
  >([]);
  const [manifests, setManifests] = useState([]);
  const [unfinishedMovements, setUnfinishedMovements] = useState();
  const [autToken, setAutToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCoundown] = useState<number>(30);
  const [displayDetail, setDisplayDetail] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>({});
  const {user} = useAuth0();

  // Queries
  const {
    status,
    data: QueryWarehousesData,
    error,
    isFetching,
  } = useGetWarehousesQuery(client, {}, {refetchOnWindowFocus: false});

  const {
    status: QueryAuthTokenStatus,
    data: QueryAuthTokenData,
    error: QueryAuthTokenError,
    isFetching: QueryAuthTokenFetching,
    refetch: QueryAuthTokenReFetch,
  } = useGetAuthorizationTokenQuery(client, {}, {refetchOnWindowFocus: false});

  const {
    status: ManifestsStatus,
    data: ManifestsData,
    error: ManifestsError,
    isFetching: ManifestsIsFetching,
    refetch: ManifestsReFetch,
  } = useGetManifestsQuery(
    client,
    {email: user.email},
    {refetchOnWindowFocus: false},
  );

  const {
    status: UnfinishedStatus,
    data: UnfinishedData,
    error: UnfinishedError,
    isFetching: UnfinishedIsFetching,
    refetch: UnfinishedReFetch,
  } = useGetUnfinishedMovementsQuery(
    client,
    {
      email: user.email,
    },
    {refetchOnWindowFocus: false},
  );

  useEffect(() => {
    if (status === 'success' && QueryWarehousesData !== undefined) {
      const {nodes} = QueryWarehousesData.warehouses;
      setWarehousesData(nodes);
    }
  }, [status, QueryWarehousesData, ReadContext.whId]);
  const handleAuthToken = React.useCallback(() => {
    if (QueryAuthTokenStatus === 'success' && !!QueryAuthTokenData) {
      const {getAuthorizationToken} = QueryAuthTokenData;
      setAutToken(getAuthorizationToken?.authToken);
    }
  }, [QueryAuthTokenStatus, QueryAuthTokenData]);

  useEffect(() => {
    handleAuthToken();
  }, [handleAuthToken]);

  const latestCreated = (manifestsList: any[], hoursAgo: number): any[] => {
    const hoursAgoMils = hoursAgo * 60 * 60 * 1000;
    const nowDateMils = new Date().getTime();
    const getDateInMils = (dateString: string): number => {
      return new Date(dateString).getTime();
    };

    const filteredManifests = manifestsList.filter(
      (e) => nowDateMils - getDateInMils(e.createdAt) < hoursAgoMils,
    );

    return filteredManifests;
  };

  useEffect(() => {
    if (ManifestsStatus === 'success' && ManifestsData !== undefined) {
      const {nodes} = ManifestsData.manifests;
      //const {nodes} = manifests;
      const latestManifests = latestCreated(nodes, 24);
      // @ts-ignore
      setManifests(latestManifests);
    }
  }, [ManifestsData, ManifestsStatus]);

  useEffect(() => {
    if (UnfinishedData && UnfinishedData.stockAggregate.count) {
      // @ts-ignore
      setUnfinishedMovements(UnfinishedData.stockAggregate.count.id);
    }
  }, [UnfinishedData]);

  useEffect(() => {
    if (countdown === 0) {
      setCoundown(30);
      QueryAuthTokenReFetch().then((r) => console.log('Token refreshed', r));
    }
  }, [countdown, QueryAuthTokenReFetch]);

  function saveOnStorage(key: string, data: any) {
    storage.save({
      key,
      data,
    });
  }

  const optionsFooter = [
    {name: 'Home', icon: 'ios-home', route: '/home'},
    {name: 'Escaneo', icon: 'apps-outline', route: '/search-categories'},
  ];

  const sideMenuOptions = [
    {name: 'Home', route: '/home', icon: 'ios-home'},
    {
      name: 'Categorías iVoy',
      route: '/search-categories',
      icon: 'apps-outline',
    },
  ];

  function onChangeWarehouse(value: string) {
    setLoadStorage(true);
    saveOnStorage('whId', value);
    saveOnStorage('whTime', Date.now());
    setTimeout(() => {
      setLoadStorage(false);
    }, [10]);
  }

  const handleUpdateSummaryInformation = () => {
    setIsLoading(true);
    ManifestsReFetch().then((r) => {
      setIsLoading(false);
      return r;
    });
    UnfinishedReFetch().then((r) => {
      setIsLoading(false);
      return r;
    });
  };

  useEffect(() => {
    if (!displayDetail) {
      setDetail({});
    }
  }, [displayDetail]);

  if (isFetching || loadStorage) {
    return <PrimaryLoader />;
  }
  const handleReFetchAuthToken = () => {
    setCoundown(30);
    QueryAuthTokenReFetch().then((r) => console.log('Token refreshed', r));
  };

  const handleSideMenu = () => {
    setSideMenu(!sideMenu);
  };

  return (
    <>
      {sideMenu && (
        <SideMenu
          sideMenuOptions={sideMenuOptions}
          onPress={() => console.log('pressed')}
          onPressOutside={() => handleSideMenu()}
        />
      )}
      {displayDetail && (
        <Modal onPressOutside={() => setDisplayDetail(false)}>
          <ManifestDetail detail={detail} />
        </Modal>
      )}
      <ContainerScreen
        header={
          <Header
            title="Almacén "
            complement={
              <>
                <DropdownList
                  selectedValue={getStorage.whId.rawData}
                  onChange={onChangeWarehouse}
                  backgroundColor={'#000'}
                  options={['', ...warehousesData]}
                  width={'fit-content'}
                />
                <PrimaryIconLink
                  onPress={handleSideMenu}
                  style={styles.menuIconStyle}
                  iconName={'menu'}
                  iconSize={28}
                />
              </>
            }
            goBack={false}
            style={styles.header}
          />
        }
        footer={<Footer options={optionsFooter} />}>
        <View style={styles.container}>
          <View style={styles.identityContainer}>
            {true && <Countdown seconds={countdown} setSeconds={setCoundown} />}
            <View style={styles.imageContainer}>
              {QueryAuthTokenFetching ? (
                <PrimaryLoader />
              ) : (
                <QRCode value={autToken ? autToken : ''} size={180} />
              )}
              <TouchableOpacity
                style={styles.reloadButton}
                onPress={handleReFetchAuthToken}>
                <Icon name={'md-reload'} color={'#fff'} />
              </TouchableOpacity>
            </View>
            <PrimaryText content={user.name} fontWeight={'700'} fontSize={14} />
            <PrimaryText
              content={user.email}
              fontWeight={'300'}
              fontSize={12}
              margin={10}
            />
            <PrimaryText
              content={`Versión: ${WO_VERSION ? WO_VERSION : 'Sin datos'}`}
              style={styles.appVersion}
            />
          </View>
        </View>
        <HomeNotificationsContent
          styleButtonCont={styles.styleButtonCont}
          styleButton={styles.styleButton}
          styleTitle={styles.styleTitle}
          width={'100%'}
          manifests={manifests}
          pendingMovements={unfinishedMovements}
          onPress={handleUpdateSummaryInformation}
          isLoading={isLoading}
          detailHandler={setDetail}
          displayDetailHandler={setDisplayDetail}
        />
      </ContainerScreen>
    </>
  );
};
