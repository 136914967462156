import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  color: ColorValue;
  borderColor: ColorValue;
}

export const makeStyles = ({color, borderColor}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      flex: 1,
      textAlign: 'center',
      justifyContent: 'space-around',
    },
    iconContainer: {
      width: 60,
      height: 60,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#50622c',
      borderRadius: 50,
      position: 'absolute',
      top: -30,
      zIndex: 1,
    },
    icon: {
      color: '#fff',
      fontWeight: '700',
      marginLeft: 2.5,
    },
    indicator: {
      width: '100%',
      height: 7,
      backgroundColor: '#50622c',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
    },
    header: {
      fontSize: 24,
      fontWeight: '700',
      color: '#383434',
      marginBottom: 5,
    },
    subZoneContainer: {
      marginBottom: 15,
    },
    labelText: {
      fontsize: 14,
      fontWeight: '700',
      marginBottom: 5,
    },
    dropdownContainer: {
      width: 'fit-content',
      alignSelf: 'center',
      borderWidth: 2,
      borderRadius: 4,
      borderColor,
    },
    buttonsContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'space-around',
    },
    buttonContainer: {
      width: '30%',
      alignSelf: 'center',
      marginBottom: 15,
    },
    highlightedText: {
      backgroundColor: color,
      marginBottom: 5,
      width: 'fit-content',
      paddingVertical: 5,
      paddingHorizontal: 10,
      color: '#000',
      borderRadius: 4,
    },
    continueButton: {
      backgroundColor: '#000',
    },
  });
};
