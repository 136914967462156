import {StyleSheet, Dimensions} from 'react-native';
const ScreenHeight = Dimensions.get('window').height;

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      height: ScreenHeight,
      justifyContent: 'space-between',
    },
    scroll: {
      flex: 1,
    },
  });
};
