import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';
import {TouchableOpacity} from 'react-native';

interface PrimaryIconLinkProps {
  onPress: () => void;
  style: any;
  iconName: string;
  iconSize: number;
  iconStyle?: any;
}

export const PrimaryIconLink = ({
  onPress,
  style,
  iconName,
  iconSize,
  iconStyle,
}: PrimaryIconLinkProps) => {
  return (
    <TouchableOpacity onPress={onPress} style={style}>
      <Icon name={iconName} size={iconSize} style={iconStyle} />
    </TouchableOpacity>
  );
};
