import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    manifestContainer: {
      width: '90%',
      maxWidth: 700,
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      borderRadius: 4,
      padding: 4,
    },
    itemContainer: {
      width: 'fit-content',
      maxWidth: 270,
      textAlign: 'center',
    },
    tableItem: {
      flex: 1,
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      backgroundColor: '#eef0f7',
      padding: 6,
      marginBottom: 5,
      marginHorizontal: 2,
      textAlign: 'center',
    },
    icon: {
      color: '#D0021B',
    },
    tableHeader: {
      backgroundColor: '#1e336a',
      marginBottom: 0,
    },
    tableHeaderText: {
      color: '#F8F9FC',
      fontWeight: '700',
    },
    manifestHeaderText: {
      color: '#F8F9FC',
      fontWeight: '700',
    },
    tableContentText: {
      color: '#383434',
    },
  });
};
