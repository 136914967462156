import React from 'react';
import {useAuth0} from '@auth0/auth0-react';

import {PrimaryButton} from '../index';
interface LogoutButtonProps {
  style?: any;
  styleButton?: any;
  styleTitle?: any;
}

export const LogoutButton = ({
  style,
  styleButton,
  styleTitle,
}: LogoutButtonProps) => {
  const {logout} = useAuth0();
  return (
    <PrimaryButton
      styleButton={styleButton}
      styleTitle={styleTitle}
      style={style}
      title={'Logout'}
      //@ts-ignore
      onPress={() => logout({returnTo: window.location.origin})}
    />
  );
};
