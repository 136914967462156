import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    menuIconStyle: {
      position: 'absolute',
      right: 15,
    },
    errorBoxStyle: {
      height: 20,
      alignSelf: 'center',
      marginHorizontal: '20px',
      marginTop: 8,
      marginBottom: 4,
      borderBox: 'center',
    },
    searchByIdsMenuContainer: {
      position: 'absolute',
      zIndex: 3,
      width: 'fit-content',
      top: 250,
      right: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    searchByIdsButton: {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      padding: 7,
      marginBottom: 2,
    },
    searchByIdManifestButton: {
      backgroundColor: '#942E83',
    },
    searchByIdDeleteButton: {
      backgroundColor: '#000',
    },
    searchByIdsIcon: {
      color: '#fff',
      marginLeft: 2,
    },
    footerStyle: {
      justifyContent: 'space-evenly',
    },
  });
};
