import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      flex: 1,
      borderWidth: 4,
      borderColor: '#000',
      borderRadius: 4,
    },
    camera: {
      flex: 1,
    },
    cameraContent: {
      flex: 1,
      backgroundColor: 'transparent',
      justifyContent: 'space-between',
    },
    cameraButtonsContainer: {
      width: '100%',
      paddingVertical: 2,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      backgroundColor: '#000',
    },
    textReaded: {
      alignSelf: 'center',
      backgroundColor: 'rgba(0, 0 ,0 ,0.9)',
      paddingVertical: 4,
      paddingHorizontal: 8,
      borderRadius: 4,
      marginTop: 5,
    },
    swipeCameraIcon: {
      color: '#fff',
    },
    cameraPhotoIconContainer: {
      borderWidth: 2,
      borderRadius: 50,
      borderColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sizesContainer: {
      height: 42,
      width: 42,
    },
    sizesInner: {
      height: 32,
      width: 32,
      backgroundColor: '#fff',
    },
    noPermissionsStyle: {
      borderWidth: 1,
      borderColor: '#942E83',
      borderRadius: 4,
      backgroundColor: '#F8F9FC',
    },
  });
};
