import React from 'react';
import {View} from 'react-native';

import {PrimaryText} from '../../atoms';
import {makeStyles} from './index.styles';

interface CamHeaderProps {
  style?: any;
  fontStyle: any;
  title: string;
}

export const CamHeader = ({style, fontStyle, title}: CamHeaderProps) => {
  const styles = makeStyles();

  return (
    <View style={[styles.container, style]}>
      <PrimaryText content={title} style={fontStyle} />
    </View>
  );
};
