import React from 'react';
import {View} from 'react-native';
import {useHistory} from 'react-router';
import Icon from 'react-native-vector-icons/Ionicons';
import QRCode from 'react-qr-code';

import {PrimaryText, PrimaryButton, Modal, PrimaryIndicator} from '../../atoms';
import {makeStyles} from './index.styles';

interface ConfirmModalProps {
  data?: any;
}

export const ConfirmModal = ({data}: ConfirmModalProps) => {
  const history = useHistory();
  const color = '#48BF4D';
  const styles = makeStyles({color});
  const handlePress: () => void = () => {
    history.push('/home');
  };

  return (
    <Modal onPressOutside={handlePress} style={styles.container}>
      <View style={styles.iconContainer}>
        <Icon
          name="ios-checkmark-circle-outline"
          size={32}
          style={styles.icon}
        />
      </View>
      <PrimaryIndicator style={styles.indicator} />
      <PrimaryText content={'Exito'} style={styles.header} />
      <View style={styles.qrContainer}>
        <QRCode value={data?.id || 'No data'} size={200} />
      </View>
      <View style={styles.modalContentContainer}>
        <PrimaryText content={'Manifiesto'} style={styles.title} />
        <PrimaryText
          content={`${data.id || '-'}`}
          style={styles.highlightedText}
        />
        <PrimaryText
          content={`Paquetes totales: ${data?.count || 0}`}
          style={styles.highlightedText}
        />
      </View>
      <View style={styles.buttonContainer}>
        <PrimaryButton
          title="Finalizar"
          styleButton={styles.finishButton}
          style={styles.finishButton}
          styleTitle={styles.finishButtonTitle}
          onPress={handlePress}
        />
      </View>
    </Modal>
  );
};
