import React from 'react';
import {View} from 'react-native';

import {PrimaryText} from '../PrimaryText';
import {makeStyles} from './index.styles';

interface PrimaryTextProps {
  text: TextProps;
  containerStyle?: any;
}

interface TextProps {
  content: string;
  color?: any;
  fontSize?: number;
  fontWeight?: any;
  fontStyle?: any;
  margin?: any;
}

export const PrimaryOutputMessage = ({
  text,
  containerStyle,
}: PrimaryTextProps) => {
  const {content, color, fontSize, fontWeight, fontStyle, margin} = text;
  const styles = makeStyles();
  return (
    <View style={[styles.container, containerStyle]}>
      <PrimaryText
        content={content}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
        margin={margin}
      />
    </View>
  );
};

PrimaryOutputMessage.defaultProps = {};
