import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: 'fit-content',
      height: 'fit-content',
      alignSelf: 'center',
      borderWidth: 4,
      borderColor: '#000',
      borderRadius: 4,
    },
    cameraButtonsContainer: {
      width: '100%',
      paddingVertical: 2,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      backgroundColor: '#000',
    },
    swipeCameraIcon: {
      color: '#fff',
    },
    cameraPhotoIconContainer: {
      borderWidth: 2,
      borderRadius: 50,
      borderColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noPermissionsStyle: {
      borderWidth: 1,
      borderColor: '#942E83',
      borderRadius: 4,
      backgroundColor: '#F8F9FC',
    },
    qrCodeScanner: {
      width: 250,
      height: 250,
    },
  });
};
