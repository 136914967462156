import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  backgroundColor: ColorValue;
}

export const makeStyles = ({backgroundColor}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      alignSelf: 'center',
      width: '85%',
      height: 40,
      backgroundColor: backgroundColor,
      marginTop: 5,
      borderRadius: 4,
      paddingHorizontal: 16,
    },
  });
};
