import React from 'react';
import {View, TouchableOpacity, Text} from 'react-native';
import styles from './index.styles';

interface PrimaryButtonProps {
  title: string;
  style?: any;
  styleButton?: any;
  styleTitle?: any;
  onPress(): void;
}

export const PrimaryButton = ({
  style,
  styleButton,
  styleTitle,
  title,
  onPress,
}: PrimaryButtonProps) => {
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <View style={[styles.bg, styleButton]}>
        <Text style={[styles.title, styleTitle]}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

PrimaryButton.defaultProps = {
  title: 'Sign Up',
  onPress: () => console.log('press'),
};
