import React, {useState} from 'react';
import {View, ScrollView} from 'react-native';

import {PrimaryText, PrimaryIconLink} from '../../atoms';
import {makeStyles} from './index.styles';
import {storage} from '../../../storage';
import {getStorageItem} from '../../../common/utils';

interface ManifestInfoProps {
  style?: any;
  onPress: () => void;
  manifestData: any;
}

export const ManifestInformation = ({
  style,
  onPress,
  manifestData,
}: ManifestInfoProps) => {
  //@ts-ignore
  const errorsButton = storage.cache.errorsButton.rawData;
  const styles = makeStyles();

  const [packagesList, setPackagesList] = useState<any>();
  const [checks, setChecks] = useState<number>(0);

  const scrollViewStyle = {height: 17, marginTop: 2};

  storage
    .load({
      key: 'packagesList',
    })
    .then((r) => {
      if (r) {
        setPackagesList(r);
      }
    })
    .catch((err) => {
      if (err) {
        console.warn('Packages list error: ', err);
      }
    });

  storage
    .load({
      key: 'manifestExceeds',
    })
    .then((r) => {
      if (r) {
        const packageList = getStorageItem('packagesList');
        const response = packageList?.length - r.length;
        setChecks(response);
      }
    })
    .catch((err) => {
      if (err) {
        console.warn('Manifests exceeds error: ', err);
      }
    });

  const manifest = [
    {
      title: `Manifiesto ${
        manifestData.ids.length ? '(' + manifestData.ids.length + ')' : ''
      }`,
      style: styles.tableHeader,
      textStyle: styles.manifestHeaderText,
      value: manifestData.ids.join('\n'),
      valueTextStyle: styles.tableContentText,
    },
    {
      title: 'Paquetes',
      style: styles.tableHeader,
      textStyle: styles.tableHeaderText,
      value: manifestData.count,
      valueTextStyle: styles.tableContentText,
    },
    {
      title: 'Checks',
      style: styles.tableHeader,
      textStyle: styles.tableHeaderText,
      value: `${checks}`,
      valueTextStyle: styles.tableContentText,
    },
    {
      title: 'Faltan',
      style: styles.tableHeader,
      textStyle: styles.tableHeaderText,
      value: `${manifestData.count - checks}`,
      valueTextStyle: styles.tableContentText,
    },
    {
      title: 'Exceden',
      style: styles.tableHeader,
      textStyle: styles.tableHeaderText,
      value: `${packagesList?.length - checks}`,
      valueTextStyle: styles.tableContentText,
    },
  ];

  return (
    <View style={[styles.manifestContainer, style]}>
      {manifest.map((item, index) => {
        return (
          <View key={index} style={styles.itemContainer}>
            <View style={[styles.tableItem, item.style]}>
              <PrimaryText content={item.title} style={item.textStyle} />
            </View>
            <View style={styles.tableItem}>
              <ScrollView style={scrollViewStyle}>
                <PrimaryText content={item.value} style={item.valueTextStyle} />
              </ScrollView>
            </View>
          </View>
        );
      })}
      {errorsButton && (
        <PrimaryIconLink
          iconName="alert-circle"
          onPress={onPress}
          style={{}}
          iconStyle={styles.icon}
          iconSize={26}
        />
      )}
    </View>
  );
};
