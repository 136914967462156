import {storage} from '../../../storage';

export const getStorageItem = (itemName: string) => {
  //@ts-ignore
  const storageItem = storage.cache?.[itemName]?.rawData;
  return storageItem;
};

export const saveOnStorage = (key: string, data: any) => {
  storage.save({
    key,
    data,
  });
};
