import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  backgroundColor?: ColorValue;
  width?: number;
  height?: number;
}

export const makeStyles = ({
  backgroundColor,
  width,
  height,
}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      backgroundColor: backgroundColor ?? '#000',
      height: height ?? 13,
      width: width ?? 13,
      borderRadius: 50,
    },
  });
};
