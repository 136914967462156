import {StyleSheet, ColorValue, Dimensions} from 'react-native';

const width = Dimensions.get('window').width;
const fontsize = width > 1024 ? '2' : '1.5';

interface StyleProps {
  backgroundColor?: ColorValue;
}

export const makeStyles = ({backgroundColor}: StyleProps): any => {
  return StyleSheet.create({
    header: {
      backgroundColor,
    },
    options: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#dbe2f6',
      borderRadius: 4,
      position: 'absolute',
      top: 0,
      padding: 10,
    },
    iconStyle: {
      color: '#000',
      marginBottom: '5%',
      fontSize: `${fontsize}rem`,
    },
    menuIconStyle: {
      position: 'absolute',
      right: 15,
    },
    categoryIcon: {
      marginRight: 7,
    },
  });
};
