import React from 'react';
import {View} from 'react-native';
import {makeStyles} from './index.styles';

import {PrimaryCounter} from '../../atoms/PrimaryCounter';

interface CountersComponentProps {
  counters: object[];
}

export const CountersComponent = ({counters}: CountersComponentProps) => {
  const styles = makeStyles({});

  return (
    <View style={styles.container}>
      {counters.map((counter, index) => (
        <PrimaryCounter key={index} counter={counter} />
      ))}
    </View>
  );
};

CountersComponent.defaultProps = {
  counters: [
    {
      id: 1,
      name: 'Counter 1',
      packages: 123,
    },
    {
      id: 2,
      name: 'Counter 2',
      packages: 45,
    },
    {
      id: 3,
      name: 'Counter 3',
      packages: 0,
    },
  ],
};
