import {StyleSheet} from 'react-native';

import shadow from '../../../common/style';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(141,139,139,0.4)',
      position: 'absolute',
      backdropFilter: 'blur(2px)',
      zIndex: 1,
      scrollBehavior: 'unset',
    },
    modal: {
      backgroundColor: '#F8F8FF',
      width: '80%',
      minWidth: 270,
      maxWidth: 450,
      height: 'auto',
      minHeight: 320,
      padding: 10,
      paddingTop: 45,
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: 4,
      ...shadow({
        elevation: 7,
        color: 'rgba(24, 24, 24, 1)',
        opacity: 0.5,
        radius: 18,
        offsetWidth: -10,
        offsetHeight: 10,
      }),
    },
  });
};
