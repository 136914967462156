import firebase from 'firebase/app';
import 'firebase/analytics';

import {
  FIREBASE_DATABASE_URL,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
} from '../../../config';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

export const handleAnalytics = (
  eventName: string,
  eventObj: any,
  userProps: {name: string; email: string; version: string},
) => {
  try {
    firebase.analytics().setUserProperties(userProps);
    firebase.analytics().logEvent(eventName, eventObj);
  } catch (error) {
    console.log('Firebase error:', JSON.stringify(error));
  }
};

export default firebase;
