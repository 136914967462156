import {StyleSheet} from 'react-native';

interface StyleProps {
  height: number | string;
}

export const makeStyles = ({height}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      height,
      backgroundColor: 'rgba(222,227,243,1)',
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      padding: 20,
    },
    containerTitle: {
      flexDirection: 'row',
      paddingBottom: 10,
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontWeight: 900,
    },
    iconArrow: {
      fontSize: 27,
      marginRight: 10,
      backgroundColor: 'transparent',
    },
    children: {
      paddingTop: 10,
    },
  });
};
