import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  backgroundColor?: ColorValue;
}

export const makeStyles = ({backgroundColor}: StyleProps): any => {
  return StyleSheet.create({
    containerScreen: {
      backgroundColor: '#000',
    },
    header: {
      fontSize: 16,
      color: '#fff',
      fontWeight: '700',
      textAlign: 'center',
    },
    contentTextHeader: {
      color: '#91A3B0',
      width: 'fit-content',
      marginBottom: 5,
      fontSize: 12,
      textAlign: 'center',
    },
    contentText: {
      marginBottom: 15,
      fontWeight: 700,
      fontSize: 15,
      textAlign: 'center',
      paddingHorizontal: 12,
      paddingVertical: 6,
      borderRadius: 4,
      minWidth: 130,
      backgroundColor: '#6b5b67',
      color: '#fff',
    },
    movementText: {
      color: '#9B9B9B',
      fontWeight: '700',
      marginLeft: 15,
      fontSize: 20,
    },
    packageInfo: {
      alignItems: 'center',
      textAlign: 'center',
    },
    colorOrder: {
      backgroundColor: '#00e0da',
      fontSize: 20,
      minWidth: 120,
    },
    colorTrackingNumber: {
      backgroundColor: '#0064e0',
      fontSize: 20,
      minWidth: 200,
    },
    colorZoneName: {
      backgroundColor: backgroundColor,
      fontSize: 20,
      minWidth: 90,
    },
    trackingNumber: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 4,
      paddingHorizontal: 20,
      paddingVertical: 7,
      borderBottomWidth: 2,
      borderBottomColor: 'rgba(78,76,76,0.05)',
      borderBottomStyle: 'dashed',
    },
  });
};
