export const packageStatus = (packagestatusId: string) => {
  switch (packagestatusId) {
    case '1':
      return 'created';
    case '2':
      return 'in_order';
    case '3':
      return 'collected';
    case '4':
      return 'ready_to_storage';
    case '5':
      return 'ready_to_delivery';
    case '6':
      return 'delivered';
    case '7':
      return 'to_storage';
    case '8':
      return 'to_next_visit';
    case '9':
      return 'visits_done';
    case '10':
      return 'storaged';
    case '11':
      return 'lost';
    case '12':
      return 'canceled_visit';
    case '13':
      return 'deleted';
    case '14':
      return 'suspend_visit';
    case '15':
      return 'returned';
    case '16':
      return 'queued';
    case '17':
      return 'to_return';
    case '18':
      return 'canceled_client_test';
    case '19':
      return 'canceled_client_request';
    case '20':
      return 'canceled_package_not_found';
    case '21':
      return 'canceled_testing';
    case '22':
      return 'canceled_duplicated_package';
    case '23':
      return 'in_pre_order';
    case '24':
      return 'out_pre_order';
    case '25':
      return 'queued_for_routing';
    case '99':
      return 'audit';
    default:
      '';
  }
};
