import {StyleSheet} from 'react-native';

interface StyleProps {}

export const makeStyles = ({}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      width: '33%',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  });
};
