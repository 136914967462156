import {Platform} from 'react-native';

interface functionProps {
  color: any;
  opacity: any;
  radius: any;
  offsetWidth: any;
  offsetHeight: any;
  elevation: any;
}

const shadow = ({
  color,
  opacity,
  radius,
  offsetWidth,
  offsetHeight,
  elevation,
}: functionProps) => {
  return Platform.select({
    ios: {
      shadowColor: color,
      shadowOpacity: opacity,
      shadowRadius: radius,
      shadowOffset: {
        width: offsetWidth,
        height: offsetHeight,
      },
    },
    android: {
      elevation,
    },
    web: {
      boxShadow: `${offsetWidth}px ${offsetHeight}px ${radius}px rgba(227, 228, 229, 0.5)`,
    },
  });
};

export default shadow;
