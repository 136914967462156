import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Movements,
  Footer,
  Header,
  ContainerScreen,
  PrimaryLoader,
  warehousesProps,
  PrimaryIconLink,
  SideMenu,
  ManifestInformation,
  ManifestErrorsModal,
  ModalKeepingsSelection,
} from '../../components';
import {Platform, Alert} from 'react-native';
import {useAuth0} from '@auth0/auth0-react';
import {useGetCatalogZonesAndActionsQuery} from '../../common/integrations/wo-api/generates';
import client from '../../common/integrations/wo-api/client';
import {BaseContext} from '../../context';
import {makeStyles} from './index.styles';
import {
  useStorageItem,
  getStorageItem,
  saveOnStorage,
} from '../../common/utils';

interface packagesProps {
  internalId: string;
  externalId: string;
  clientId: string;
  clientName: string;
  routeId: string;
}

interface IdName {
  id: string;
  name: string;
}

interface ICancelProps extends IdName {
  reason?: string;
}

interface IPackageItem {
  id: string;
  exist: boolean;
  status: string;
  internalId: string;
  referenceId: string;
  zoneName: string;
  zoneId: string;
  clientName: string;
  clientId: string;
}

interface IPackageItemWithCancelation extends IPackageItem {
  cancelationReasonName: string;
  cancelationReasonId: string;
}

export function MovementPage() {
  const history = useHistory();
  const ReadContext = React.useContext(BaseContext);
  const [sideMenu, setSideMenu] = useState<boolean>(false);
  const [zoneNames, setZoneNames] = useState<warehousesProps[]>([]);
  const [zoneName, setZoneName] = useState<string>('');
  const [actionNames, setActionNames] = useState<warehousesProps[]>([]);
  const [actionName, setActionName] = useState('');
  const [subZoneNames, setSubZonesNames] = useState<any[]>([]);
  const [subZoneName, setSubZoneName] = useState('');
  const [showModalErrors, setShowModalErrors] = useState<boolean>(false);
  const [showModalKeepings, setShowModalKeepings] = useState<boolean>(false);
  const manifestInput = useStorageItem('manifestData');
  const keepingsData = getStorageItem('keepings');
  const {getIdTokenClaims} = useAuth0();
  const [loadToken, setLoadToken] = useState<boolean>(true);
  const [currentPackage, setCurrentPackage] = useState<any>({id: ''});
  const [cancelStatus, setCancelStatus] = useState<ICancelProps>({
    id: '',
    name: '',
    reason: '',
  });

  const styles = makeStyles();

  const sideMenuOptions = [
    {name: 'Home', route: '/home', icon: 'ios-home'},
    {
      name: 'Categorías iVoy',
      route: '/search-categories',
      icon: 'apps-outline',
    },
  ];
  const optionsFooter = [
    {
      name: 'Atras',
      icon: 'ios-arrow-back-sharp',
      route: '/search/consolidated-search',
    },
    {
      name: 'Home',
      icon: 'ios-home',
      route: '/home',
      onPress: () => console.log('action'),
    },
    {
      name: 'Siguiente',
      icon: 'ios-arrow-forward',
      onPress: () => handlePress(),
    },
  ];

  const handlePress: () => void = () => {
    const inputsWerePicked: boolean = !!zoneName && !!actionName;

    if (inputsWerePicked) {
      next();
    } else {
      const message: string =
        (!zoneName ? 'No se há seleccioando una Zona. ' : '') +
        (!actionName ? 'No se há seleccionado una Acción.' : '');

      Platform.OS === ('android' || 'ios')
        ? Alert.alert('Movimiento', message)
        : //@ts-ignore
          alert(message);
    }
  };

  const getSubZoneByCancelName: (id: string) => string[] | string = (
    id: string,
  ) => {
    const getIdByName = (name: string) => {
      return subZoneNames.find((item: warehousesProps) => item.name === name)
        .id;
    };

    switch (id) {
      case 'address_incomplete':
        return ['CLIENTE AUSENTE', getIdByName('CLIENTE AUSENTE')];
      case 'address_incorrect':
        return ['REF', getIdByName('REF')];
      case 'bad_pointer':
        return ['PUNTERO MALO', getIdByName('PUNTERO MALO')];
      default:
        return '';
    }
  };

  const createTransactions = () => {
    if (keepingsData) {
      const packagesList = getStorageItem('packagesList');
      const packagesCache: any = {};
      const newTransactions: any[] = [];
      const oldTransactions = {
        zoneId: zoneName,
        zoneName: getNameById(zoneName, zoneNames),
        actionTypeId: actionName,
        actionTypeName: getNameById(actionName, actionNames),
      };

      packagesList.forEach((item: IPackageItemWithCancelation) => {
        if (packagesCache.hasOwnProperty(item.cancelationReasonName)) {
          packagesCache[item.cancelationReasonName].push(item);
        } else {
          packagesCache[item.cancelationReasonName] = [item];
        }
      });
      for (let key in packagesCache) {
        const transaction = {
          ...oldTransactions,
          subZoneName: getSubZoneByCancelName(key)[0],
          subZoneId: getSubZoneByCancelName(key)[1],
          packages: packagesCache[key].map((item: IPackageItem) => ({
            internalId: item.internalId,
            externalId: item.referenceId,
            clientId: item.clientId,
            clientName: item.clientName,
            zoneId: item.zoneId,
            zoneName: item.zoneName,
            routeId: null,
          })),
        };
        newTransactions.push(transaction);
      }

      return newTransactions;
    }
  };

  function next() {
    const pending: any = [];
    const entry: any = [];
    const exceedsPackagesCount = getStorageItem('manifestExceeds').length;
    const missingPackagesCount = getStorageItem('manifestMissing').length;
    const missingPackages = getStorageItem('manifestMissing');
    let movementObj = getStorageItem('movementObj');
    const manifests: any = {};
    missingPackages.forEach(
      (item: {manifestId: string; externalId: string; internalId: string}) => {
        if (manifests.hasOwnProperty(item.manifestId)) {
          manifests[item.manifestId] = manifests[item.manifestId] + 1;
        } else {
          manifests[item.manifestId] = 1;
        }
      },
    );

    const missingsByManifestMessage = Object.entries(manifests)
      .map((item: any) => {
        return `Faltantes de ${item[0]}: ${item[1]}`;
      })
      .join('\n');

    let message: string =
      (exceedsPackagesCount > 0
        ? `Paquetes Excedentes: ${exceedsPackagesCount} \n`
        : '') +
      (missingPackagesCount > 0
        ? `Paquetes Faltantes: ${missingPackagesCount} \n`
        : '') +
      (missingsByManifestMessage || '') +
      '\n';

    if (movementObj.comments) {
      let comment: string = movementObj.comments
        ?.split('\n')
        ?.filter(
          (line: string) =>
            !line.includes('Cruce con manifiesto:') &&
            !line.includes('Paquetes Excedentes:') &&
            !line.includes('Faltantes de') &&
            !line.includes('Paquetes Faltantes:'),
        )
        .join('\n');
      message = message + comment;
    }

    const setTransactions = () => {
      if (keepingsData) {
        return createTransactions();
      } else {
        return [
          {
            zoneId: zoneName,
            zoneName: getNameById(zoneName, zoneNames),
            actionTypeId: actionName,
            actionTypeName: getNameById(actionName, actionNames),
            subZoneId: subZoneName || '',
            subZoneName:
              subZoneNames?.find((subZone) => subZone.id === subZoneName)
                ?.name || '',
            comments: message,
            packages: entry,
          },
          {
            zoneId: zoneName,
            zoneName: getNameById(zoneName, zoneNames),
            actionTypeId: getIdByName('PENDIENTE', actionNames),
            actionTypeName: 'PENDIENTE',
            subZoneId: subZoneName || '',
            subZoneName:
              subZoneNames?.find((subZone) => subZone.id === subZoneName)
                ?.name || '',
            comments: message,
            packages: pending,
          },
        ];
      }
    };

    const obj = {
      warehouseId: ReadContext.whId,
      warehouseName: 'Hub Mex',
      comments: message,
      transactions: setTransactions(),
    };

    getStorageItem('packagesList').forEach((item: any) => {
      if (item.exist) {
        entry.push({
          internalId: item.internalId,
          externalId: item.referenceId,
          clientId: item.clientId,
          clientName: item.clientName,
          zoneId: item.zoneId,
          zoneName: item.zoneName,
          routeId: null,
        });
      } else {
        pending.push({
          internalId: item.internalId,
          externalId: item.referenceId,
          clientId: item.clientId,
          clientName: item.clientName,
          zoneId: item.zoneId,
          zoneName: item.zoneName,
          routeId: null,
        });
      }
    });
    saveOnStorage('movementObj', obj);
    history.push('/confirm');
  }

  const {
    status: catalogQueryStatus,
    data: catalogQueryData,
    error: catalogQueryError,
    isFetching: zonesQueryIsFetching,
  } = useGetCatalogZonesAndActionsQuery(
    client,
    {
      paging: {limit: 20},
      filter: {warehouseId: {eq: ReadContext.whId}, active: {is: true}},
    },
    {refetchOnWindowFocus: false},
  );

  useEffect(() => {
    if (catalogQueryData) {
      const newZones = [...catalogQueryData.zones.nodes].filter(
        (item) => item.parentZone === null,
      );

      setZoneNames(newZones);
      setActionNames(catalogQueryData.actionTypes.nodes);
    }
  }, [catalogQueryData]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getIdTokenClaims();
        await saveOnStorage('token', token.__raw);
        client.setHeader('Authorization', token.__raw);

        setLoadToken(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getIdTokenClaims]);

  if (loadToken) {
    return <PrimaryLoader />;
  }

  if (zonesQueryIsFetching) {
    return <PrimaryLoader />;
  }

  const getNameById = (id: string, names: IdName[]) => {
    if (names.length > 0) {
      const result = names.filter((item: IdName) => item.id === id);
      return result[0].name;
    }
    return null;
  };

  const getIdByName = (name: string, ids: IdName[]) => {
    if (ids.length > 0) {
      const result = ids.filter((item: IdName) => item.name === name);
      return result[0].id;
    }

    return null;
  };

  const handleSideMenu = () => {
    setSideMenu(!sideMenu);
  };

  const handleSelectZone = (value: string) => {
    setZoneName(value);

    if (value) {
      //@ts-ignore
      const subzonesArr = zoneNames?.find((zone) => zone.id === value)?.subZones
        .nodes;
      setSubZonesNames(subzonesArr);
    } else {
      setSubZonesNames([]);
    }
  };

  const handleSelectSubZone = (internalId: string) => {
    const getCancelIdBySubZone = (id: string | undefined) => {
      switch (id) {
        case '4':
          return ['4', 'CLIENTE AUSENTE', 'address_incomplete'];
        case '5':
          return ['5', 'REF', 'address_incorrect'];
        case '6':
          return ['6', 'PUNTERO MALO', 'bad_pointer'];
        default:
          return ['', '', ''];
      }
    };
    const [cancelId, cancelName, cancelReason] =
      getCancelIdBySubZone(internalId);
    setCancelStatus({id: cancelId, name: cancelName, reason: cancelReason});
  };

  const handleSaveModalKeepings = async () => {
    const currentPackagesList = getStorageItem('packagesList');
    const {id, reason} = cancelStatus;
    let errors;
    if (id) {
      errors = false;
      if (currentPackage.name) {
        currentPackagesList.map((item: any) => {
          item.cancelationReasonId = id;
          item.cancelationReasonName = reason;
        });
        saveOnStorage('packagesList', currentPackagesList);
      } else {
        const newPackagesList = currentPackagesList.map((item: any) => {
          if (item.internalId === currentPackage.internalId) {
            item.cancelationReasonName = reason;
            item.cancelationReasonId = id;
          }
          return item;
        });
        saveOnStorage('packagesList', newPackagesList);
      }
      setTimeout(() => {
        setShowModalKeepings(false);
        setCancelStatus({id: '', name: '', reason: ''});
      }, 1500);
    } else {
      errors = true;
    }
    return {errors};
  };

  const handleOpenModal = (item: any) => {
    setShowModalKeepings(true);
    const {cancelationReasonId, cancelationReasonName} = item;
    setCancelStatus({id: cancelationReasonId, name: cancelationReasonName});
    setCurrentPackage(item);
  };

  return (
    <>
      {sideMenu && (
        <SideMenu
          sideMenuOptions={sideMenuOptions}
          onPress={() => console.log('pressed')}
          onPressOutside={() => handleSideMenu()}
        />
      )}
      {showModalErrors && (
        <ManifestErrorsModal
          onPressOutside={() => setShowModalErrors(false)}
          onPressNext={() => {}}
          buttonTitle={'Continuar'}
        />
      )}
      {showModalKeepings && (
        <ModalKeepingsSelection
          currentCancelStatus={currentPackage.cancelationReasonId}
          cancelId={cancelStatus.id}
          onChange={(value: string) => handleSelectSubZone(value)}
          handleSave={() => handleSaveModalKeepings()}
          handlePressOutside={() => setShowModalKeepings(false)}
        />
      )}
      <ContainerScreen
        header={
          <Header
            title="Movimiento"
            complement={
              <PrimaryIconLink
                onPress={handleSideMenu}
                style={styles.menuIconStyle}
                iconName={'menu'}
                iconSize={28}
              />
            }
          />
        }
        footer={<Footer options={optionsFooter} />}>
        {!!manifestInput?.id && (
          <ManifestInformation
            onPress={() => setShowModalErrors(true)}
            manifestData={manifestInput}
          />
        )}
        <Movements
          zoneName={zoneName}
          actionName={actionName}
          zoneNames={zoneNames}
          actionNames={actionNames}
          subZoneNames={subZoneNames}
          subZoneName={subZoneName}
          onChangeZone={(value: string) => handleSelectZone(value)}
          onChangeAction={(value: string) => setActionName(value)}
          onChangeSubZone={(value: string) => handleSelectSubZone(value)}
          handleOpenModal={(item) => handleOpenModal(item)}
        />
      </ContainerScreen>
    </>
  );
}
