import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  width: number | string;
  backgroundColor?: ColorValue;
}

export const makeStyles = ({width, backgroundColor}: StyleProps): any => {
  return StyleSheet.create({
    header: {
      backgroundColor,
    },
    summaryHeader: {
      maxWidth: '50%',
    },
    styleButtonCont: {
      justifyContent: 'center',
    },
    styleButton: {
      backgroundColor: '#000',
      borderRadius: 4,
      width: 92,
      height: 24,
      alignSelf: 'flex-end',
    },
    styleTitle: {
      marginTop: 0,
      fontWeight: 'bolder',
    },
    menuIconStyle: {
      position: 'absolute',
      right: 15,
    },
    container: {
      width,
    },
    buttonsContainer: {
      width: '100%',
      minHeight: 37,
      height: '6%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    dropDownList: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      paddingLeft: '10%',
      marginBottom: 40,
    },
    identityContainer: {
      width: '100%',
      height: 'fit-content',
      alignItems: 'center',
    },
    imageContainer: {
      width: 180,
      height: 190,
      marginBottom: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    reloadButton: {
      position: 'absolute',
      bottom: 20,
      right: -50,
      backgroundColor: '#000',
      padding: 4,
      borderRadius: 4,
      width: 27,
      height: 27,
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      flex: 1,
    },
    summary: {
      width: '100%',
      justifyContent: 'space-between',
      marginTop: 20,
      padding: '7%',
      borderRadius: 4,
      backgroundColor: '#DEE3F3',
    },
    registry: {
      width: '100%',
      height: 'fit-content',
      marginTop: 15,
    },
    pendingMovements: {
      paddingLeft: 15,
      paddingTop: 13,
      flexDirection: 'row',
      alignItems: 'center',
    },
    summaryRow: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
    },
    manifestsContainer: {
      marginTop: 10,
    },
    manifests: {
      paddingTop: 5,
    },
    manifestsInnerContainer: {
      marginTop: 10,
      flexDirection: 'row',
      userSelect: 'none',
    },
    manifestsRow: {
      flexDirection: 'row',
    },
    manifestsIconContainer: {
      paddingLeft: 15,
      paddingRight: 10,
      marginRight: 10,
      justifyContent: 'center',
      borderRightWidth: 2,
      borderRightColor: '#4d4d4e',
    },
    manifestsIcon: {
      color: '#4d4d4e',
    },
    pendingText: {
      backgroundColor: '#383434',
      width: 'fit-content',
      paddingVertical: 3,
      paddingHorizontal: 10,
      borderRadius: 4,
      color: '#fff',
      fontWeight: 700,
    },
    manifestsIndicator: {
      width: 4,
      height: 4,
      backgroundColor: '#9aa9da',
      borderRadius: 4,
      marginHorizontal: 5,
    },
    withoutManifest: {
      color: '#211c0c',
      fontSize: 13,
      paddingVertical: 3,
      paddingHorizontal: 10,
      borderRadius: 4,
      backgroundColor: '#9aa9da',
    },
    appVersion: {
      fontSize: 11,
      fontStyle: 'italic',
      color: '#000',
      position: 'absolute',
      bottom: -20,
      right: 13,
    },
  });
};
