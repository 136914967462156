import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

//@ts-ignore
import JSPM from 'jsprintmanager';
import {DropdownList, PrimaryText} from '../../atoms';
import {useGetPrintersAndGatewaysQuery} from '../../../common/integrations/wo-api/generates';
import {makeStyles} from './index.styles';
import client from '../../../common/integrations/wo-api/client';
import {saveOnStorage, getStorageItem} from '../../../common/utils';

interface PrintersOptionsProps {
  zonePrint: string;
  setZonePrint: (value: string) => void;
  printer: string;
  setPrinter: any;
  printMode: string;
  setPrintMode: (value: string) => void;
}

interface PrintersProps {
  id: string;
  name: string;
}

interface GatewaysProps {
  id: string;
  name: string;
}

export const PrintersSubFooterOptions = ({
  setPrinter,
  setZonePrint,
  printer,
  zonePrint,
  printMode,
  setPrintMode,
}: PrintersOptionsProps) => {
  const styles = makeStyles();
  const cacheData = getStorageItem('whId');
  const options = getStorageItem('subFootOptions');
  const [printersData, setPrinters] = useState<PrintersProps[]>([
    {id: '1', name: 'Loading...'},
  ]);
  const [gateways, setGateways] = useState<GatewaysProps[]>([
    {id: '2', name: 'Loading...'},
  ]);
  const {data, status} = useGetPrintersAndGatewaysQuery(client, {
    warehouseId: cacheData,
  });

  useEffect(() => {
    if (data !== null && data !== undefined && status === 'success') {
      const {printers, printGateways} = data;

      JSPM.JSPrintManager.getPrintersInfo()
        .then((installedPrinters: any) => {
          installedPrinters = installedPrinters.filter((printer: any) =>
            printer.name.startsWith('wo-'),
          );
          if (
            (printers.nodes.length === 0 || printGateways.nodes.length === 0) &&
            installedPrinters.length === 0
          ) {
            setPrinters([{name: 'Sin data', id: '1'}]);
            setGateways([{name: 'Sin data', id: '2'}]);
          } else {
            let dataPrints: any = [];
            let dataZonePrints: any = [];
            installedPrinters.forEach((installedPrinter: any) => {
              dataPrints.push({
                name: installedPrinter.name.substr(0, 7),
                id: installedPrinter.name,
              });
            });
            data.printers.nodes.forEach((item) => {
              dataPrints.push({name: item.name, id: item.ip});
            });
            setPrinters(dataPrints);

            data.printGateways.nodes.map((item) => {
              dataZonePrints.push({name: item.name, id: item.deviceName});
            });
            setGateways(dataZonePrints);
            if (options.printer === '' || options.zonePrint === '') {
              setPrinter(dataPrints[0].id);
              setZonePrint(
                data.printGateways.nodes[0]?.deviceName || 'NO DATA',
              );
            }
          }
          const obj = {
            printer: printer || printersData[0].id,
            zonePrint: zonePrint,
          };

          saveOnStorage('subFootOptions', obj);
        })
        .catch((err: any) => console.log(err));
    }
  }, [
    data,
    setPrinter,
    status,
    setZonePrint,
    printer,
    zonePrint,
    printersData,
    options.printer,
    options.zonePrint,
  ]);

  const mappable = [
    {
      title: 'Gateway',
      value: options.zonePrint,
      setFunction: setZonePrint,
      options: gateways,
    },
    {
      title: 'Impresoras',
      value: printer,
      setFunction: (value: any) => {
        setPrinter(value);
      },
      options: printersData,
    },
    {
      title: 'Modo',
      value: printMode,
      setFunction: (value: any) => setPrintMode(value),
      options: [
        {id: 1, name: 'Cloud'},
        {id: 2, name: 'Local'},
      ],
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <PrimaryText
          content={'Opciones de impresión'}
          style={styles.headerText}
        />
      </View>
      <View style={styles.fastModeSelectsContainer}>
        {mappable?.map((select: any, index: number) => (
          <View style={styles.fastModeSelects} key={index}>
            <PrimaryText content={select.title} style={styles.selectsLabel} />
            <DropdownList
              backgroundColor={'#000'}
              selectedValue={select.value}
              onChange={select.setFunction}
              options={select.options}
            />
          </View>
        ))}
      </View>
      <View style={styles.headerContainer}>
        <PrimaryText content={'Opciones generales'} style={styles.headerText} />
      </View>
    </View>
  );
};
