import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

export function formatDate(date: string | undefined) {
  if (!date) {
    return '';
  }
  dayjs.extend(LocalizedFormat);
  const auxDate = dayjs(date).format('DD/MM/YYYY');
  const auxTime = dayjs(date).format('HH:mm');
  return `${auxDate} - ${auxTime}`;
}
