import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import {storage} from '../../../storage';

import {
  Modal,
  PrimaryIndicator,
  PrimaryText,
  SimplePackagesList,
  PrimaryButton,
  PrimaryLoader,
} from '../../';
import {useStorageItem} from '../../../common/utils';
import {makeStyles} from './index.styles';

interface ManifestErrorsModalProps {
  onPressOutside: () => void;
  onPressNext: () => void;
  showNextButton?: boolean;
  buttonTitle: string;
}

export function ManifestErrorsModal({
  onPressOutside,
  onPressNext,
  showNextButton,
  buttonTitle,
}: ManifestErrorsModalProps) {
  //@ts-ignore
  const manifestMissing = storage.cache.manifestMissing.rawData;
  //@ts-ignore
  const manifestData = storage.cache.manifestData.rawData;
  //@ts-ignore
  const packsList = storage.cache.packagesList.rawData;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exceeds, setExceeds] = useState<any[]>([]);
  useStorageItem('manifestExceeds', setExceeds);

  const styles = makeStyles();
  useEffect(() => {
    setIsLoading(true);
    if (manifestData) {
      const scannedData = manifestData.guides.filter((pack: any) => {
        return !packsList.some((guide: any) => {
          return pack.internalId === guide.internalId;
        });
      });
      storage.save({
        key: 'manifestMissing',
        data: scannedData,
      });
    }
    setIsLoading(false);
  }, [manifestData, packsList]);

  return (
    <Modal onPressOutside={onPressOutside} style={styles.modal}>
      {isLoading ? (
        <PrimaryLoader />
      ) : (
        <>
          <View style={styles.iconContainer}>
            <Icon name={'alert-circle-outline'} size={32} style={styles.icon} />
          </View>
          <PrimaryIndicator style={styles.indicator} />
          <PrimaryText content={'Errores'} style={styles.header} />
          <SimplePackagesList
            modalInfo={{title: 'Excedentes', packages: exceeds}}
          />
          <SimplePackagesList
            modalInfo={{
              title: 'Faltantes',
              packages: manifestMissing.map((item: any) => ({
                internalId:
                  item.internalId.substring(0, 4) +
                  ' * * * * * * * * ' +
                  item.internalId.substring(14, 19),
              })),
            }}
          />
          <View style={styles.buttonsContainer}>
            <View style={styles.button}>
              <PrimaryButton
                title={buttonTitle}
                onPress={onPressOutside}
                styleButton={styles.buttonNormalStyle}
                styleTitle={styles.buttonTitle}
              />
            </View>
            {!!showNextButton && (
              <View style={styles.button}>
                <PrimaryButton
                  title={'Siguiente'}
                  onPress={onPressNext}
                  styleButton={styles.buttonStyle}
                  styleTitle={styles.buttonTitle}
                />
              </View>
            )}
          </View>
        </>
      )}
    </Modal>
  );
}
