import React from 'react';
import {View} from 'react-native';

import {makeStyles} from './index.styles';
import {PrimaryOutputMessage} from '../../atoms';

interface CamPermissionsContainerProps {
  permissions: any;
  children: React.ReactNode;
}

export const CamPermissionsContainer = ({
  permissions,
  children,
}: CamPermissionsContainerProps) => {
  const styles = makeStyles();

  // Permissions Logic

  const noPermissionsStyle = {
    content: 'Sin acceso a la cámara',
    fontSize: 12,
  };

  const nullPermissionsStyle = {
    content: 'Cámara bloqueada',
    fontSize: 12,
  };

  if (permissions === null) {
    return (
      <PrimaryOutputMessage
        text={nullPermissionsStyle}
        containerStyle={styles.noPermissionsStyle}
      />
    );
  }
  if (permissions === false) {
    return (
      <PrimaryOutputMessage
        text={noPermissionsStyle}
        containerStyle={styles.noPermissionsStyle}
      />
    );
  }
  return <View style={styles.container}>{children}</View>;
};

CamPermissionsContainer.defaultProps = {};
