import React from 'react';
import {View} from 'react-native';
import {
  PrimaryLoader,
  PrimaryText,
  PrimaryListItem,
  PrimaryIconLink,
} from '../../';
import {makeStyles} from './index.styles';

interface PackageInfoProps {
  style?: any;
  isLoading: boolean;
  packageInfo: any;
  manualPrint: (packInfo: any) => void;
  zoneCities: any;
  zoneHubs: any;
}

export const PackageInfo = ({
  style,
  isLoading,
  packageInfo,
  manualPrint,
  zoneCities,
  zoneHubs,
}: PackageInfoProps) => {
  let cityFiltered;
  if (packageInfo?.zoneName === 'CDMX' && !!packageInfo?.zoneHub) {
    cityFiltered = zoneHubs.filter((item: any) => {
      return item.name.toUpperCase() === packageInfo?.zoneHub?.toUpperCase();
    });
  } else {
    cityFiltered = zoneCities.filter((item: any) => {
      return item.name === packageInfo?.zoneName;
    });
  }

  const city =
    cityFiltered.length > 0
      ? cityFiltered[0]
      : zoneCities[zoneCities.length - 1];

  const styles = makeStyles({backgroundColor: city.color});

  if (isLoading) {
    return <PrimaryLoader style={styles.loaderStyle} />;
  }

  return (
    <View style={[styles.container, style]}>
      <View style={styles.containerTrackingNumber}>
        <PrimaryText
          style={styles.trackingNumberText}
          content={packageInfo.trackingNumber}
        />
        <PrimaryText
          style={styles.packageInfoTitle}
          content={packageInfo.trackingTitle}
        />
        <PrimaryIconLink
          onPress={() => manualPrint(packageInfo)}
          style={styles.printIconContainer}
          iconName={'print'}
          iconSize={22}
          iconStyle={styles.printIconLink}
        />
      </View>
      <View style={styles.packageItemsContainer}>
        {packageInfo.order && (
          <View style={styles.orderContainer}>
            <PrimaryText
              content={packageInfo.order}
              style={styles.destinyText}
            />
            <PrimaryText style={styles.packageInfoTitle} content="Orden" />
          </View>
        )}
        <View style={styles.destinyContainer}>
          <PrimaryText
            content={`${packageInfo.zoneHub || '-'}`}
            style={styles.destinyText}
          />
          <PrimaryText
            content={`${
              packageInfo.zoneCoverage &&
              packageInfo.zoneCoverage.toLowerCase() !== 'none'
                ? ' - ' + packageInfo.zoneCoverage
                : ''
            }`}
            style={styles.zoneCoverageText}
          />
          <PrimaryText style={styles.packageInfoTitle} content="Destino" />
        </View>
      </View>
      <View style={styles.backgroundTech}>
        {packageInfo.info.map((item: any, index: number) => {
          return (
            <PrimaryListItem
              key={index}
              item={item}
              style={styles.primaryItemList}
            />
          );
        })}
      </View>
    </View>
  );
};

PackageInfo.defaultProps = {
  style: {
    width: '100%',
  },
};
