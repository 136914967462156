import {StyleSheet} from 'react-native';

interface StyleProps {
  width: number | string;
}

export const makeStyles = ({width}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      width,
      paddingHorizontal: 20,
    },
    comentaries: {
      width: '100%',
      height: 622,
      paddingVertical: 5,
      justifyContent: 'space-around',
    },
    input: {
      backgroundColor: 'rgba(241,243,250,1)',
      borderWidth: 1,
      borderColor: '#000',
      borderRadius: 4,
      padding: 5,
      height: 110,
    },
    evidences: {
      width: '100%',
      height: 395,
    },
    picker: {
      width: '100%',
      height: 101,
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    pickerOption: {
      width: 72,
      height: 72,
      elevation: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    evidenceOutputContainer: {
      width: '100%',
      height: 258,
      justifyContent: 'center',
    },
    evidenceOutput: {
      width: '100%',
      height: 231,
      alignItems: 'center',
      justifyContent: 'center',
    },
    close: {
      width: 27,
      height: 27,
      borderWidth: 4,
      borderColor: '#000',
      borderRadius: 50,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-end',
    },
    qrReaderContainer: {
      width: 'fit-content',
      height: 'fit-content',
      alignSelf: 'center',
      backgroundColor: '#000',
    },
    loaderStyle: {
      width: '100%',
      height: 'calc(100vh - 180px)',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};
