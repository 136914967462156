import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  borderColor?: ColorValue;
  borderWidth?: number;
  borderRadius?: number;
}

export const makeStyles = ({
  borderWidth,
  borderColor,
  borderRadius,
}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      borderWidth: borderWidth ?? 0,
      borderColor: borderColor ?? 'transparent',
      borderRadius: borderRadius ?? 0,
      padding: '0.5%',
      backgroundColor: 'rgba(255,255,255,1)',
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconSearch: {
      color: 'rgba(0,0,0,1)',
      fontSize: 25,
    },
    input: {
      width: '100%',
      height: 22,
      color: 'black',
      fontSize: 15,
      fontWeight: 'medium',
      textAlign: 'left',
      marginLeft: 20,
      paddingHorizontal: 7,
      lineHeight: 28,
    },
    iconRow: {
      height: 27,
      flexDirection: 'row',
      marginLeft: 12,
    },
    iconRowFiller: {
      flex: 1,
      flexDirection: 'row',
    },
    iconScan: {
      color: 'rgba(0,0,0,1)',
      fontSize: 25,
      marginRight: 14,
    },
    placeholder: {
      color: '#91A3B0',
    },
  });
};
