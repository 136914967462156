import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  backgroundColor?: ColorValue;
  textColor?: ColorValue;
  textBackgroundColor?: ColorValue;
}

export const makeStyles = ({
  backgroundColor,
  textColor,
  textBackgroundColor,
}: StyleProps): any => {
  return StyleSheet.create({
    header: {
      backgroundColor,
    },
    pickerOptionSelected: {
      borderWidth: 4,
      borderColor: '#000',
      borderRadius: 4,
      padding: 10,
    },
    menuIconStyle: {
      position: 'absolute',
      right: 15,
    },
    highlightText: {
      backgroundColor: textBackgroundColor ? textBackgroundColor : '#D0021B',
      color: textColor ? textColor : '#fff',
      paddingVertical: 4,
      paddingHorizontal: 8,
      borderRadius: 4,
      fontSize: 13,
      fontWeight: 700,
      width: 'fit-content',
      alignSelf: 'center',
    },
    errorText: {
      backgroundColor: '#D0021B',
      color: '#fff',
    },
    successText: {
      backgroundColor: '#50E3C2',
      color: '#000',
    },
  });
};
