import {StyleSheet, Dimensions} from 'react-native';

const height = Dimensions.get('window').height;

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      minHeight: `calc(${height}px - 58px)`,
      backgroundColor: '#000',
      justifyContent: 'space-between',
    },
    contentContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    packageInformation: {
      width: '90%',
      height: 'fit-content',
      maxWidth: 856,
      paddingVertical: 20,
      marginTop: 20,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      backgroundColor: 'rgba(78,76,76,0.15)',
    },
    backButton: {
      width: 250,
      margin: 20,
      alignSelf: 'center',
    },
  });
};
