import {StyleSheet} from 'react-native';

interface StyleProps {}

export const makeStyles = ({}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      paddingVertical: '2%',
    },
  });
};
