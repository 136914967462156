import React from 'react';
import {Route} from '../../navigation/react-router';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import {useHistory} from 'react-router-dom';
import {storage} from '../../storage';
import {PrimaryLoader} from '../../components';

interface ProtectedRouteProps {
  component: any;
  path: string;
}

export const ProtectedRoute = ({component, path}: ProtectedRouteProps) => {
  const history = useHistory();
  //@ts-ignore
  const getStorage = storage.cache;

  React.useEffect(() => {
    if (getStorage.token !== undefined) {
      if (getStorage.token.rawData === '') {
        history.push('/home');
      }
    }
  });

  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <PrimaryLoader />,
      })}
      path={path}
    />
  );
};
