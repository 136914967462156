import React from 'react';
import {makeStyles} from './index.styles';
import {OptionProps, Option} from '../../atoms';
import {View} from 'react-native';

interface FooterProps {
  options: OptionProps[];
  style?: any;
}

export const Footer = ({options, style}: FooterProps) => {
  const styles = makeStyles(style);
  return (
    <View style={[styles.container, style]}>
      {options.map((value, index) => (
        <Option
          key={index}
          name={value.name}
          icon={value.icon}
          route={value.route}
          onPress={value.onPress}
        />
      ))}
    </View>
  );
};

Footer.defaultProps = {
  style: {
    height: 101,
  },
  options: [
    {name: 'Limpiar', icon: 'broom'},
    {name: 'Home', icon: 'broom'},
    {name: 'Siguiente', icon: 'broom'},
  ],
};
