import React from 'react';
import {View} from 'react-native';

import {PrimarySectionsHeader, Option, OptionProps} from '../../atoms';
import {makeStyles} from './index.styles';

interface ScanCategoriesContentProps {
  options: OptionProps[];
  iconInfo: any;
  textInfo: any;
}

export const ScanCategoriesContent = ({
  options,
  iconInfo,
  textInfo,
}: ScanCategoriesContentProps) => {
  const styles = makeStyles();

  return (
    <View style={styles.container}>
      <PrimarySectionsHeader iconInfo={iconInfo} textInfo={textInfo} />
      <View style={styles.categoriesContainer}>
        {options.map((option, index) => (
          <View key={index} style={styles.containerOption}>
            <Option
              name={option.name}
              icon={option.icon}
              onPress={option.onPress}
              route={option.route}
              style={option.style}
              iconStyle={option.iconStyle}
            />
          </View>
        ))}
      </View>
    </View>
  );
};
