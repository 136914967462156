import React, {useCallback} from 'react';
import {FlatList, View} from 'react-native';

import {PrimaryText} from '../../';
import {makeStyles} from './index.styles';

interface SimplePackagesListProps {
  modalInfo: {
    title: string;
    packages: any[];
  };
}

export function SimplePackagesList({modalInfo}: SimplePackagesListProps) {
  const {title, packages} = modalInfo;
  const packsLength = packages.length;
  const styles = makeStyles();
  const keyExtractor = useCallback((item, index) => index.toString(), []);

  const checkItems = packsLength ? packages.slice(-10) : packages;
  return (
    <View style={styles.container}>
      <PrimaryText content={title} style={styles.title} />
      {packsLength > 0 && packsLength <= 10 && (
        <View style={styles.listContainer}>
          <FlatList
            data={checkItems}
            windowSize={10}
            inverted={true}
            style={styles.list}
            keyExtractor={keyExtractor}
            renderItem={(item: any) => (
              <PrimaryText
                style={styles.highlightedText}
                content={item.item.internalId}
              />
            )}
          />
        </View>
      )}
      {packsLength > 10 && (
        <View style={styles.errorContainer}>
          <PrimaryText
            content={`Número de ${title} mayor a 10`}
            style={styles.highlightedText}
          />
          <PrimaryText
            content={'No es posible realizar el cruce de paquetes en la app'}
            style={styles.highlightedText}
          />
        </View>
      )}
      {packsLength <= 0 && (
        <View style={styles.errorContainer}>
          <PrimaryText
            content={'No hay paquetes erróneos'}
            style={styles.highlightedText}
          />
        </View>
      )}
    </View>
  );
}
