import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  color: ColorValue;
}

export const makeStyles = ({color}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      paddingBottom: 20,
    },
    iconContainer: {
      width: 60,
      height: 60,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: color,
      borderRadius: 50,
      position: 'absolute',
      top: -30,
      zIndex: 1,
    },
    icon: {
      color: '#fff',
      fontWeight: '700',
    },
    indicator: {
      width: '100%',
      height: 7,
      backgroundColor: color,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
    },
    header: {
      fontSize: 24,
      fontWeight: '700',
      color: '#383434',
    },
    modalContentContainer: {
      backgroundColor: 'transparent',
      flex: 1,
      margin: 4,
      marginBottom: 15,
      paddingTop: 14,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    qrContainer: {
      marginHorizontal: 'auto',
      marginTop: 20,
    },
    title: {
      color: '#383434',
      fontSize: 17,
      fontWeight: '700',
      marginBottom: 5,
    },
    subTitle: {
      fontSize: 15,
      marginBottom: 5,
      color: '#383434',
    },
    highlightedText: {
      backgroundColor: '#eef0f7',
      marginBottom: 5,
      width: 'fit-content',
      paddingVertical: 5,
      paddingHorizontal: 10,
      alignSelf: 'center',
      color: '#000',
      borderRadius: 4,
    },
    buttonContainer: {
      width: 120,
      height: 40,
    },
    finishButton: {
      flex: 1,
      backgroundColor: color,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
    },
    finishButtonTitle: {
      marginTop: 0,
    },
  });
};
