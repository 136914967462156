import React from 'react';
import {Picker} from 'react-native';
import {makeStyles} from './index.styles';

interface PrimaryButtonProps {
  backgroundColor?: any;
  options: any;
  width?: number | string;
  selectedValue: string;
  onChange: (value: string) => void;
  style?: any;
}
export interface warehousesProps {
  id: string;
  name: string;
}

export const DropdownList = ({
  backgroundColor,
  options,
  width,
  selectedValue,
  onChange,
  style,
}: PrimaryButtonProps) => {
  const styles = makeStyles({backgroundColor, width});
  return (
    <>
      <Picker
        selectedValue={selectedValue}
        style={[styles.container, style]}
        onValueChange={onChange}>
        {options.map((item: any, index: number) => {
          return (
            <Picker.Item label={item.name} value={item.id} key={item.id} />
          );
        })}
      </Picker>
    </>
  );
};

DropdownList.defaultProps = {
  title: 'Title',
  backgroundColor: '#F00',
  options: [
    {id: 0, name: 'Example 1'},
    {id: 1, name: 'Example 2'},
  ],
  width: '100%',
};
