import {StyleSheet} from 'react-native';

interface StyleProps {
  width: number | string;
}

export const makeStyles = ({width}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      width,
    },
    title: {
      color: '#121212',
      height: 22,
      width: 306,
      fontSize: 16,
      textAlign: 'left',
      fontWeight: 700,
      marginTop: 21,
      marginLeft: 35,
    },
    authText: {
      color: 'rgba(145,163,176,1)',
      fontSize: 13,
      textAlign: 'left',
      marginTop: 19,
      marginLeft: 35,
      fontWeight: 700,
    },
    buttonsContainer: {
      width: 'fit-content',
      marginTop: 20,
      alignItems: 'center',
    },
    containerZone: {
      paddingHorizontal: 20,
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    selectsLabel: {
      marginBottom: 5,
      fontSize: 14,
      fontWeight: 700,
    },
    tableContainer: {
      width: '100%',
      padding: 20,
    },
  });
};
