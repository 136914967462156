import Storage from 'react-native-storage';
import AsyncStorage from '@react-native-community/async-storage';
import {Platform} from 'react-native';

export const storage = new Storage({
  size: 1000,
  storageBackend:
    //@ts-ignore
    Platform.OS === 'android' || 'ios' ? AsyncStorage : window.localStorage,
  defaultExpires: null,
  enableCache: true,
  sync: {},
});
