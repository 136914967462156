import {StyleSheet, Dimensions} from 'react-native';
const ScreenHeight = Dimensions.get('window').height;

interface StyleProps {
  width: number | string;
}

export const makeStyles = ({width}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      width,
      height: ScreenHeight,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    image: {
      width: '80%',
      height: '16rem',
      resizeMode: 'contain',
    },
    spacingInputs: {
      padding: 5,
    },
    containerLogo: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '11%',
      flex: 2,
    },
    containerInputs: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
      width: '100%',
      paddingHorizontal: '7%',
    },
    separatorInputs: {
      padding: '2%',
    },
    containerButton: {
      width: '50%',
      maxWidth: 500,
      minWidth: 200,
      flex: 0.5,
      paddingHorizontal: '7%',
      paddingVertical: '3%',
    },
    experimentalLoginCont: {
      width: 300,
      marginBottom: 10,
    },
    experimentalLogin: {
      backgroundColor: '#000',
    },
  });
};
