import React from 'react';
import {Pressable, View} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import {makeStyles} from '../IdCardContent/index.styles';
import {PrimaryText, PrimaryButton, PrimaryIndicator} from '../..';
import dayjs from 'dayjs';

interface HomeNotificationProps {
  width: any;
  onPress: () => void;
  styleButton: any;
  styleTitle: any;
  styleButtonCont: any;
  manifests: any;
  pendingMovements: any;
  isLoading: boolean;
  detailHandler: (a: any) => void;
  displayDetailHandler: (a: any) => void;
}

export const HomeNotificationsContent = ({
  width,
  onPress,
  styleButton,
  styleTitle,
  styleButtonCont,
  manifests,
  pendingMovements,
  isLoading,
  detailHandler,
  displayDetailHandler,
}: HomeNotificationProps) => {
  const movements =
    pendingMovements !== undefined
      ? pendingMovements
      : 'Sin movimientos pendientes';
  const styles = makeStyles(width);

  const handleLongPress = (detail: any) => {
    detailHandler(detail);
    displayDetailHandler(true);
  };

  const handlePress = (detail: any) => {
    detailHandler(detail);
    displayDetailHandler(true);
  };

  const handlePressOut = () => {
    displayDetailHandler(false);
  };

  return (
    <View style={styles.summary}>
      <View style={styles.summaryRow}>
        <PrimaryText
          content={'Resumen de actividad de hoy'}
          fontSize={15}
          fontWeight={'700'}
          style={styles.summaryHeader}
        />
        <PrimaryButton
          title={isLoading ? 'Cargando...' : 'Actualizar'}
          onPress={onPress}
          styleButton={styleButton}
          styleTitle={styleTitle}
          style={styleButtonCont}
        />
      </View>
      <View style={styles.registry}>
        <PrimaryText
          content={'Movimientos pendientes'}
          fontWeight={'700'}
          fontSize={13}
        />
        <View style={styles.pendingMovements}>
          <PrimaryText
            style={styles.pendingText}
            content={movements}
            fontSize={13}
          />
        </View>
      </View>
      <View style={styles.manifestsContainer}>
        <PrimaryText
          content={'Lista de manifiestos'}
          fontWeight={'700'}
          fontSize={13}
          margin={5}
        />
        <View style={styles.manifests}>
          {manifests.length > 0 ? (
            manifests.map((item: any, index: number) => {
              const formatedDate = dayjs(item.createdAt)
                .locale('de')
                .format('DD-MM-YYYYTHH:mm:ss');

              const titles = ['Id: ', 'Fecha: ', 'Hora: ', 'Total: '];
              const data = [
                item.id,
                formatedDate.slice(0, 10),
                formatedDate.slice(11, 16),
                item.packages.count,
              ];

              const detail = {
                id: data[0],
                date: data[1],
                hour: data[2],
                count: data[3],
                warehouse: item.warehouse.name,
                authorizedByName: item.authorizedByName,
                comments: item.comments,
              };
              return (
                <Pressable
                  onPress={() => handlePress(detail)}
                  onLongPress={() => handleLongPress(detail)}
                  onPressOut={handlePressOut}
                  delayLongPress={175}
                  key={index}>
                  <View style={styles.manifestsInnerContainer}>
                    <View style={styles.manifestsIconContainer}>
                      <Icon
                        name={'md-alert-circle-sharp'}
                        size={6}
                        style={styles.manifestsIcon}
                      />
                    </View>
                    <View>
                      {titles.map((title: string, index2: number) => {
                        return (
                          <View style={styles.manifestsRow} key={index2}>
                            <PrimaryText
                              content={title}
                              fontSize={12}
                              fontWeight={'700'}
                            />
                            <PrimaryText content={data[index2]} fontSize={12} />
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </Pressable>
              );
            })
          ) : (
            <View style={styles.pendingMovements}>
              <PrimaryIndicator style={styles.manifestsIndicator} />
              <PrimaryText
                content={'Sin manifiestos en las últimas 24 horas'}
                style={styles.withoutManifest}
              />
              <PrimaryIndicator style={styles.manifestsIndicator} />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

HomeNotificationsContent.defaultProps = {};
