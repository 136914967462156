import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  borderColor?: ColorValue;
  borderWidth?: number;
  borderRadius?: number;
}

export const makeStyles = ({
  borderWidth,
  borderColor,
  borderRadius,
}: StyleProps): any => {
  return StyleSheet.create({
    containerLogin: {
      width: '100%',
      height: '100%',
    },
    password: {
      lineHeight: 18,
      color: 'black',
      fontSize: 12,
      letterSpacing: 0.1,
      paddingBottom: 5,
    },
    passwordFiller: {
      width: '100%',
      height: '100%',
      color: 'black',
      fontSize: 15,
      fontWeight: 'medium',
      textAlign: 'left',
      paddingHorizontal: 7,
      lineHeight: 30,
    },
    container: {
      backgroundColor: 'rgba(241,243,250,1)',
      borderWidth: borderWidth ?? 0,
      borderColor: borderColor ?? 'transparent',
      borderRadius: borderRadius ?? 0,
      padding: 0,
    },
    textarea: {
      fontSize: 12,
      color: 'black',
      textAlignVertical: 'top',
    },
    placeholder: {
      color: 'rgba(145,163,176,1)',
    },
  });
};
