import React, {useEffect} from 'react';
import {View, Text} from 'react-native';
import {makeStyles} from './index.styles';
import {
  CountersComponent,
  DropdownList,
  PrimaryText,
  KeepingsInformation,
  warehousesProps,
} from '../../';
import {handlerCounters} from '../../../screens';
import {useStorageItem} from '../../../common/utils';

interface SubZoneProps {
  id: string;
  name: string;
}

interface MovementsProps {
  style?: any;
  zoneName: string;
  actionName: string;
  zoneNames: warehousesProps[];
  actionNames: warehousesProps[];
  subZoneName: string;
  subZoneNames: SubZoneProps[];
  onChangeZone: (value: any) => void;
  onChangeAction: (value: any) => void;
  onChangeSubZone: (value: any) => void;
  handleOpenModal: (item: any) => void;
}

export const Movements = ({
  style,
  zoneName,
  actionName,
  zoneNames,
  actionNames,
  subZoneNames,
  subZoneName,
  onChangeZone,
  onChangeAction,
  onChangeSubZone,
  handleOpenModal,
}: MovementsProps) => {
  const cacheData = useStorageItem('packagesList');
  const keepings = useStorageItem('keepings');
  const styles = makeStyles(style);

  const counters = [
    {
      id: 1,
      name: 'Total',
      packages: cacheData?.length,
    },
    {
      id: 2,
      name: 'Ok',
      packages: handlerCounters(cacheData, true),
    },
    {
      id: 3,
      name: 'Pending',
      packages: handlerCounters(cacheData, false),
    },
  ];

  const preSelectedZone = (() => {
    if (keepings) {
      const keepingsZone = zoneNames.find(
        (item: any) => item.name === 'RESGUARDOS',
      );
      return [true, keepingsZone?.id || 'error', keepingsZone?.name || 'error'];
    }
    return [false];
  })();

  useEffect(() => {
    if (preSelectedZone[0]) {
      //RESGUARDOS ID
      onChangeZone(preSelectedZone[1]);
    }
  }, [preSelectedZone, onChangeZone]);

  return (
    <>
      <View style={[styles.container, style]}>
        <Text style={styles.title}>Tráfico de paquetes</Text>
        {/*<OrdersList /> */}
        <CountersComponent counters={counters} />
        <Text style={styles.title}>Particularidades</Text>
        <Text style={styles.authText}>
          Seleccione el área de recepción y el tipo de movimiento
        </Text>
        <View style={styles.containerZone}>
          <View style={styles.buttonsContainer}>
            <PrimaryText content={'Zona'} style={styles.selectsLabel} />
            <DropdownList
              selectedValue={zoneName}
              onChange={onChangeZone}
              backgroundColor={'#000'}
              options={
                preSelectedZone[0]
                  ? zoneNames.filter(
                      (item: any) => item.name === preSelectedZone[2],
                    )
                  : ['', ...zoneNames]
              }
            />
          </View>
          <View style={styles.buttonsContainer}>
            <PrimaryText content={'Acción'} style={styles.selectsLabel} />
            <DropdownList
              selectedValue={actionName}
              onChange={onChangeAction}
              backgroundColor={'#000'}
              options={['', ...actionNames]}
            />
          </View>
        </View>
        {keepings && (
          <View style={styles.tableContainer}>
            <KeepingsInformation
              activeButton={true}
              onPress={(item) => handleOpenModal(item)}
            />
          </View>
        )}
      </View>
    </>
  );
};

Movements.defaultProps = {
  style: {
    width: '100%',
  },
};
