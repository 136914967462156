import React, {useState, useEffect, ChangeEvent} from 'react';
import {View, ScrollView} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

import {
  Modal,
  Input,
  PrimaryLoader,
  PrimaryText,
  PrimaryIndicator,
  PrimaryButton,
  PrimaryIconLink,
} from '../../';
import {makeStyles} from './index.styles';
import {getStorageItem} from '../../../common/utils';

interface SearchModalProps {
  handleSearch: (
    manifestId: string,
  ) => Promise<{hasErrors: boolean; messageResponse: string}>;
  onPressOutside: () => void;
  handleClearAll: () => void;
  handleClearOne: (id: string) => void;
}

export function SearchModal({
  handleSearch,
  onPressOutside,
  handleClearAll,
  handleClearOne,
}: SearchModalProps) {
  const [input, setInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<any>({
    isLoading: false,
    isErasing: false,
  });
  const [outputText, setOutputText] = useState<string>(
    'Introduza el código a continuación',
  );
  const manifests = getStorageItem('manifestData');
  const [color, setColor] = useState('#383434');

  const styles = makeStyles({color});

  const handleInputChange = (e: ChangeEvent) => {
    setInput(`${e}`);
  };

  const handlePress = async () => {
    setIsLoading({isLoading: true, isErasing: false});
    if (input) {
      const cleanedInput = input.trim();
      await handleSearch(cleanedInput).then((r) => {
        const {hasErrors, messageResponse} = r;
        setOutputText(messageResponse);
        if (hasErrors) {
          setColor('#D0021B');
          setIsLoading(false);
          setInput('');
        } else {
          setIsLoading(false);
          setColor('#48BF4D');
        }
      });
    } else {
      setIsLoading({isLoading: false, isErasing: false});
      setColor('#D0021B');
    }
    setInput('');
  };

  const handleKeyDown = (e: any) => {
    if (e.nativeEvent.key === 'Enter') {
      if (input.trim() !== '') {
        handlePress();
      } else {
        setInput('');
        setOutputText('No se ha ingresado ningún valor');
        setColor('#D0021B');
      }
    }
  };

  const handleCleanManifestById = (id: string) => {
    setIsLoading({isLoading: true, isErasing: true});

    handleClearOne(id);

    setTimeout(() => {
      setIsLoading({isLoading: false, isErasing: false});
    }, 1000);
  };

  const handleCleanManifests = () => {
    setIsLoading({isLoading: true, isErasing: true});
    handleClearAll();
    setTimeout(() => {
      setIsLoading({isLoading: false, isErasing: false});
    }, 1000);
  };

  return (
    <Modal onPressOutside={onPressOutside} style={styles.modal}>
      {isLoading.isLoading ? (
        <PrimaryLoader
          title={isLoading.isErasing ? ' Borrando...' : 'Cargando...'}
          style={styles.containerLoader}
        />
      ) : (
        <>
          <View style={styles.iconContainer}>
            <Icon name="newspaper-outline" size={32} style={styles.icon} />
          </View>
          <PrimaryIndicator style={styles.indicator} />
          <PrimaryText content={'Manifiesto'} style={styles.header} />
          <View style={styles.searchContainer}>
            <PrimaryText content={outputText} style={styles.textInstruction} />
            <View style={styles.inputSearchContainer}>
              <Input
                placeholder="Id del manifiesto"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyDown}
                style={styles.input}
                type={''}
                autofocus={true}
              />
              <PrimaryIconLink
                onPress={handlePress}
                style={styles.searchButtonContainer}
                iconStyle={styles.searchIcon}
                iconName={'search'}
                iconSize={20}
              />
            </View>
          </View>
          {!!manifests.ids.length && (
            <View style={styles.manifestsContainer}>
              <View style={styles.manifestsHeaderContainer}>
                <PrimaryText
                  content={'Lista añadidos'}
                  style={styles.manifestListTitle}
                />
                <View style={styles.cleanButtonCont}>
                  <PrimaryButton
                    style={styles.cleanButtonStyleCont}
                    styleButton={styles.cleanButtonStyle}
                    styleTitle={styles.cleanButtonTitle}
                    title={'Limpiar'}
                    onPress={handleCleanManifests}
                  />
                </View>
              </View>
              <ScrollView style={styles.scrollManifestsContainer}>
                {manifests.ids.map((item: string, index: number) => (
                  <View key={index} style={styles.manifestContainer}>
                    <PrimaryText content={`${index + 1}`} fontSize={12} />
                    <PrimaryText content={item} fontSize={12} />
                    <PrimaryIconLink
                      onPress={() => handleCleanManifestById(item)}
                      style={{}}
                      iconName={'remove-circle'}
                      iconSize={23}
                      iconStyle={styles.manifestIcon}
                    />
                  </View>
                ))}
              </ScrollView>
            </View>
          )}
          <View style={styles.continueButtonContainer}>
            <PrimaryButton
              title={'Continuar'}
              style={styles.continueButton}
              styleButton={styles.continueButton}
              styleTitle={styles.continueButtonTitle}
              onPress={onPressOutside}
            />
          </View>
        </>
      )}
    </Modal>
  );
}
