import React from 'react';
import {View, Image} from 'react-native';
import {makeStyles} from './index.styles';
import {logo} from '../../../common/assets';

import AuthenticationButton from '../../molecules/AuthenticateButton';

interface LoginProps {
  style?: any;
}

export const Login = ({style}: LoginProps) => {
  const styles = makeStyles(style);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.containerLogo}>
        <Image source={logo} style={styles.image} />
      </View>
      <View style={styles.containerButton}>
        <AuthenticationButton path={'/home'} />
      </View>
    </View>
  );
};

Login.defaultProps = {
  style: {
    width: '100%',
  },
};
