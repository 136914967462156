import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {View, TouchableOpacity, Image} from 'react-native';
import {makeStyles} from './index.styles';
import Icon from 'react-native-vector-icons/Ionicons';

import {PrimaryText, PrimaryIconLink, Modal} from '../index';
import {LogoutButton} from '../LogoutButton';
import {menuLogo} from '../../../common/assets';
import {Link} from '../../../navigation/react-router';
import {WO_VERSION} from '../../../config';

interface SideMenuProps {
  sideMenuOptions: sideMenuOptionsProps[];
  onPress: () => void;
  style?: any;
  onPressOutside?: () => void;
}

interface sideMenuOptionsProps {
  name: string;
  route: string;
  icon: string;
}

export const SideMenu = ({
  sideMenuOptions,
  onPress,
  style,
  onPressOutside,
}: SideMenuProps) => {
  const {user} = useAuth0();
  const styles = makeStyles();
  const menuTextColor = '#DEE3F3';
  const menuLink = {textDecoration: 'none'};

  return (
    <Modal
      style={styles.modal}
      containerStyle={styles.modalContainer}
      onPressOutside={onPressOutside}>
      <View style={[styles.container, style]}>
        <PrimaryIconLink
          onPress={() =>
            onPressOutside ? onPressOutside() : console.log('close')
          }
          style={styles.menuButton}
          iconName={'close'}
          iconSize={22}
          iconStyle={styles.menuIcon}
        />
        <View style={styles.user}>
          <View style={styles.userIcon}>
            <Image source={menuLogo} style={styles.userSvg} />
          </View>
          <PrimaryText
            content={user.name}
            color={menuTextColor}
            fontSize={12}
            fontStyle={'italic'}
          />
          <PrimaryText
            content={user.email}
            color={menuTextColor}
            margin={5}
            fontSize={12}
            fontStyle={'italic'}
          />
        </View>
        <View style={styles.categoriesContainer}>
          {sideMenuOptions.map((option, index) => (
            <Link to={option.route} style={menuLink} key={index}>
              <TouchableOpacity style={styles.menu} onPress={() => onPress}>
                <Icon
                  name={option.icon}
                  color={menuTextColor}
                  style={styles.icon}
                />
                <PrimaryText
                  content={option.name}
                  fontWeight={700}
                  color={menuTextColor}
                />
              </TouchableOpacity>
            </Link>
          ))}
        </View>
        <View style={styles.logoutButtonContainer}>
          <LogoutButton
            style={styles.logoutButtonCont}
            styleButton={styles.logoutButton}
            styleTitle={styles.logoutButtonTitle}
          />
        </View>
        <View style={styles.versionContainer}>
          <PrimaryText
            content={`Versión: ${WO_VERSION ? WO_VERSION : 'Sin datos'}`}
            style={styles.appVersion}
          />
        </View>
      </View>
    </Modal>
  );
};

SideMenu.defaultProps = {};
