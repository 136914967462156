import React from 'react';
import {View} from 'react-native';

import {PrimaryText} from '../';
import {makeStyles} from './index.styles';

export interface ItemProps {
  id: number;
  internalId: string;
  referenceId: string;
}

export interface StyleProps {
  textWeight: string | number;
  itemColor: string;
  textStyle: any;
}

interface KeepingRowProps {
  item: ItemProps;
  style: StyleProps;
  children: React.ReactNode;
}

export function KeepingRow({item, style, children}: KeepingRowProps) {
  const {internalId, referenceId} = item;
  const {textWeight, itemColor, textStyle} = style;
  const styles = makeStyles({itemColor});
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <PrimaryText
          style={textStyle}
          content={`${internalId}\n${referenceId}`}
          fontWeight={textWeight}
        />
      </View>
      <View style={styles.row}>{children}</View>
    </View>
  );
}
