import React from 'react';
import {View} from 'react-native';
import QRCode from 'react-qr-code';
import {PrimaryText} from '../..';
import {makeStyles} from './index.styles';

interface ManifestDetailProps {
  warehouse: string;
  id: string;
  date: string;
  hour: string;
  count: number;
  authorizedByName: string;
  comments: string | null;
}

export const ManifestDetail = ({detail}: {detail: ManifestDetailProps}) => {
  const styles = makeStyles();
  const titles = [
    'Almacen: ',
    'Id: ',
    'Fecha: ',
    'Hora: ',
    'Total: ',
    'Autoriz.: ',
  ];
  const data = [
    `${detail?.warehouse}`,
    `${detail?.id}`,
    `${detail?.date}`,
    `${detail?.hour}`,
    `${detail?.count}`,
    `${detail?.authorizedByName}`,
  ];
  return (
    <View>
      <PrimaryText
        content="Detalle"
        fontSize={22}
        fontWeight={800}
        style={styles.title}
      />
      <View style={styles.qrContainer}>
        <QRCode value={detail?.id} size={180} />
      </View>
      {titles.map((title, index) => {
        return (
          <View style={styles.row} key={index}>
            <PrimaryText content={title} fontWeight={700} fontSize={16} />
            <PrimaryText content={data[index]} fontSize={16} />
          </View>
        );
      })}

      <View style={!detail.comments ? styles.row : {}}>
        <PrimaryText content={'Comentarios: '} fontWeight={700} fontSize={16} />
        <PrimaryText
          content={`${detail?.comments || 'Sin comentarios.'}`}
          fontSize={16}
        />
      </View>
    </View>
  );
};
