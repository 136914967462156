import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
    },
    noPermissionsStyle: {
      width: '100%',
      height: '100%',
      borderWidth: 4,
      borderColor: '#000',
      borderRadius: 4,
      backgroundColor: '#F8F9FC',
    },
  });
};
