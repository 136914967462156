import React from 'react';
import {View, TouchableWithoutFeedback} from 'react-native';
import {makeStyles} from './index.styles';
interface ModalProps {
  children?: React.ReactNode;
  onPressOutside?: () => void;
  style?: any;
  containerStyle?: any;
}

export const Modal = ({
  children,
  onPressOutside,
  style,
  containerStyle,
}: ModalProps) => {
  const styles = makeStyles();

  return (
    <TouchableWithoutFeedback onPress={onPressOutside || (() => {})}>
      <View style={[styles.container, containerStyle]}>
        <TouchableWithoutFeedback onPress={(): void => {}}>
          <View style={[styles.modal, style]}>{children}</View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
};
