import React, {useEffect} from 'react';
import {Animated, View, ScrollView} from 'react-native';

import {PrimarySwitch, PrimaryText} from '../../atoms';
import {makeStyles} from './index.styles';

interface SubFooterProps {
  switchOptions: SubFooterOption[];
  animation?: any;
  position?: any;
  children?: React.ReactNode;
}

interface SubFooterOption {
  switchStyle: SubFooterStyle;
  onValueChange: () => void;
  isEnabled: boolean;
  name: string;
  styleContainer?: any;
}

interface SubFooterStyle {
  trackColorFalse: string;
  trackColorTrue: string;
  iosColor: string;
  thumbColorFalse: string;
  thumbColorTrue: string;
  style?: any;
}

export const SubFooter = ({
  switchOptions,
  animation,
  position,
  children,
}: SubFooterProps) => {
  const styles = makeStyles({animation, position});
  return (
    <Animated.View style={styles.animation}>
      <View style={styles.container}>
        {children}
        <ScrollView
          horizontal={true}
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContent}
          showsHorizontalScrollIndicator={true}
          removeClippedSubviews={true}>
          {switchOptions.map((option, index) => {
            return (
              <View
                key={index}
                style={[styles.optionSwitchContainer, option.styleContainer]}>
                <PrimaryText
                  content={option.name}
                  fontSize={15}
                  fontWeight={700}
                />
                <PrimarySwitch
                  switchStyle={option.switchStyle}
                  onValueChange={option.onValueChange}
                  isEnabled={option.isEnabled}
                />
              </View>
            );
          })}
        </ScrollView>
      </View>
    </Animated.View>
  );
};

SubFooter.defaultProps = {};
