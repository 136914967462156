import {StyleSheet} from 'react-native';

import shadow from '../../../common/style';

interface StyleProps {
  animation: any;
  position: any;
}

export const makeStyles = ({animation, position}: StyleProps): any => {
  return StyleSheet.create({
    animation: {
      opacity: animation,
      transform: [
        {
          translateY: position,
        },
      ],
      width: '100%',
      height: 'fit-content',
      paddingHorizontal: 30,
      marginBottom: 30,
    },
    container: {
      width: '100%',
      maxWidth: 450,
      alignSelf: 'center',
      height: 'fit-content',
      backgroundColor: '#F8F8FF',
      paddingVertical: 30,
      borderRadius: 10,
      paddingHorizontal: 20,
      ...shadow({
        elevation: 7,
        color: 'rgba(227, 228, 229, 0.5)',
        opacity: 0.15,
        radius: 18,
        offsetWidth: -10,
        offsetHeight: 10,
      }),
    },
    scrollView: {
      width: '100%',
      height: '100%',
      marginTop: 20,
    },
    scrollViewContent: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 2,
    },
    optionSwitchContainer: {
      width: 84,
      height: 50,
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 1,
    },
  });
};
