import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {Animated, View} from 'react-native';
import {useAuth0} from '@auth0/auth0-react';
import {useHistory} from 'react-router';

import {
  Header,
  InputSearch,
  PackagesList,
  CountersComponent,
  Footer,
  PackageInfo,
  ContainerScreen,
  PrimaryIconLink,
  SideMenu,
  SubFooter,
  PrimaryLoader,
  PrimaryText,
  SearchModal,
  ManifestInformation,
  ManifestErrorsModal,
  ModalKeepingsSelection,
} from '../../components';
import {
  sideMenuOptions,
  zoneCitiesCreator,
  zoneHubsCreator,
} from '../../common/models/index.variables';
import {PrintersSubFooterOptions} from '../../components';
import {ScanCamera} from '../ScanCamera';
import {makeStyles} from './index.styles';
import {handleAnalytics} from '../../common/integrations/firebase';
import {initialPackage} from '../../common/models/index.variables';
import client, {
  getPackageInfo,
  getManifestByIdInfo,
  fetchPackageInfo,
} from '../../common/integrations/wo-api/client';
import {sendPrint} from '../../common/integrations/printer';
import {storage} from '../../storage';
import {Sound} from '../../common/sound/sound';
import {WO_VERSION} from '../../config';
import {
  useStorageItem,
  saveOnStorage,
  getStorageItem,
} from '../../common/utils';
import {formatDate} from '../../common/utils/DateFormater';
import {packageStatus} from './constants';

interface SearchStateProps {
  match: any;
}
interface PackDataProps {
  trackingNumber: string;
  trackingTitle: string;
  zoneName: string;
  order?: string;
  info: PackageInfoProps[];
}

interface PackageInfoProps {
  title: string;
  value: any;
  color: string;
}

interface IManifestsData {
  ids: string[];
  count: number;
  guides: any[];
}

interface IdName {
  id: string;
  name: string;
}

interface ICancelProps extends IdName {
  reason?: string;
}

export function handlerCounters(data: any, type: boolean) {
  let totalExist = 0;
  let totalNoExist = 0;
  data?.forEach((item: any) => {
    if (item.exist) {
      totalExist++;
    } else {
      totalNoExist++;
    }
  });
  return type ? totalExist : totalNoExist;
}

export const ConsolidatedSearch = ({match}: SearchStateProps) => {
  const history = useHistory();
  const cacheData = getStorageItem('packagesList');
  const manifestData = getStorageItem('manifestData');
  const manifestExceeds = getStorageItem('manifestExceeds');
  const printData = getStorageItem('printOption');
  const insertData = getStorageItem('insertOption');
  const printerOptions = getStorageItem('subFootOptions');
  const whId = useStorageItem('whId');

  const [sideMenu, setSideMenu] = useState<boolean>(false);
  const [fastSearch, setFastSearch] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [scanCode, setScanCode] = useState<string>('');
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const opacity = useState(new Animated.Value(0))[0];
  const position = useState(new Animated.Value(57))[0];
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [print, setPrint] = useState<boolean>(false);
  const [enabledInsert, setEnabledInsert] = useState<boolean>(insertData);
  const [option1, setOption1] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadToken, setLoadToken] = useState<boolean>(true);
  const [zonePrint, setZonePrint] = useState('');
  const [printer, setPrinter] = useState('');
  const [printMode, setPrintMode] = useState<string>('1');
  const [displayToast, setDisplayToast] = useState<boolean>(false);
  const [packagesList, setPackagesList] = useState<any[]>([]);
  const [packageInfo, setPackageInfo] = useState<PackDataProps>(initialPackage);
  const [scanMode, setScanMode] = useState(false);
  const {getIdTokenClaims, user} = useAuth0();
  const [displaySearchModal, setDisplaySearchModal] = useState<boolean>(false);
  const [manifestInput, setManifestInput] = useState<IManifestsData>({
    ids: [],
    count: 0,
    guides: [],
  });
  const [errorsList, setErrorsList] = useState<any[]>([]);
  const [checkedErrors, setCheckedErrors] = useState<any[]>([]);
  const [displayErrorsModal, setDisplayErrorsModal] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [keepings, setKeepings] = useState<boolean>(getStorageItem('keepings'));
  const [manualSubZoneSelect, setManualSubZoneSelect] =
    useState<boolean>(false);
  const [currentPackage, setCurrentPackage] = useState<any>({id: ''});
  const [cancelStatus, setCancelStatus] = useState<ICancelProps>({
    id: '',
    name: '',
    reason: '',
  });
  const styles = makeStyles();

  useStorageItem('manifestData', setManifestInput);

  // State Listeners and LifeCycle methods

  const SoundFactory = (string: string) => {
    return useMemo(() => {
      return new Sound(`/${string}.mp3`);
    }, [string]);
  };

  const scanningSound = SoundFactory('scanning');
  const scanningSoundError = SoundFactory('error');
  const cdmxSound = SoundFactory('CDMX');
  const hmx1Sound = SoundFactory('HMX1');
  const hmx2Sound = SoundFactory('HMX2');
  const AR1Sound = SoundFactory('AR1');
  const ARPSound = SoundFactory('ARP');
  const ISSound = SoundFactory('IS');
  const ISHSound = SoundFactory('ISH');
  const TENSound = SoundFactory('TEN');
  const TEPSound = SoundFactory('TEP');
  const ITRUSound = SoundFactory('ITRU');
  const IZTSound = SoundFactory('IZT');
  const RDASound = SoundFactory('RDA');
  const gdlSound = SoundFactory('GDL');
  const mtySound = SoundFactory('MTY');
  const tolSound = SoundFactory('TOL');
  const pueSound = SoundFactory('PUE');
  const qroSound = SoundFactory('QRO');
  const dabbaSound = SoundFactory('DABBA');
  const grrSound = SoundFactory('GRR');
  const raamSound = SoundFactory('RAAM');
  const noInfoSound = SoundFactory('noInfoSound');

  const compareGuides = (
    guideNumber: string,
    reference: string,
    exist: boolean,
  ) => {
    if (manifestInput.count) {
      const manifest = manifestInput?.guides.find(
        (guide: {internalId: string; externalId: string}) =>
          guide.internalId === guideNumber || guide.externalId === guideNumber,
      );
      if (!manifest) {
        const sync = exist ? 'sync' : 'no-sync';
        const data = [
          ...errorsList,
          {
            internalId: guideNumber,
            externalId: reference,
            exist: exist,
            status: sync,
          },
        ];
        saveOnStorage('manifestExceeds', data);
      }
    }
  };

  const handleSendPrint = useCallback(
    (packInfo: any) => {
      sendPrint(
        packInfo,
        {
          printer: printer,
          device: zonePrint,
          localPrint: printMode,
        },
        setDisplayToast,
      );
    },
    [printer, zonePrint, printMode],
  );

  useEffect(() => {
    if (match.params.state === 'fast-search') {
      setFastSearch(true);
    }
  }, [match.params.state]);

  useEffect(() => {
    if (displayToast) {
      setTimeout(() => {
        setDisplayToast(false);
      }, 5000);
    }
  }, [displayToast]);
  // Style variables

  const switchStyleConst = {
    trackColorFalse: '#767577',
    trackColorTrue: '#dee3f3',
    iosColor: '#3e3e3e',
    thumbColorFalse: '#f4f3f4',
    thumbColorTrue: '#08948c',
    style: {
      marginTop: 5,
    },
  };

  // Package Info options variables
  const zoneCities = zoneCitiesCreator(
    whId,
    hmx1Sound,
    hmx2Sound,
    gdlSound,
    mtySound,
    tolSound,
    qroSound,
    pueSound,
    AR1Sound,
    ARPSound,
    ISSound,
    ISHSound,
    TENSound,
    TEPSound,
    ITRUSound,
    IZTSound,
    RDASound,
    dabbaSound,
    grrSound,
    raamSound,
    noInfoSound,
  );

  const zoneHubs = zoneHubsCreator(hmx1Sound, AR1Sound);

  const counters = [
    {
      id: 1,
      name: 'Total',
      packages: cacheData.length,
    },
    {
      id: 2,
      name: 'Ok',
      packages: handlerCounters(cacheData, true),
    },
    {
      id: 3,
      name: 'Error',
      packages: handlerCounters(cacheData, false),
    },
  ];

  const subFooterOptions = fastSearch
    ? [
        {
          switchStyle: switchStyleConst,
          onValueChange: () => {
            if (!keepings) {
              saveOnStorage('packagesList', []);
            }
            saveOnStorage('keepings', !keepings);
            setKeepings((prevState) => !prevState);
          },
          isEnabled: keepings,
          name: 'Resguardos',
          styleContainer: {},
        },
        {
          switchStyle: switchStyleConst,
          onValueChange: () => {
            saveOnStorage('insertOption', !insertData);
            setEnabledInsert((prevState) => !prevState);
          },
          isEnabled: enabledInsert,
          name: 'Mixto',
          styleContainer: {},
        },
        {
          switchStyle: switchStyleConst,
          onValueChange: () => {
            saveOnStorage('printOption', !printData);
            setPrint((prevState) => !prevState);
          },
          isEnabled: print,
          name: 'Autoimprimir',
          styleContainer: {},
        },
      ]
    : [
        {
          switchStyle: switchStyleConst,
          onValueChange: () => {
            setOption1((previousState) => !previousState);
          },
          isEnabled: option1,
          name: 'Option 1',
          styleContainer: {},
        },
      ];

  const footerOptions = [
    {
      name: 'Limpiar',
      icon: 'md-brush',
      onPress: () => clearList(),
    },
    {
      name: 'Opciones',
      icon: 'settings',
      onPress: () => {
        handleOptionsMenuAnimation();
      },
    },
    {
      name: 'Siguiente',
      icon: 'ios-arrow-forward',
      onPress: () => {
        handleNext();
      },
    },
  ];

  // storage methods

  storage
    .load({
      key: 'packagesList',
    })
    .then((r) => {
      setPackagesList(r);
    })
    .catch((err) => {
      console.warn(err.message);
      switch (err.name) {
        case 'NotFoundError':
          // TODO;
          break;
        case 'ExpiredError':
          // TODO
          break;
      }
    });

  storage
    .load({
      key: 'manifestExceeds',
    })
    .then((r) => {
      setErrorsList(r);
    })
    .catch((err) => {
      console.warn(err.message);
      switch (err.name) {
        case 'NotFoundError':
          // TODO;
          break;
        case 'ExpiredError':
          // TODO
          break;
      }
    });

  useEffect(() => {
    (async () => {
      try {
        const token = await getIdTokenClaims();
        await saveOnStorage('token', token.__raw);
        client.setHeader('Authorization', token.__raw);

        setLoadToken(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getIdTokenClaims]);

  if (loadToken) {
    return <PrimaryLoader color={'#942E83'} />;
  }

  function clearList() {
    if (cacheData.length > 0) {
      setShowAlert(true);
      //@ts-ignore
      const clear = window.confirm('Está seguro de limpiar la lista?');
      if (clear) {
        saveOnStorage('packagesList', []);
        saveOnStorage('manifestExceeds', []);
        saveOnStorage('errorsButton', false);
        setScanCode('');
      }
      setTimeout(() => {
        setShowAlert(false);
      }, 200);
    }
  }

  const deletePackageHandler = (id: number) => {
    setIsDelete((previousState) => !previousState);
    saveOnStorage(
      'packagesList',
      cacheData.filter((item: any) => item.internalId !== id),
    );
    const newErrors = errorsList.filter((item: any) => item.internalId !== id);

    saveOnStorage('manifestExceeds', newErrors);

    setTimeout(function () {
      setIsDelete((previousState) => !previousState);
    }, 100);
  };

  // Sub footer event handler methods

  const timingAnimation = (state: any, value: number, duration: number) => {
    return Animated.timing(state, {
      toValue: value,
      duration: duration,
      useNativeDriver: true,
    }).start();
  };

  const handleTimeOut = (functionality: void, ms: number) => {
    return setTimeout(function () {
      return functionality;
    }, ms);
  };

  const handleOptionsMenuAnimation = () => {
    if (!displayOptions) {
      handleTimeOut(
        setDisplayOptions((previousState) => !previousState),
        10,
      );
      timingAnimation(opacity, 1, 400);
      timingAnimation(position, 0, 100);
    } else {
      timingAnimation(opacity, 0, 250);
      timingAnimation(position, 102, 1000);
      setTimeout(function () {
        setDisplayOptions((previousState) => !previousState);
      }, 200);
    }
  };

  const handleNext = () => {
    const manifestMissing = manifestData.guides.filter((pack: any) => {
      return !cacheData.some((guide: any) => {
        return pack.internalId === guide.internalId;
      });
    });

    if (manifestMissing.length > 0 || manifestExceeds.length > 0) {
      if (!fastSearch || enabledInsert) {
        setNextButton(true);
        setDisplayErrorsModal((prevState) => !prevState);
        saveOnStorage('errorsButton', true);
      } else {
        history.push('/movement');
      }
    } else {
      if (manifestMissing.length === 0) {
        saveOnStorage('manifestMissing', []);
      }
      history.push('/movement');
    }
  };

  // Input event listener methods

  const handleSelectSubZone = (internalId: string) => {
    const getCancelIdBySubZone = (id: string | undefined) => {
      switch (id) {
        case '4':
          return ['4', 'CLIENTE AUSENTE', 'address_incomplete'];
        case '5':
          return ['5', 'REF', 'address_incorrect'];
        case '6':
          return ['6', 'PUNTERO MALO', 'bad_pointer'];
        default:
          return ['', '', ''];
      }
    };
    const [cancelId, cancelName, cancelReason] =
      getCancelIdBySubZone(internalId);
    setCancelStatus({id: cancelId, name: cancelName, reason: cancelReason});
  };

  const handleSaveSubZone = async () => {
    let errors;
    if (cancelStatus.id && cancelStatus.name) {
      errors = false;
      const currentPackagesList = getStorageItem('packagesList');
      currentPackagesList.map((item: any) => {
        if (item.internalId === currentPackage.internalId) {
          item.cancelationReasonName = cancelStatus.reason;
          item.cancelationReasonId = cancelStatus.id;
        }
        return item;
      });
      saveOnStorage('packagesList', currentPackagesList);
      setCurrentPackage({});
      setTimeout(() => {
        setManualSubZoneSelect(false);
        setCancelStatus({id: '', name: '', reason: ''});
      }, 1500);
    } else {
      errors = true;
    }
    return {errors};
  };

  const cancelIdByCancelName = (cancelId: string) => {
    switch (cancelId) {
      case 'ADDRESS_NOT_FOUND':
        return '1';
      case 'BAD_POINTER':
        return '2';
      case 'CLIENT_DID_NOT_RECEIVE':
        return '3';
      case 'INCOMPLETE_ADDRESS':
        return '4';
      case 'INCORRECT_ADDRESS':
        return '5';
      case 'OTHERS':
        return '6';
      case 'RESPONSIBLE_DID_NOT_RECEIVE':
        return '7';
      case 'RESPONSIBLE_NOT_FOUND':
        return '8';
      case 'THIEF':
        return '9';
      case 'ABANDONED_ROUTE':
        return '10';
      case 'THIEF_ATTEMPT':
        return '11';
      case 'OFFICE_HOUR':
        return '12';
      case 'OUT_OF_ROUTE':
        return '13';
      default:
        return '';
    }
  };

  const handleInterPackage = async (lastScannedCode: any) => {
    const {name, email} = user;
    const userProps = {name, email, version: WO_VERSION};
    setIsLoading(true);

    const existInList = cacheData.filter(
      (value: any) =>
        value.internalId === lastScannedCode ||
        value.referenceId === lastScannedCode,
    );

    let response;
    let exist = true;
    let cancelId;
    let cancelName;
    let idClient;
    let nameClient;
    let idZone;
    let nameZone;
    let coverageZone;
    let guide;
    let reference;
    let nameHub;

    if (!existInList.length || (fastSearch && !insertData)) {
      try {
        const pkgInfo = await fetchPackageInfo(lastScannedCode);
        if (pkgInfo?.guide_ivoy) {
          let {
            cancelationReasonId,
            cancelationReasonName,
            guide_ivoy: trackingNumber,
            guide: referenceId,
            id_zone: zoneId,
            zoneName,
            zoneHub,
            zoneCoverage,
            id_package_status: deliveryStatusId,
            id_client: clientName,
            routeDateCreated,
            routeId,
            routeStatusName,
            routeSequence,
            legacyRouteId,
            clientId,
            id_zone,
            hub,
            operation_area,
          } = pkgInfo;

          response = true;
          cancelId = cancelIdByCancelName(cancelationReasonName);
          cancelName = cancelationReasonName;
          nameClient = clientName;
          idClient = clientId;
          idZone = zoneId || id_zone;
          nameZone = zoneHub || hub;
          nameHub = zoneHub || hub;
          coverageZone = zoneCoverage || operation_area;
          guide = trackingNumber;
          reference = referenceId;
          routeSequence = routeSequence
            ?.split('/')
            ?.map((num: string) => parseInt(num) - 1)
            ?.join('/');
          const deliveryStatus: string = deliveryStatusId;

          const trackingContent =
            trackingNumber === referenceId
              ? trackingNumber
              : `${trackingNumber}\n${referenceId}\n`;

          const trackingTitle =
            trackingNumber === referenceId
              ? 'Guía iVoy'
              : 'Guía iVoy / Referencia';

          const route = legacyRouteId
            ? legacyRouteId.slice(legacyRouteId.length - 4)
            : '-';
          const hasRoute = !!route && route !== '-';
          const routeStatus = routeStatusName
            ? routeStatusName.toUpperCase()
            : '-';
          const routeS = routeSequence ? `(${routeSequence})` : '';

          const statusColor = '#50E3C2';
          const getSubZoneByCancelId = (id: string) => {
            switch (id) {
              case '1':
                return 'DIRECCION NO ENCONTRADA';
              case '2':
                return 'PUNTERO MALO';
              case '3':
                return 'CLIENTE NO RECIBIO';
              case '4':
                return 'DIRECCIÓN INCOMPLETA';
              case '5':
                return 'DIRECCIÓN INCORRECTA';
              case '6':
                return 'OTROS';
              case '7':
                return 'RESPONSABLE NO RECIBIO';
              case '8':
                return 'NO SE ENCUENTRA RESPONSABLE';
              case '9':
                return 'ROBO';
              case '10':
                return 'RUTA ABANDONADA';
              case '11':
                return 'INTENTO DE ROBO';
              case '12':
                return 'HORA DE OFICINA';
              case '13':
                return 'FUERA DE RUTA';
              default:
                return '';
            }
          };
          const packInfo = {
            id: trackingNumber,
            trackingNumber: trackingContent,
            trackingTitle: trackingTitle,
            zoneName: nameZone,
            zoneHub: nameHub,
            deliveryStatusId,
            zoneCoverage: coverageZone,
            order: `${route} ${routeS}`,
            info: [
              {
                title: 'Estado de la orden',
                value: routeStatus,
                color: statusColor,
              },
              {
                title: 'Estado del paquete',
                value: packageStatus(deliveryStatus)?.toUpperCase(),
                color: statusColor,
              },
              {
                title: 'Cliente',
                value: clientName,
                color: statusColor,
              },
              {
                title: 'Fecha de la Orden',
                value: formatDate(routeDateCreated),
                color: statusColor,
              },
              {
                title: 'Cancelación',
                value: getSubZoneByCancelId(cancelId) || '-',
                color: 'red',
              },
            ],
          };

          setPackageInfo(packInfo);
          setIsLoading(false);
          handleAnalytics(
            'success_scanning',
            {
              trackingNumber,
              referenceId,
              zoneHub,
              clientName,
            },
            userProps,
          );
          if (print && hasRoute) {
            handleAnalytics(
              'send_print',
              {trackingNumber, referenceId},
              userProps,
            );
            handleSendPrint(packInfo);
          }
        } else {
          exist = false;
          guide = lastScannedCode;
          reference = lastScannedCode;
          nameZone = null;
          nameHub = null;
          idZone = null;
          nameClient = null;
          idClient = null;
          response = false;
          setPackageInfo(initialPackage);
          handleAnalytics(
            'no_info_scanning',
            {internalId: guide, externalId: reference},
            userProps,
          );
          scanningSoundError.play();
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e);
        exist = false;
        guide = lastScannedCode;
        reference = lastScannedCode;
        nameHub = null;
        nameZone = null;
        idZone = null;
        nameClient = null;
        idClient = null;
        response = false;
        setPackageInfo(initialPackage);
        handleAnalytics(
          'error_scanning',
          {internalId: guide, externalId: reference},
          userProps,
        );
        scanningSoundError.play();
        setIsLoading(false);
      }
      if (enabledInsert || !fastSearch || keepings) {
        scanningSound.play();
        const newPackage: any = {
          id: lastScannedCode,
          exist,
          status: exist ? 'sync' : 'no-sync',
          internalId: guide ? guide : '', //Ver que guide siempre tenga valor.
          referenceId: reference,
          zoneId: idZone,
          zoneName: nameZone,
          zoneHub: nameHub,
          zoneCoverage: coverageZone,
          clientName: nameClient,
          clientId: idClient,
        };
        if (keepings) {
          setManualSubZoneSelect(true);
          setCurrentPackage(newPackage);
          if (cancelId && cancelName) {
            newPackage.cancelationReasonId = cancelId;
            newPackage.cancelationReasonName = cancelName;
            handleSelectSubZone(cancelId);
          }
        }
        if (manifestData.count) {
          compareGuides(guide, reference, exist);
        }

        const cacheData = JSON.parse(
          //@ts-ignore
          // eslint-disable-next-line no-undef
          localStorage.getItem('packagesList') || '',
        )?.rawData;

        saveOnStorage('packagesList', [...cacheData, newPackage]);
      }
    } else {
      const {internalId, referenceId, zoneName} = existInList[0];
      response = false;
      handleAnalytics(
        'duplicated_scanning',
        {
          internalId: internalId,
          externalId: referenceId,
          nameZone: zoneName,
        },
        userProps,
      );
      scanningSoundError.play();
      setIsLoading(false);
    }
    return {
      response,
      nameZone,
      nameHub,
    };
  };

  const handleZoneCitiesSounds = (
    response: boolean,
    nameZone: string,
    nameHub?: string,
  ) => {
    if (response && nameZone !== null) {
      let cityFiltered;
      if (nameZone === 'CDMX' && !!nameHub) {
        cityFiltered = zoneHubs.filter((item: any) => {
          return item.name.toUpperCase() === nameHub?.toUpperCase();
        });
      } else {
        cityFiltered = zoneCities.filter((item: any) => {
          return item.name === nameZone;
        });
      }

      const city =
        cityFiltered.length > 0
          ? cityFiltered[0]
          : zoneCities[zoneCities.length - 1];
      city.sound?.play();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.nativeEvent.key === 'Enter' && scanCode !== '') {
      const splitScannedCode = event.target.textContent.split('\n');
      const actualScannedCode = splitScannedCode.pop();
      if (actualScannedCode != null && actualScannedCode !== '') {
        handleInterPackage(actualScannedCode).then((r) => {
          const {response, nameZone, nameHub} = r;
          handleZoneCitiesSounds(response, nameZone, nameHub);
        });
      }
    }
  };

  const handleScanCamera = (data: any) => {
    if (data !== '' && data !== null && data !== undefined) {
      handleInterPackage(data).then((r) => {
        const {response, nameZone} = r;
        handleZoneCitiesSounds(response, nameZone);
      });
    }
  };

  const handleScanCameraError = (err: any) => {
    console.error('qr scan error', err);
  };

  // Side Menu Methods

  const handleSideMenu = () => {
    setSideMenu(!sideMenu);
  };

  const packagesItems =
    packagesList.length > 20 ? packagesList.slice(-20) : packagesList;

  const lastGuide =
    packagesList.length > 0
      ? packagesList[packagesList.length - 1]
      : {internalId: 'Por favor comienze a escanear'};

  // Manifest Methods

  const clearComments = () => {
    const movementObj = getStorageItem('movementObj');
    const newTransactions = [...movementObj.transactions];
    newTransactions[0].comments = '';
    newTransactions[1].comments = '';
    const newMovementObj = {
      ...movementObj,
      comments: '',
      transactions: [...newTransactions],
    };
    return newMovementObj;
  };

  const handleSearch = async (manifestId: string) => {
    let hasErrors: boolean;
    let messageResponse: string;
    const oldManifestData = getStorageItem('manifestData');
    const isManifestDuplicated = oldManifestData.guides.some(
      (item: any) => item.manifestId === manifestId,
    );
    if (manifestId !== '' && !isManifestDuplicated) {
      const manifestInfo = await getManifestByIdInfo(manifestId);
      if (manifestInfo?.manifest !== null) {
        const newPackages = manifestInfo.manifest.packages.guides.map(
          (item: any) => ({
            manifestId: manifestId,
            ...item,
          }),
        );

        const newManifestData: IManifestsData = {
          ids: [...oldManifestData.ids, manifestId],
          count: oldManifestData.guides.length + newPackages.length,
          guides: [...oldManifestData.guides, ...newPackages],
        };
        setManifestInput(newManifestData);
        saveOnStorage('manifestData', newManifestData);

        const manifestDataIds = newManifestData.guides?.map(
          (guide: any) => guide.internalId,
        );
        const exceedsArray = packagesList.filter(
          (guide: any) => !manifestDataIds.includes(guide.internalId),
        );

        const newMovementObj = clearComments();

        saveOnStorage('movementObj', newMovementObj);
        saveOnStorage('manifestExceeds', exceedsArray);
        saveOnStorage('manifestMissing', []);
        saveOnStorage('errorsButton', false);
        hasErrors = false;
        messageResponse = 'Manifiesto cargado.';
      } else {
        hasErrors = true;
        messageResponse =
          'Manifiesto no encontrado. Por favor vuelva a escanear.';
      }
    } else {
      hasErrors = true;
      messageResponse = 'Manifiesto previamente escaneado.';
    }
    return {
      hasErrors,
      messageResponse,
    };
  };

  const handlePressOutside = () => {
    setDisplaySearchModal(false);
  };

  const handleClearManifest = () => {
    const newMovementObj = clearComments();
    const clearManifest = {
      ids: [],
      count: 0,
      guides: [],
    };
    saveOnStorage('manifestData', clearManifest);
    saveOnStorage('manifestExceeds', []);
    saveOnStorage('manifestMissing', []);
    saveOnStorage('movementObj', newMovementObj);
    saveOnStorage('errorsButton', false);
    setManifestInput(clearManifest);
  };

  const handleClearOne = (id: string) => {
    const filteredIds = manifestInput.ids.filter((item: string) => item !== id);
    const filteredGuides = manifestInput.guides.filter(
      (item: any) => item.manifestId !== id,
    );

    const newManifestData = {
      ids: filteredIds,
      count: filteredGuides.length || 0,
      guides: filteredGuides,
    };

    saveOnStorage('manifestData', newManifestData);
    setManifestInput(newManifestData);
  };

  const handleCheckedPackages = () => {
    const listSet = new Set(packagesList);
    const errorsArray = errorsList.filter((x: any) => {
      return !listSet.has(x);
    });
    setCheckedErrors(errorsArray);
    setNextButton(false);
    setDisplayErrorsModal(true);
  };

  const handlePressNext = () => {
    history.push('/movement');
  };

  return (
    <>
      {manualSubZoneSelect && (
        <ModalKeepingsSelection
          currentCancelStatus={currentPackage.cancelationReasonId}
          cancelId={cancelStatus.id}
          onChange={(value: string) => handleSelectSubZone(value)}
          handleSave={() => handleSaveSubZone()}
        />
      )}
      {displayErrorsModal && (
        <ManifestErrorsModal
          onPressOutside={() => setDisplayErrorsModal(false)}
          onPressNext={handlePressNext}
          showNextButton={nextButton}
          buttonTitle={'Seguir escaneando'}
        />
      )}
      {displaySearchModal && (
        <SearchModal
          onPressOutside={handlePressOutside}
          handleSearch={handleSearch}
          handleClearAll={handleClearManifest}
          handleClearOne={handleClearOne}
        />
      )}
      {scanMode ? (
        <ScanCamera
          handleScan={handleScanCamera}
          handleError={handleScanCameraError}
          zoneCities={zoneCities}
          fastSearch={fastSearch}
          packageInfo={fastSearch ? packageInfo : lastGuide}
          handleBackButton={() => setScanMode((prevState) => !prevState)}
        />
      ) : (
        <ContainerScreen
          header={
            <>
              <Header
                title="Escaneo"
                subtitle="Escanea el código del paquete"
                complement={
                  <PrimaryIconLink
                    onPress={handleSideMenu}
                    style={styles.menuIconStyle}
                    iconName={'menu'}
                    iconSize={28}
                  />
                }>
                <InputSearch
                  iconName={fastSearch ? 'barcode-sharp' : 'ios-flash'}
                  onPressButtonScan={() => setScanMode(!scanMode)}
                  onChange={(value: any) => setScanCode(value)}
                  onKeyPress={handleKeyDown}
                  value={scanCode}
                  onPressButtonSearch={() => setFastSearch(!fastSearch)}
                />
              </Header>
              {(!fastSearch || enabledInsert) && (
                <>
                  <View style={styles.searchByIdsMenuContainer}>
                    <PrimaryIconLink
                      iconName="newspaper-outline"
                      iconSize={20}
                      iconStyle={styles.searchByIdsIcon}
                      style={[
                        styles.searchByIdsButton,
                        styles.searchByIdManifestButton,
                      ]}
                      onPress={() => setDisplaySearchModal(true)}
                    />
                  </View>
                  {!!manifestInput?.guides.length && (
                    <ManifestInformation
                      onPress={() => handleCheckedPackages()}
                      manifestData={manifestInput}
                    />
                  )}
                </>
              )}
            </>
          }
          footer={
            <>
              {!fastSearch && <CountersComponent counters={counters} />}
              {displayOptions && (
                <SubFooter
                  position={position}
                  animation={opacity}
                  switchOptions={subFooterOptions}
                  children={
                    fastSearch && (
                      <PrintersSubFooterOptions
                        printer={printer}
                        setPrinter={(value: string) => setPrinter(value)}
                        zonePrint={zonePrint}
                        setZonePrint={(value: string) => setZonePrint(value)}
                        printMode={printMode}
                        setPrintMode={(value) => setPrintMode(value)}
                      />
                    )
                  }
                />
              )}
              <Footer
                options={
                  fastSearch && !(insertData || keepings)
                    ? [footerOptions[1]]
                    : footerOptions
                }
                style={
                  fastSearch && !(insertData || keepings)
                    ? styles.footerStyle
                    : {}
                }
              />
            </>
          }>
          {fastSearch ? (
            <View>
              {displayToast && (
                <View style={styles.errorBoxStyle}>
                  <PrimaryText
                    color="#f00"
                    content="Device is not connected."
                    fontSize={14}
                    fontWeight="bold"
                  />
                </View>
              )}
              <PackageInfo
                manualPrint={handleSendPrint}
                isLoading={isLoading}
                packageInfo={packageInfo}
                zoneCities={zoneCities}
                zoneHubs={zoneHubs}
              />
            </View>
          ) : (
            <>
              <PackagesList
                packages={packagesItems}
                onPress={(id: number) => deletePackageHandler(id)}
                isDelete={isDelete}
              />
            </>
          )}
          {showAlert && <></>}
        </ContainerScreen>
      )}
      {sideMenu && (
        <SideMenu
          sideMenuOptions={sideMenuOptions}
          onPress={() => console.log('pressed')}
          onPressOutside={() => handleSideMenu()}
        />
      )}
    </>
  );
};
