import React from 'react';
import {View} from 'react-native';

import {ScannerCamera, PrimaryButton} from '../../atoms';
import {makeStyles} from './index.styles';

interface ScannerCameraProps {
  handleScan: (value: any) => void;
  handleError: (value: any) => void;
  handleBackButton: () => void;
  children: React.ReactNode;
}

export const ScanCameraContent = ({
  handleError,
  handleScan,
  handleBackButton,
  children,
}: ScannerCameraProps) => {
  const scannerCameraStyle = {width: 315, height: 315};
  const styles = makeStyles();

  return (
    <View style={styles.container}>
      <ScannerCamera
        style={scannerCameraStyle}
        handleScan={handleScan}
        handleError={handleError}
      />
      <View style={styles.contentContainer}>
        <View style={styles.packageInformation}>{children}</View>
      </View>
      <View style={styles.backButton}>
        <PrimaryButton title={'Regresar'} onPress={handleBackButton} />
      </View>
    </View>
  );
};
