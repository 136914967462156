import React from 'react';
import {View} from 'react-native';

import {
  KeepingRow,
  ItemProps,
  StyleProps,
  PrimaryText,
  PrimaryIconLink,
} from '../../atoms';
import {makeStyles} from './index.styles';
import {getStorageItem} from '../../../common/utils';

interface KeepingsInfoProps {
  style?: any;
  activeButton?: boolean;
  onPress?: (item: any) => void;
}

export const KeepingsInformation = ({
  style,
  activeButton,
  onPress,
}: KeepingsInfoProps) => {
  const packagesList = getStorageItem('packagesList');
  const styles = makeStyles();

  const header: ItemProps = {
    id: 1,
    internalId: 'Guía iVoy',
    referenceId: 'Referencia',
  };

  const headerStyle: StyleProps = {
    itemColor: '#50622c',
    textWeight: '700',
    textStyle: styles.textStyle,
  };

  const itemStyle: StyleProps = {
    itemColor: '#e0e3d9',
    textWeight: 'light',
    textStyle: styles.textStyle,
  };

  const getSubZoneByCancelId = (id: string) => {
    switch (id) {
      case '4':
        return 'CLIENTE AUSENTE';
      case '5':
        return 'REF';
      case '6':
        return 'PUNTERO MALO';
      default:
        return '';
    }
  };

  return (
    <View style={[styles.container, style]}>
      <KeepingRow
        item={header}
        style={headerStyle}
        children={
          <View style={styles.itemRowContainer}>
            <PrimaryText
              style={styles.textStyle}
              fontWeight={'700'}
              content={'Sub zona'}
            />
            {activeButton && (
              <PrimaryIconLink
                onPress={() => onPress && onPress({name: 'all'})}
                style={styles.dropdownButton}
                iconName={'chevron-down'}
                iconSize={18}
                iconStyle={styles.iconColor}
              />
            )}
          </View>
        }
      />
      {packagesList.map((item: any, index: number) => {
        return (
          <KeepingRow
            key={index}
            item={item}
            style={itemStyle}
            children={
              <View style={styles.itemRowContainer}>
                <PrimaryText
                  content={
                    item.cancelationReasonId
                      ? getSubZoneByCancelId(item.cancelationReasonId)
                      : '-'
                  }
                />
                {activeButton && (
                  <PrimaryIconLink
                    onPress={() => onPress && onPress(item)}
                    style={styles.dropdownButton}
                    iconName={'chevron-down'}
                    iconSize={18}
                    iconStyle={styles.iconColor}
                  />
                )}
              </View>
            }
          />
        );
      })}
    </View>
  );
};
