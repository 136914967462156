import React from 'react';
import {makeStyles} from './index.styles';
import {View, TouchableOpacity} from 'react-native';
import {PrimaryText} from '../../';
import Icon from 'react-native-vector-icons/Ionicons';
import {useHistory} from '../../../navigation/react-router';

interface HeaderProps {
  title: string;
  subtitle: string;
  goBack: boolean;
  style?: any;
  children?: React.ReactNode;
  complement?: React.ReactNode;
}

export const Header = ({
  children,
  title,
  subtitle,
  goBack,
  style,
  complement,
}: HeaderProps) => {
  const styles = makeStyles(style);
  let history = useHistory();

  return (
    <View style={[styles.container, style]}>
      <View style={styles.containerTitle}>
        {goBack && (
          <TouchableOpacity onPress={() => history.goBack()}>
            <Icon name="chevron-back-outline" style={styles.iconArrow} />
          </TouchableOpacity>
        )}
        <PrimaryText fontSize={24} content={title} fontWeight={900} />
        {complement}
      </View>
      <View>
        {subtitle !== '' && (
          <>
            <PrimaryText
              color="#121212"
              fontSize={12}
              content={subtitle}
              fontWeight={500}
            />
            <View style={styles.children} />
          </>
        )}
        {children}
      </View>
    </View>
  );
};

Header.defaultProps = {
  title: '',
  subtitle: '',
  goBack: true,
  style: {
    container: {
      height: 'auto',
    },
  },
};
