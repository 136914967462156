import React from 'react';
import {Text} from 'react-native';
import {makeStyles} from './index.styles';

interface PrimaryTextProps {
  content: string;
  color?: any;
  fontSize?: number;
  fontWeight?: any;
  fontStyle?: any;
  margin?: any;
  style?: any;
}

export const PrimaryText = ({
  color,
  fontSize,
  fontStyle,
  fontWeight,
  content,
  margin,
  style,
}: PrimaryTextProps) => {
  const styles = makeStyles({color, fontSize, fontStyle, fontWeight, margin});
  return <Text style={[styles.content, style]}>{content}</Text>;
};

PrimaryText.defaultProps = {
  content: 'Hello. I am a text',
  color: '#000',
  fontSize: 14,
  fontWeight: '100',
  fontStyle: 'normal',
  margin: 0,
};
