import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  itemColor: ColorValue;
}

export const makeStyles = ({itemColor}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      maxWidth: 400,
      minWidth: 250,
      height: 'fit-content',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      marginBottom: 1,
    },
    row: {
      width: '55%',
      height: 50,
      flexWrap: 'wrap',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 1,
      paddingHorizontal: 2,
      paddingVertical: 4,
      borderRadius: 4,
      backgroundColor: itemColor,
    },
  });
};
