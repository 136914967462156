import React from 'react';
import {Login} from '../../components';
import {View} from 'react-native';

export const LoginPage = () => {
  return (
    <View>
      <Login />
    </View>
  );
};
