import React from 'react';
import {View} from 'react-native';
import {useStorageItem} from '../../../common/utils';

import {
  PrimaryText,
  Input,
  ScannerCamera,
  PrimaryLoader,
  KeepingsInformation,
} from '../../';
import {ManifestInformation} from '../../';
import {CamPermissionsContainer} from '../CameraContainer';
import {makeStyles} from './index.styles';
import {storage} from '../../../storage';

interface ConfirmContentProps {
  width?: any;
  pickerOptions?: OptionContentProps[];
  evidence?: any;
  error: any;
  inputValue: string;
  onChangeInputValue: (e: string) => void;
  selectedPicker: string;
  setSelectedPicker: (e: string) => void;
  handlePickerStyle: (picker: string, icon: string) => React.ReactNode;
  handleScanOutput: () => React.ReactNode;
  handleScan: (data: any) => void;
  handleError: (err: any) => void;
  permissions: any;
  isLoading: string;
  style: any;
  showModalErrors: () => void;
}

interface OptionContentProps {
  name: string;
  icon: string;
}

export const ConfirmContent = ({
  width,
  pickerOptions,
  evidence,
  error,
  inputValue,
  onChangeInputValue,
  selectedPicker,
  setSelectedPicker,
  handlePickerStyle,
  handleScanOutput,
  handleScan,
  handleError,
  permissions,
  isLoading,
  style,
  showModalErrors,
}: ConfirmContentProps) => {
  // @ts-ignore
  const keepings = storage.cache.keepings.rawData;
  const styles = makeStyles({width});
  const whId = useStorageItem('whId');
  const manifestInput = useStorageItem('manifestData');

  if (isLoading === 'loading') {
    return (
      <PrimaryLoader
        title={'Enviando...'}
        style={styles.loaderStyle}
        color={'#4fa8fb'}
      />
    );
  }

  return (
    <View style={styles.container}>
      {keepings && <KeepingsInformation />}
      {!!manifestInput?.id && (
        <ManifestInformation
          onPress={() => showModalErrors()}
          manifestData={manifestInput}
        />
      )}
      <View style={styles.comentaries}>
        {whId !== 'Q3xN1P4jEA7kGqE3kw_C5CAL' && (
          <>
            <PrimaryText
              content={'Código de validación'}
              fontSize={14}
              fontWeight={'700'}
            />
            {handleScanOutput()}
            <View style={styles.qrReaderContainer}>
              <CamPermissionsContainer
                permissions={permissions}
                children={
                  <ScannerCamera
                    handleScan={handleScan}
                    handleError={handleError}
                    style={style}
                  />
                }
              />
            </View>
          </>
        )}
        <div>
          <PrimaryText
            content={'Agrega tus comentarios anexos al movimiento'}
            fontSize={14}
            fontWeight={'700'}
          />
          <Input
            type={'textarea'}
            placeholder={'Comience a escribir'}
            style={styles.input}
            value={inputValue}
            onChange={onChangeInputValue}
            numberOfLines={6}
          />
        </div>
      </View>
      {/* TO DO: Wait for evidences redesign and implementation
            <View style={styles.evidences}>
        <PrimaryText
          content={'Adjunta tus evidencias'}
          fontSize={14}
          fontWeight={'700'}
        />
        <View style={styles.picker}>
          {pickerOptions &&
            pickerOptions.map((option, index) => (
              <View style={styles.pickerOption} key={index}>
                {handlePickerStyle(option.name, option.icon)}
              </View>
            ))}
        </View>
        <View style={styles.evidenceOutputContainer}>
          {!evidence && selectedPicker === '' && (
            <View style={styles.evidenceOutput}>
              <PrimaryText
                content={'No se han agregado evidencias'}
                color={'#000'}
              />
            </View>
          )}
          {selectedPicker !== '' && (
            <>
              <View style={styles.evidenceOutput}>
                <PrimaryText
                  content={`${selectedPicker} de evidencia agregada`}
                  color={'#000'}
                  fontSize={14}
                />
              </View>
              <TouchableHighlight onPress={() => setSelectedPicker('')}>
                <View style={styles.close}>
                  <PrimaryText content={'X'} fontSize={16} fontWeight={'700'} />
                </View>
              </TouchableHighlight>
            </>
          )}
        </View>
      </View>
      */}
    </View>
  );
};

ConfirmContent.defaultProps = {
  width: '100%',
  pickerOptions: [
    {name: 'Firma', icon: 'md-pencil-outline'},
    {name: 'Huella digital', icon: 'md-finger-print-sharp'},
    {name: 'Foto', icon: 'md-image-outline'},
  ],
};
