import {
  BrowserRouter,
  Route as WebRoute,
  Link as WebLink,
  useHistory as WebHistory,
} from 'react-router-dom';

export let Router = BrowserRouter;
export let Route = WebRoute;
export let Link = WebLink;
export let useHistory = WebHistory;
