import React, {useState} from 'react';
import {View, Image} from 'react-native';

import {
  DropdownList,
  PrimaryText,
  PrimaryIconLink,
  PrimarySectionsHeader,
  Input,
  PrimaryCamera,
  warehousesProps,
} from '../../atoms';
import {CamPermissionsContainer} from '../CameraContainer';
import {avatar} from '../../../common/assets';
import {makeStyles} from './index.styles';

interface PersonnelRecordProps {
  onPressSearch: () => void;
  buttonInfo: any;
  iconHeaderInfo: any;
  textHeaderInfo: any;
  indicatorInfo?: any;
  data?: dataProps;
  isPartner: string;
  zoneName: string;
  actionName: string;
  zoneNames: warehousesProps[];
  actionNames: warehousesProps[];
  onChangeZone: (value: any) => void;
  onChangeAction: (value: any) => void;
  type: any;
  setCameraRef: (value: any) => void;
  onPressPhoto: () => void;
  permissions: any;
  photo: any;
  readedText: string;
  setReadedText?: (value: string) => void;
  autoFocus?: any;
  courierData: CourierProps[];
}

interface dataProps {
  name: string;
  idCarNumber: string;
  firstActivity: string;
  lastActivity: string;
}

interface CourierProps {
  columnOne: string;
  columnTwo?: string;
}

export const PersonnelRecordContent = ({
  onPressSearch,
  buttonInfo,
  iconHeaderInfo,
  textHeaderInfo,
  indicatorInfo,
  data,
  isPartner,
  zoneName,
  actionName,
  zoneNames,
  actionNames,
  onChangeZone,
  onChangeAction,
  type,
  setCameraRef,
  onPressPhoto,
  permissions,
  photo,
  readedText,
  setReadedText,
  autoFocus,
  courierData,
}: PersonnelRecordProps) => {
  const styles = makeStyles();

  const {buttonName, buttonSize, buttonStyle, buttonIconStyle} = buttonInfo;
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <PrimarySectionsHeader
          iconInfo={iconHeaderInfo}
          textInfo={textHeaderInfo}
          indicatorInfo={indicatorInfo}
        />
        <PrimaryText
          content={'Reconocimiento de matrícula'}
          fontSize={14}
          fontWeight={700}
          margin={25}
        />
        <PrimaryText
          content={'Cargue la imagen correspondiente a continuación'}
          fontSize={12}
          fontWeight={300}
          margin={10}
        />
      </View>
      <View style={styles.ocrContainer}>
        <View style={styles.cameraContainer}>
          <CamPermissionsContainer
            permissions={permissions}
            children={
              <PrimaryCamera
                setCameraRef={setCameraRef}
                onPressPhoto={onPressPhoto}
                type={type}
                autoFocus={autoFocus}
                readedText={readedText}
                setReadedText={setReadedText}
              />
            }
          />
        </View>
      </View>
      <View style={styles.searchContainer}>
        <PrimaryIconLink
          onPress={onPressSearch}
          style={buttonStyle}
          iconName={buttonName}
          iconSize={buttonSize}
          iconStyle={buttonIconStyle}
        />
      </View>
      {isPartner === 'Mensajero' && (
        <>
          <View style={styles.outputInformation}>
            <PrimaryText
              content={'Datos obtenidos'}
              fontSize={14}
              fontWeight={700}
              margin={7}
            />
            <View style={styles.outputInner}>
              <View style={styles.imageContainer}>
                <Image source={{uri: avatar}} style={styles.profileImage} />
              </View>
              <View style={styles.innerInfo}>
                {courierData.map((item, index) => (
                  <View style={styles.outputRow} key={index}>
                    <PrimaryText
                      content={item.columnOne}
                      fontSize={12}
                      fontWeight={700}
                    />
                    <PrimaryText
                      content={item.columnTwo}
                      fontSize={12}
                      fontWeight={500}
                      fontStyle={'italic'}
                    />
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View style={styles.registryContainer}>
            <PrimaryText
              content={'Registro de visita'}
              fontSize={14}
              fontWeight={700}
              margin={7}
            />
            <View style={styles.selectionContainer}>
              <View style={styles.dropsContainer}>
                <DropdownList
                  selectedValue={zoneName}
                  onChange={onChangeZone}
                  backgroundColor={'#000'}
                  options={zoneNames}
                />
              </View>
              <View style={styles.dropsContainer}>
                <DropdownList
                  selectedValue={actionName}
                  onChange={onChangeAction}
                  backgroundColor={'#000'}
                  options={actionNames}
                />
              </View>
            </View>
          </View>
        </>
      )}
      {/*isPartner === 'Sin data' && (
        <>
          <View style={styles.outputInformation}>
            <PrimaryText
              content={'Sin datos obtenidos'}
              fontSize={14}
              fontWeight={700}
              margin={7}
            />
          </View>
          <View style={styles.registryContainer}>
            <PrimaryText
              content={'Registrar visitante'}
              fontSize={14}
              fontWeight={700}
              margin={7}
            />
            <View style={styles.personnelRegistry}>
              <View style={styles.outputRow}>
                <PrimaryText
                  content={'Matrícula: '}
                  fontSize={12}
                  fontWeight={700}
                  margin={7}
                />
                <PrimaryText
                  content={readedText}
                  fontSize={12}
                  fontWeight={500}
                  margin={7}
                  fontStyle={'italic'}
                />
              </View>
              <View style={styles.outputRow}>
                <PrimaryText
                  content={'Nombre: '}
                  fontSize={12}
                  fontWeight={700}
                  margin={7}
                />
                <Input
                  placeholder={'Registre el nombre del visitante'}
                  style={styles.inputStyle}
                />
              </View>
            </View>
            <PrimaryText
              content={'Registrar ubicación'}
              fontSize={14}
              fontWeight={700}
              margin={7}
            />
            <View style={styles.selectionContainer}>
              <View style={styles.dropsContainer}>
                <DropdownList
                  selectedValue={zoneName}
                  onChange={onChangeZone}
                  backgroundColor={'#000'}
                  options={zoneNames}
                />
              </View>
              <View style={styles.dropsContainer}>
                <DropdownList
                  selectedValue={actionName}
                  onChange={onChangeAction}
                  backgroundColor={'#000'}
                  options={actionNames}
                />
              </View>
            </View>
          </View>
        </>
      )*/}
    </View>
  );
};
