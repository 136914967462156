import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
    },
    titleContainer: {
      width: '100%',
      flexDirection: 'row',
    },
  });
};
