import {whPickupId} from '../constants';

export const initialPackage = {
  trackingNumber: '-',
  trackingTitle: 'Guía iVoy / Referencia',
  zoneName: '-',
  order: '-',
  info: [
    {
      title: 'Estado de la orden',
      value: '-',
      color: 'red',
    },
    {
      title: 'Estado del paquete',
      value: '-',
      color: 'red',
    },
    {
      title: 'Cliente',
      value: '-',
      color: 'red',
    },
    {
      title: 'Hora de Creación',
      value: '-',
      color: 'red',
    },
    {
      title: 'Cancelación',
      value: '-',
      color: 'red',
    },
  ],
};

// Screens/ConsolidatedSearch

export const sideMenuOptions = [
  {name: 'Home', route: '/home', icon: 'ios-home'},
  {
    name: 'Categorías iVoy',
    route: '/search-categories',
    icon: 'apps-outline',
  },
];

export const zoneCitiesCreator = (
  whId: string,
  HMX1Sound: any,
  HMX2Sound: any,
  GDLSound: any,
  MTYSound: any,
  TOLSound: any,
  QROSound: any,
  PLASound: any,
  AR1Sound: any,
  ARPSound: any,
  ISSound: any,
  ISHSound: any,
  TENSound: any,
  TEPSound: any,
  ITRUSound: any,
  IZTSound: any,
  RDASound: any,
  dabbaSound: any,
  grrSound: any,
  raamSound: any,
  noInfoSound: any,
) => {
  if (whId === whPickupId) {
    return [
      {
        id: '0',
        name: '-',
        color: '#942E83',
        sound: undefined,
      },
    ];
  }
  return [
    {
      id: '0',
      name: '-',
      color: '#942E83',
      sound: undefined,
    },
    {
      id: '1',
      name: 'HMX1',
      color: '#7371f6',
      sound: HMX1Sound,
    },
    {
      id: '2',
      name: 'GDL',
      color: '#f86f6f',
      sound: GDLSound,
    },
    {
      id: '3',
      name: 'MTY',
      color: '#4fa8fb',
      sound: MTYSound,
    },
    {
      id: '4',
      name: 'TOL',
      color: '#e00000',
      sound: TOLSound,
    },
    {
      id: '5',
      name: 'QRO',
      color: '#b3b300',
      sound: QROSound,
    },
    {
      id: '6',
      name: 'PLA1',
      color: '#32a852',
      sound: PLASound,
    },
    {
      id: '7',
      name: 'AR1',
      color: '#056e02',
      sound: AR1Sound,
    },
    {
      id: '8',
      name: 'IZT',
      color: '#28634b',
      sound: IZTSound,
    },
    {
      id: '9',
      name: 'PLA',
      color: '#32a852',
      sound: PLASound,
    },
    {
      id: '10',
      name: 'ARP',
      color: '#5c5c5a',
      sound: ARPSound,
    },
    {
      id: '11',
      name: 'ISH',
      color: '#7a3573',
      sound: ISHSound,
    },
    {
      id: '12',
      name: 'TEN',
      color: '#6b3434',
      sound: TENSound,
    },
    {
      id: '13',
      name: 'TEP',
      color: '#35407a',
      sound: TEPSound,
    },
    {
      id: '14',
      name: 'IS',
      color: '#35337a',
      sound: ISSound,
    },
    {
      id: '15',
      name: 'HMX2',
      color: '#35407a',
      sound: HMX2Sound,
    },
    {
      id: '16',
      name: 'ITRU',
      color: '#ff5e61',
      sound: ITRUSound,
    },
    {
      id: '17',
      name: 'DABBA',
      color: '#636300',
      sound: dabbaSound,
    },
    {
      id: '18',
      name: 'GRR',
      color: '#a87979',
      sound: grrSound,
    },
    {
      id: '19',
      name: 'RAAM',
      color: '#6e1045',
      sound: raamSound,
    },
    // el elemento noInfo debe quedar siempre al final
    {
      id: '20',
      name: 'RDA',
      color: '#ff702f',
      sound: RDASound,
    },
    {
      id: '21',
      name: '',
      color: '#ff35c2',
      sound: noInfoSound,
    },
  ];
};

// Components / InputSearch

export const NOT_ALLOWED_MESSAGE = '';

export const zoneHubsCreator = (hmx1Sound: any, hmx2Sound: any) => {
  return [
    {
      id: '1',
      name: 'HMX1',
      color: '#7371f6',
      sound: hmx1Sound,
    },
    {
      id: '2',
      name: 'HMX2',
      color: '#078c03',
      sound: hmx2Sound,
    },
  ];
};
