import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  color?: ColorValue;
  fontWeight?: any;
  fontSize?: number;
  fontStyle?: any;
  margin?: any;
}

export const makeStyles = ({
  color,
  fontSize,
  fontStyle,
  fontWeight,
  margin,
}: StyleProps): any => {
  return StyleSheet.create({
    content: {
      color: color ?? '#000',
      fontWeight: fontWeight ?? 'normal',
      fontStyle: fontStyle ?? 'normal',
      fontSize: fontSize ?? 14,
      marginTop: margin ?? 0,
    },
  });
};
