import React from 'react';
import {View} from 'react-native';

import {
  ContainerScreen,
  ScanCameraContent,
  CamHeader,
  PrimaryText,
  PrimaryOutputStatus,
} from '../../components';
import {makeStyles} from './index.styles';

interface ScanCamProps {
  handleBackButton: () => void;
  fastSearch: boolean;
  packageInfo: any;
  handleScan: (data: any) => void;
  handleError: (err: any) => void;
  zoneCities: any;
}

export const ScanCamera = ({
  handleBackButton,
  handleScan,
  handleError,
  fastSearch,
  packageInfo,
  zoneCities,
}: ScanCamProps) => {
  let cityFiltered;
  let city;

  if (fastSearch) {
    cityFiltered = zoneCities.filter((item: any) => {
      return item.name === packageInfo.zoneName;
    });
    city =
      cityFiltered.length > 0
        ? cityFiltered[0]
        : zoneCities[zoneCities.length - 1];
  }

  const styles = makeStyles({backgroundColor: city?.color});

  return (
    <View style={styles.containerScreen}>
      <ContainerScreen
        header={
          <CamHeader
            fontStyle={styles.header}
            title={fastSearch ? 'Búsqueda rápida' : 'Movimiento'}
          />
        }
        footer={<View />}>
        <ScanCameraContent
          handleScan={handleScan}
          handleError={handleError}
          handleBackButton={handleBackButton}
          children={
            fastSearch ? (
              <View style={styles.packageInfo}>
                <PrimaryText
                  content={packageInfo.trackingTitle}
                  style={styles.contentTextHeader}
                />
                <PrimaryText
                  content={packageInfo.trackingNumber}
                  style={[styles.contentText, styles.colorTrackingNumber]}
                />
                <PrimaryText
                  content={'Orden'}
                  style={styles.contentTextHeader}
                />
                <PrimaryText
                  content={packageInfo.order}
                  style={[styles.contentText, styles.colorOrder]}
                />
                <PrimaryText
                  content={'Destino'}
                  style={styles.contentTextHeader}
                />
                <PrimaryText
                  content={packageInfo.zoneName}
                  style={[styles.contentText, styles.colorZoneName]}
                />
                {packageInfo.info.map((item: any, index: number) => {
                  return (
                    <View style={styles.packageInfo} key={index}>
                      <PrimaryText
                        content={item.title}
                        style={styles.contentTextHeader}
                      />
                      <PrimaryText
                        content={item.value}
                        style={styles.contentText}
                      />
                    </View>
                  );
                })}
              </View>
            ) : (
              <>
                <PrimaryText
                  content={'Última guía escaneada'}
                  style={styles.contentTextHeader}
                />
                <View style={styles.trackingNumber}>
                  <PrimaryOutputStatus
                    backgroundColor={packageInfo.exist ? '#50E3C2' : '#D0021B'}
                  />

                  <PrimaryText
                    content={packageInfo.internalId}
                    style={styles.movementText}
                  />
                </View>
              </>
            )
          }
        />
      </ContainerScreen>
    </View>
  );
};
