import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    menuIconStyle: {
      position: 'absolute',
      right: 15,
    },
  });
};
