import React from 'react';
import {SafeAreaView, StyleSheet, View, StatusBar} from 'react-native';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Route} from './navigation/react-router';
import {Switch} from 'react-router-native';

import {
  Confirm,
  ConsolidatedSearch,
  IdCard,
  LoginPage,
  MovementPage,
  ScanCategories,
  PersonnelRecord,
} from './screens';
import {AppContext} from './context';
import {ProtectedRoute} from './auth/ProtectedRoute';
import './icons';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext>
        <StatusBar barStyle="light-content" />
        <SafeAreaView>
          <View style={styles.body}>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <ProtectedRoute path="/home" component={IdCard} />
              <ProtectedRoute
                path={'/search-categories'}
                component={ScanCategories}
              />
              <ProtectedRoute
                path={'/personnel-record'}
                component={PersonnelRecord}
              />
              <ProtectedRoute
                path="/search/:state"
                component={ConsolidatedSearch}
              />
              <ProtectedRoute path="/movement" component={MovementPage} />
              <ProtectedRoute path="/confirm" component={Confirm} />
            </Switch>
          </View>
        </SafeAreaView>
      </AppContext>
    </QueryClientProvider>
  );
};

const styles = StyleSheet.create({
  body: {
    width: '100%',
    height: '100%',
  },
});

export default App;
