import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    modal: {
      maxHeight: 700,
      paddingBottom: 30,
      textAlign: 'center',
    },
    iconContainer: {
      width: 60,
      height: 60,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#D0021B',
      borderRadius: 50,
      position: 'absolute',
      top: -30,
      zIndex: 1,
    },
    icon: {
      color: '#fff',
      fontWeight: '700',
      marginLeft: 2.5,
    },
    indicator: {
      width: '100%',
      height: 7,
      backgroundColor: '#D0021B',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
    },
    header: {
      fontSize: 24,
      fontWeight: '700',
      color: '#383434',
      marginBottom: 15,
    },
    buttonsContainer: {
      width: '90%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    button: {
      width: '40%',
    },
    buttonStyle: {
      backgroundColor: '#000',
      height: 37,
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonNormalStyle: {
      height: 37,
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTitle: {
      marginTop: 0,
      lineHeight: 16,
    },
  });
};
