import {StyleSheet} from 'react-native';

interface StyleProps {
  backgroundColor: string;
}

export const makeStyles = ({backgroundColor}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      alignSelf: 'center',
      width: '85%',
      height: 40,
      backgroundColor: backgroundColor,
      marginTop: 5,
      borderRadius: 4,
      paddingHorizontal: 16,
    },
    contentContainer: {
      width: '85%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    titleText: {
      fontWeight: '700',
    },
    valueText: {
      fontWeight: '700',
      color: '#431782',
    },
  });
};
