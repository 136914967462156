import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    itemRowContainer: {
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
    },
    textStyle: {
      color: '#000',
      fontSize: 12,
    },
    dropdownButton: {
      width: 25,
      height: 25,
      justifySelf: 'flex-end',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000',
      borderRadius: 4,
    },
    iconColor: {
      color: '#fff',
    },
  });
};
