import React from 'react';
import {makeStyles} from './index.styles';
import {View, Text} from 'react-native';

interface OrdersListProps {
  options: string[];
  data: OrderOptions[];
  style?: any;
}

export interface OrderOptions {
  order: string;
  client: string;
  packages: string;
}

export const OrdersList = ({options, data, style}: OrdersListProps) => {
  const styles = makeStyles(style);
  return (
    <View style={[styles.container, style]}>
      <View style={styles.orderRow}>
        <View style={styles.row}>
          {options.map((value, index) => (
            <Text key={index} style={styles.text}>
              {value}
            </Text>
          ))}
        </View>
        {data.map((value, index) => (
          <View style={styles.row} key={index}>
            <Text style={styles.normalText}>{value.order}</Text>
            <Text style={styles.normalText}>{value.client}</Text>
            <Text style={styles.normalText}>{value.packages}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

OrdersList.defaultProps = {
  options: ['Order', 'Client', 'Paquetes'],
  data: [{order: '2312', client: 'Zara', packages: '2/33'}],
  style: {
    width: '100%',
    height: 'auto',
  },
};
