import React from 'react';
import {useHistory} from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react';

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory = ({
  children,
}: Auth0ProviderWithHistoryProps) => {
  const history = useHistory();
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const redirectUri: string = process.env.REACT_APP_AUTH0_REDIRECT_URI
    ? process.env.REACT_APP_AUTH0_REDIRECT_URI
    : //@ts-ignore
      window.location.origin;

  const onRedirectCallback = (appState: any) => {
    //@ts-ignore
    history.push(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
