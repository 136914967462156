import React from 'react';
import {View} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

import {PrimaryIndicator, PrimaryText} from '../../atoms';
import {makeStyles} from './index.styles';

interface PrimarySectionsHeaderProps {
  iconInfo: any;
  textInfo: any;
  indicatorInfo?: any;
}

export const PrimarySectionsHeader = ({
  iconInfo,
  textInfo,
  indicatorInfo,
}: PrimarySectionsHeaderProps) => {
  const {iconName, iconColor, iconSize, iconStyle} = iconInfo;
  const {textContent, textColor, fontSize, fontWeight} = textInfo;
  const width = indicatorInfo ? indicatorInfo.width : 163;
  const styles = makeStyles();

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Icon
          name={iconName}
          color={iconColor}
          size={iconSize}
          style={iconStyle}
        />
        <PrimaryText
          content={textContent}
          color={textColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
        />
      </View>
      <PrimaryIndicator width={width} backgroundColor={'#000'} height={3} />
    </View>
  );
};
