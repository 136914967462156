import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import IoniconsIcon from 'react-native-vector-icons/Ionicons';
import {makeStyles} from './index.styles';

import {PrimaryOutputStatus} from '../PrimaryOutputStatus';

interface PrimaryPackageProps {
  pack: any;
  onPress: any;
  style: any;
}

export const PrimaryPackage = ({pack, onPress, style}: PrimaryPackageProps) => {
  const styles = makeStyles(style);

  const {status, internalId} = pack;

  return (
    <View style={styles.container}>
      <PrimaryOutputStatus
        backgroundColor={status === 'sync' ? '#50E3C2' : '#D0021B'}
      />
      <Text>{internalId}</Text>
      <TouchableOpacity onPress={onPress}>
        <IoniconsIcon name="ios-trash" size={20} />
      </TouchableOpacity>
    </View>
  );
};

PrimaryPackage.defaultProps = {
  pack: {
    id: 1,
    status: 'no-sync',
    internalId: '12345678901234567890',
  },
  onPress: () => console.log('Delete item'),
};
