import React from 'react';
import {Router} from './navigation/react-router';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import App from './App';

const AppProvider = () => {
  return (
    <>
      <Router>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </Router>
    </>
  );
};

export default AppProvider;
