import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  bg: {
    height: 48,
    borderRadius: 4,
    backgroundColor: 'rgba(148,46,131,1)',
  },
  title: {
    backgroundColor: 'transparent',
    lineHeight: 22,
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    fontSize: 14,
    fontWeight: 'bolder',
    marginTop: 13,
    alignSelf: 'center',
  },
});

export default styles;
