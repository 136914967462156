import React from 'react';
import {useAuth0} from '@auth0/auth0-react';

import {LoginButton} from '../../atoms';
import {NextButton} from '../../atoms';

interface AuthenticationButtonProps {
  path: string;
}

const AuthenticationButton = ({path}: AuthenticationButtonProps) => {
  const {isAuthenticated} = useAuth0();

  return isAuthenticated ? <NextButton path={path} /> : <LoginButton />;
};

export default AuthenticationButton;
