import {StyleSheet, FlexAlignType} from 'react-native';

interface StyleProps {
  height?: number | string;
  width?: number | string;
  alignSelf?: FlexAlignType;
}

export const makeStyles = ({height, width, alignSelf}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      height,
      width,
      alignSelf,
    },
    icon: {
      color: 'rgba(145,163,176,1)',
      fontSize: 25,
      marginTop: 8,
      alignSelf: 'center',
    },
    limpiar: {
      color: 'black',
      height: 16,
      fontSize: 13,
      textAlign: 'center',
      marginTop: 1,
      fontWeight: 600,
      opacity: 0.9,
    },
  });
};
