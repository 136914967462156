import React from 'react';
import {Link} from '../../../navigation/react-router';

import {PrimaryButton} from '../PrimaryButton';

interface NextButtonProps {
  path: string;
}

export const NextButton = ({path}: NextButtonProps) => {
  return (
    <Link to={path}>
      <PrimaryButton title={'Next'} />
    </Link>
  );
};
