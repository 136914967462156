import {GOOGLE_VISION_API, GOOGLE_VISION_API_KEY} from '../../../config';

export const callGoogleVisionApi = async (
  base64: string,
  setState: (value: string) => void,
  features: any[],
) => {
  if (GOOGLE_VISION_API && GOOGLE_VISION_API_KEY) {
    const googleVisionRes = await fetch(
      GOOGLE_VISION_API + GOOGLE_VISION_API_KEY,
      {
        method: 'POST',
        body: JSON.stringify({
          requests: [
            {
              image: {
                content: base64,
              },
              features: features,
            },
          ],
        }),
      },
    );
    await googleVisionRes
      .json()
      .then((googleRes) => {
        if (googleRes) {
          const {responses} = googleRes;
          const {text} = responses[0].fullTextAnnotation;
          setState(`${text}`);
        } else {
          setState('');
        }
      })
      .catch((error) => {
        setState('Error');
        console.log('Error: ', error);
      });
  } else {
    console.log('No env variables found');
    setState('Error');
  }
};
