import { GraphQLClient } from 'graphql-request';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables);
}
// The file generated in: 2022-03-31T12:11:24-03:00

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};






export type Action = {
  __typename?: 'Action';
  id: Scalars['ID'];
  warehouseId: Scalars['String'];
  warehouseName: Scalars['String'];
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy: Scalars['String'];
  executedById: Scalars['String'];
  routeId?: Maybe<Scalars['String']>;
  actionTypeId: Scalars['String'];
  actionTypeName: Scalars['String'];
  actionTypeDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  alertsAggregate: ActionAlertsAggregateResponse;
  evidence?: Maybe<ActionAttachment>;
  alerts?: Maybe<ActionAlertsConnection>;
};


export type ActionAlertsAggregateArgs = {
  filter?: Maybe<ActionAttachmentAggregateFilter>;
};


export type ActionAlertsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionAttachmentFilter>;
  sorting?: Maybe<Array<ActionAttachmentSort>>;
};

export type ActionAggregateFilter = {
  and?: Maybe<Array<ActionAggregateFilter>>;
  or?: Maybe<Array<ActionAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  warehouseName?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  actionTypeDescription?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  comments?: Maybe<StringFieldComparison>;
  manifestId?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type ActionAggregateResponse = {
  __typename?: 'ActionAggregateResponse';
  count?: Maybe<ActionCountAggregate>;
  min?: Maybe<ActionMinAggregate>;
  max?: Maybe<ActionMaxAggregate>;
};

export type ActionAlertsAggregateResponse = {
  __typename?: 'ActionAlertsAggregateResponse';
  count?: Maybe<ActionAlertsCountAggregate>;
  min?: Maybe<ActionAlertsMinAggregate>;
  max?: Maybe<ActionAlertsMaxAggregate>;
};

export type ActionAlertsConnection = {
  __typename?: 'ActionAlertsConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ActionAttachment>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionAlertsCountAggregate = {
  __typename?: 'ActionAlertsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  attachmentType?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ActionAlertsMaxAggregate = {
  __typename?: 'ActionAlertsMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  attachmentType?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionAlertsMinAggregate = {
  __typename?: 'ActionAlertsMinAggregate';
  id?: Maybe<Scalars['ID']>;
  attachmentType?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionAttachment = {
  __typename?: 'ActionAttachment';
  id: Scalars['ID'];
  attachmentType: Scalars['String'];
  path: Scalars['String'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  actionsAggregate: ActionAttachmentActionsAggregateResponse;
  actions?: Maybe<ActionAttachmentActionsConnection>;
};


export type ActionAttachmentActionsAggregateArgs = {
  filter?: Maybe<ActionAggregateFilter>;
};


export type ActionAttachmentActionsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionFilter>;
  sorting?: Maybe<Array<ActionSort>>;
};

export type ActionAttachmentActionsAggregateResponse = {
  __typename?: 'ActionAttachmentActionsAggregateResponse';
  count?: Maybe<ActionAttachmentActionsCountAggregate>;
  min?: Maybe<ActionAttachmentActionsMinAggregate>;
  max?: Maybe<ActionAttachmentActionsMaxAggregate>;
};

export type ActionAttachmentActionsConnection = {
  __typename?: 'ActionAttachmentActionsConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Action>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionAttachmentActionsCountAggregate = {
  __typename?: 'ActionAttachmentActionsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseName?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  actionTypeDescription?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  manifestId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ActionAttachmentActionsMaxAggregate = {
  __typename?: 'ActionAttachmentActionsMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  actionTypeDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionAttachmentActionsMinAggregate = {
  __typename?: 'ActionAttachmentActionsMinAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  actionTypeDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionAttachmentAggregateFilter = {
  and?: Maybe<Array<ActionAttachmentAggregateFilter>>;
  or?: Maybe<Array<ActionAttachmentAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  attachmentType?: Maybe<StringFieldComparison>;
  path?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type ActionAttachmentAggregateResponse = {
  __typename?: 'ActionAttachmentAggregateResponse';
  count?: Maybe<ActionAttachmentCountAggregate>;
  min?: Maybe<ActionAttachmentMinAggregate>;
  max?: Maybe<ActionAttachmentMaxAggregate>;
};

export type ActionAttachmentConnection = {
  __typename?: 'ActionAttachmentConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ActionAttachment>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionAttachmentCountAggregate = {
  __typename?: 'ActionAttachmentCountAggregate';
  id?: Maybe<Scalars['Int']>;
  attachmentType?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ActionAttachmentFilter = {
  and?: Maybe<Array<ActionAttachmentFilter>>;
  or?: Maybe<Array<ActionAttachmentFilter>>;
  id?: Maybe<IdFilterComparison>;
  attachmentType?: Maybe<StringFieldComparison>;
  path?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type ActionAttachmentInput = {
  path: Scalars['String'];
  attachmentType: Scalars['String'];
};

export type ActionAttachmentMaxAggregate = {
  __typename?: 'ActionAttachmentMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  attachmentType?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionAttachmentMinAggregate = {
  __typename?: 'ActionAttachmentMinAggregate';
  id?: Maybe<Scalars['ID']>;
  attachmentType?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionAttachmentSort = {
  field: ActionAttachmentSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ActionAttachmentSortFields {
  Id = 'id',
  AttachmentType = 'attachmentType',
  Path = 'path',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type ActionAttachmentUpdate = {
  path?: Maybe<Scalars['String']>;
  attachmentType?: Maybe<Scalars['String']>;
};

export type ActionAttachmentUpdateFilter = {
  and?: Maybe<Array<ActionAttachmentUpdateFilter>>;
  or?: Maybe<Array<ActionAttachmentUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  attachmentType?: Maybe<StringFieldComparison>;
  path?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type ActionConnection = {
  __typename?: 'ActionConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Action>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionCountAggregate = {
  __typename?: 'ActionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseName?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  actionTypeDescription?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  manifestId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ActionFilter = {
  and?: Maybe<Array<ActionFilter>>;
  or?: Maybe<Array<ActionFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  warehouseName?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  actionTypeDescription?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  comments?: Maybe<StringFieldComparison>;
  manifestId?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type ActionInput = {
  warehouseId: Scalars['String'];
  warehouseName: Scalars['String'];
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  zoneLegacyId?: Maybe<Scalars['String']>;
  zoneLegacyName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionTypeId: Scalars['String'];
  actionTypeName: Scalars['String'];
  actionTypeDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  evidence?: Maybe<ActionAttachmentInput>;
};

export type ActionMaxAggregate = {
  __typename?: 'ActionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  actionTypeDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionMinAggregate = {
  __typename?: 'ActionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  actionTypeDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionSort = {
  field: ActionSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ActionSortFields {
  Id = 'id',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName',
  ZoneId = 'zoneId',
  ZoneName = 'zoneName',
  SubZoneId = 'subZoneId',
  SubZoneName = 'subZoneName',
  InternalId = 'internalId',
  ExternalId = 'externalId',
  AuthorizedBy = 'authorizedBy',
  AuthorizedById = 'authorizedById',
  ExecutedBy = 'executedBy',
  ExecutedById = 'executedById',
  RouteId = 'routeId',
  ActionTypeId = 'actionTypeId',
  ActionTypeName = 'actionTypeName',
  ActionTypeDescription = 'actionTypeDescription',
  ClientId = 'clientId',
  ClientName = 'clientName',
  Comments = 'comments',
  ManifestId = 'manifestId',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type ActionTransactionsInput = {
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  actionTypeId: Scalars['String'];
  actionTypeName: Scalars['String'];
  actionTypeDescription?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  packages: Array<PackageInput>;
};

export type ActionType = {
  __typename?: 'ActionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  triggerAlert?: Maybe<Scalars['Boolean']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  sort?: Maybe<Scalars['Float']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  forbiddenZonesAggregate: ActionTypeForbiddenZonesAggregateResponse;
  stocksAggregate: ActionTypeStocksAggregateResponse;
  alertType?: Maybe<AlertType>;
  actionTypeCategory?: Maybe<ActionTypeCategory>;
  forbiddenZones?: Maybe<ActionTypeForbiddenZonesConnection>;
  stocks?: Maybe<ActionTypeStocksConnection>;
};


export type ActionTypeForbiddenZonesAggregateArgs = {
  filter?: Maybe<ZoneActionTypeBlacklistAggregateFilter>;
};


export type ActionTypeStocksAggregateArgs = {
  filter?: Maybe<StockAggregateFilter>;
};


export type ActionTypeForbiddenZonesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneActionTypeBlacklistFilter>;
  sorting?: Maybe<Array<ZoneActionTypeBlacklistSort>>;
};


export type ActionTypeStocksArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<StockFilter>;
  sorting?: Maybe<Array<StockSort>>;
};

export type ActionTypeAggregateFilter = {
  and?: Maybe<Array<ActionTypeAggregateFilter>>;
  or?: Maybe<Array<ActionTypeAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeAggregateResponse = {
  __typename?: 'ActionTypeAggregateResponse';
  count?: Maybe<ActionTypeCountAggregate>;
  sum?: Maybe<ActionTypeSumAggregate>;
  avg?: Maybe<ActionTypeAvgAggregate>;
  min?: Maybe<ActionTypeMinAggregate>;
  max?: Maybe<ActionTypeMaxAggregate>;
};

export type ActionTypeAvgAggregate = {
  __typename?: 'ActionTypeAvgAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ActionTypeCategory = {
  __typename?: 'ActionTypeCategory';
  id: Scalars['ID'];
  key: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  actionTypesAggregate: ActionTypeCategoryActionTypesAggregateResponse;
  actionTypes?: Maybe<ActionTypeCategoryActionTypesConnection>;
};


export type ActionTypeCategoryActionTypesAggregateArgs = {
  filter?: Maybe<ActionTypeAggregateFilter>;
};


export type ActionTypeCategoryActionTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionTypeFilter>;
  sorting?: Maybe<Array<ActionTypeSort>>;
};

export type ActionTypeCategoryActionTypesAggregateResponse = {
  __typename?: 'ActionTypeCategoryActionTypesAggregateResponse';
  count?: Maybe<ActionTypeCategoryActionTypesCountAggregate>;
  sum?: Maybe<ActionTypeCategoryActionTypesSumAggregate>;
  avg?: Maybe<ActionTypeCategoryActionTypesAvgAggregate>;
  min?: Maybe<ActionTypeCategoryActionTypesMinAggregate>;
  max?: Maybe<ActionTypeCategoryActionTypesMaxAggregate>;
};

export type ActionTypeCategoryActionTypesAvgAggregate = {
  __typename?: 'ActionTypeCategoryActionTypesAvgAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ActionTypeCategoryActionTypesConnection = {
  __typename?: 'ActionTypeCategoryActionTypesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ActionType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionTypeCategoryActionTypesCountAggregate = {
  __typename?: 'ActionTypeCategoryActionTypesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  triggerAlert?: Maybe<Scalars['Int']>;
  alertTypeId?: Maybe<Scalars['Int']>;
  actionTypeCategoryId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ActionTypeCategoryActionTypesMaxAggregate = {
  __typename?: 'ActionTypeCategoryActionTypesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeCategoryActionTypesMinAggregate = {
  __typename?: 'ActionTypeCategoryActionTypesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeCategoryActionTypesSumAggregate = {
  __typename?: 'ActionTypeCategoryActionTypesSumAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ActionTypeCategoryAggregateFilter = {
  and?: Maybe<Array<ActionTypeCategoryAggregateFilter>>;
  or?: Maybe<Array<ActionTypeCategoryAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeCategoryAggregateResponse = {
  __typename?: 'ActionTypeCategoryAggregateResponse';
  count?: Maybe<ActionTypeCategoryCountAggregate>;
  min?: Maybe<ActionTypeCategoryMinAggregate>;
  max?: Maybe<ActionTypeCategoryMaxAggregate>;
};

export type ActionTypeCategoryConnection = {
  __typename?: 'ActionTypeCategoryConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ActionTypeCategory>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionTypeCategoryCountAggregate = {
  __typename?: 'ActionTypeCategoryCountAggregate';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ActionTypeCategoryDeleteFilter = {
  and?: Maybe<Array<ActionTypeCategoryDeleteFilter>>;
  or?: Maybe<Array<ActionTypeCategoryDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeCategoryFilter = {
  and?: Maybe<Array<ActionTypeCategoryFilter>>;
  or?: Maybe<Array<ActionTypeCategoryFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  actionTypes?: Maybe<ActionTypeCategoryFilterActionTypeFilter>;
};

export type ActionTypeCategoryFilterActionTypeFilter = {
  and?: Maybe<Array<ActionTypeCategoryFilterActionTypeFilter>>;
  or?: Maybe<Array<ActionTypeCategoryFilterActionTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeCategoryInput = {
  key: Scalars['String'];
  description: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type ActionTypeCategoryMaxAggregate = {
  __typename?: 'ActionTypeCategoryMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeCategoryMinAggregate = {
  __typename?: 'ActionTypeCategoryMinAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeCategorySort = {
  field: ActionTypeCategorySortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ActionTypeCategorySortFields {
  Id = 'id',
  Key = 'key',
  Description = 'description',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ActionTypeCategoryUpdate = {
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeCategoryUpdateFilter = {
  and?: Maybe<Array<ActionTypeCategoryUpdateFilter>>;
  or?: Maybe<Array<ActionTypeCategoryUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeConnection = {
  __typename?: 'ActionTypeConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ActionType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionTypeCountAggregate = {
  __typename?: 'ActionTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  triggerAlert?: Maybe<Scalars['Int']>;
  alertTypeId?: Maybe<Scalars['Int']>;
  actionTypeCategoryId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ActionTypeDeleteFilter = {
  and?: Maybe<Array<ActionTypeDeleteFilter>>;
  or?: Maybe<Array<ActionTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeFilter = {
  and?: Maybe<Array<ActionTypeFilter>>;
  or?: Maybe<Array<ActionTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  actionTypeCategory?: Maybe<ActionTypeFilterActionTypeCategoryFilter>;
  alertType?: Maybe<ActionTypeFilterAlertTypeFilter>;
  stocks?: Maybe<ActionTypeFilterStockFilter>;
  forbiddenZones?: Maybe<ActionTypeFilterZoneActionTypeBlacklistFilter>;
};

export type ActionTypeFilterActionTypeCategoryFilter = {
  and?: Maybe<Array<ActionTypeFilterActionTypeCategoryFilter>>;
  or?: Maybe<Array<ActionTypeFilterActionTypeCategoryFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeFilterAlertTypeFilter = {
  and?: Maybe<Array<ActionTypeFilterAlertTypeFilter>>;
  or?: Maybe<Array<ActionTypeFilterAlertTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeFilterStockFilter = {
  and?: Maybe<Array<ActionTypeFilterStockFilter>>;
  or?: Maybe<Array<ActionTypeFilterStockFilter>>;
  id?: Maybe<IdFilterComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionId?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  originWarehouseId?: Maybe<StringFieldComparison>;
  destinationWarehouseId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeFilterZoneActionTypeBlacklistFilter = {
  and?: Maybe<Array<ActionTypeFilterZoneActionTypeBlacklistFilter>>;
  or?: Maybe<Array<ActionTypeFilterZoneActionTypeBlacklistFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionTypeForbiddenZonesAggregateResponse = {
  __typename?: 'ActionTypeForbiddenZonesAggregateResponse';
  count?: Maybe<ActionTypeForbiddenZonesCountAggregate>;
  min?: Maybe<ActionTypeForbiddenZonesMinAggregate>;
  max?: Maybe<ActionTypeForbiddenZonesMaxAggregate>;
};

export type ActionTypeForbiddenZonesConnection = {
  __typename?: 'ActionTypeForbiddenZonesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ZoneActionTypeBlacklist>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionTypeForbiddenZonesCountAggregate = {
  __typename?: 'ActionTypeForbiddenZonesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ActionTypeForbiddenZonesMaxAggregate = {
  __typename?: 'ActionTypeForbiddenZonesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeForbiddenZonesMinAggregate = {
  __typename?: 'ActionTypeForbiddenZonesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  triggerAlert?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Float']>;
};

export type ActionTypeMaxAggregate = {
  __typename?: 'ActionTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeMinAggregate = {
  __typename?: 'ActionTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeSort = {
  field: ActionTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ActionTypeSortFields {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  TriggerAlert = 'triggerAlert',
  AlertTypeId = 'alertTypeId',
  ActionTypeCategoryId = 'actionTypeCategoryId',
  Active = 'active',
  Sort = 'sort',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ActionTypeStocksAggregateResponse = {
  __typename?: 'ActionTypeStocksAggregateResponse';
  count?: Maybe<ActionTypeStocksCountAggregate>;
  min?: Maybe<ActionTypeStocksMinAggregate>;
  max?: Maybe<ActionTypeStocksMaxAggregate>;
};

export type ActionTypeStocksConnection = {
  __typename?: 'ActionTypeStocksConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Stock>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionTypeStocksCountAggregate = {
  __typename?: 'ActionTypeStocksCountAggregate';
  id?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  originWarehouseId?: Maybe<Scalars['Int']>;
  destinationWarehouseId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ActionTypeStocksMaxAggregate = {
  __typename?: 'ActionTypeStocksMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeStocksMinAggregate = {
  __typename?: 'ActionTypeStocksMinAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeSumAggregate = {
  __typename?: 'ActionTypeSumAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ActionTypeUpdate = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  triggerAlert?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionTypeUpdateFilter = {
  and?: Maybe<Array<ActionTypeUpdateFilter>>;
  or?: Maybe<Array<ActionTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ActionUpdate = {
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  actionTypeDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  evidence?: Maybe<ActionAttachmentUpdate>;
};

export type ActionUpdateFilter = {
  and?: Maybe<Array<ActionUpdateFilter>>;
  or?: Maybe<Array<ActionUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  warehouseName?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  actionTypeDescription?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  comments?: Maybe<StringFieldComparison>;
  manifestId?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  street: Scalars['String'];
  externalNumber: Scalars['String'];
  internalNumber?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  warehousesAggregate: AddressWarehousesAggregateResponse;
  warehouses?: Maybe<AddressWarehousesConnection>;
};


export type AddressWarehousesAggregateArgs = {
  filter?: Maybe<WarehouseAggregateFilter>;
};


export type AddressWarehousesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<WarehouseFilter>;
  sorting?: Maybe<Array<WarehouseSort>>;
};

export type AddressAggregateFilter = {
  and?: Maybe<Array<AddressAggregateFilter>>;
  or?: Maybe<Array<AddressAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  street?: Maybe<StringFieldComparison>;
  externalNumber?: Maybe<StringFieldComparison>;
  internalNumber?: Maybe<StringFieldComparison>;
  neighborhood?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  zipCode?: Maybe<StringFieldComparison>;
  latitude?: Maybe<NumberFieldComparison>;
  longitude?: Maybe<NumberFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AddressAggregateResponse = {
  __typename?: 'AddressAggregateResponse';
  count?: Maybe<AddressCountAggregate>;
  sum?: Maybe<AddressSumAggregate>;
  avg?: Maybe<AddressAvgAggregate>;
  min?: Maybe<AddressMinAggregate>;
  max?: Maybe<AddressMaxAggregate>;
};

export type AddressAvgAggregate = {
  __typename?: 'AddressAvgAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Address>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AddressCountAggregate = {
  __typename?: 'AddressCountAggregate';
  id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['Int']>;
  externalNumber?: Maybe<Scalars['Int']>;
  internalNumber?: Maybe<Scalars['Int']>;
  neighborhood?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type AddressDeleteFilter = {
  and?: Maybe<Array<AddressDeleteFilter>>;
  or?: Maybe<Array<AddressDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  street?: Maybe<StringFieldComparison>;
  externalNumber?: Maybe<StringFieldComparison>;
  internalNumber?: Maybe<StringFieldComparison>;
  neighborhood?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  zipCode?: Maybe<StringFieldComparison>;
  latitude?: Maybe<NumberFieldComparison>;
  longitude?: Maybe<NumberFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AddressFilter = {
  and?: Maybe<Array<AddressFilter>>;
  or?: Maybe<Array<AddressFilter>>;
  id?: Maybe<IdFilterComparison>;
  street?: Maybe<StringFieldComparison>;
  externalNumber?: Maybe<StringFieldComparison>;
  internalNumber?: Maybe<StringFieldComparison>;
  neighborhood?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  zipCode?: Maybe<StringFieldComparison>;
  latitude?: Maybe<NumberFieldComparison>;
  longitude?: Maybe<NumberFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  warehouses?: Maybe<AddressFilterWarehouseFilter>;
};

export type AddressFilterWarehouseFilter = {
  and?: Maybe<Array<AddressFilterWarehouseFilter>>;
  or?: Maybe<Array<AddressFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AddressInput = {
  street: Scalars['String'];
  externalNumber: Scalars['String'];
  internalNumber?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  active?: Maybe<Scalars['Boolean']>;
};

export type AddressMaxAggregate = {
  __typename?: 'AddressMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  street?: Maybe<Scalars['String']>;
  externalNumber?: Maybe<Scalars['String']>;
  internalNumber?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressMinAggregate = {
  __typename?: 'AddressMinAggregate';
  id?: Maybe<Scalars['ID']>;
  street?: Maybe<Scalars['String']>;
  externalNumber?: Maybe<Scalars['String']>;
  internalNumber?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressSort = {
  field: AddressSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum AddressSortFields {
  Id = 'id',
  Street = 'street',
  ExternalNumber = 'externalNumber',
  InternalNumber = 'internalNumber',
  Neighborhood = 'neighborhood',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type AddressSumAggregate = {
  __typename?: 'AddressSumAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type AddressUpdate = {
  street?: Maybe<Scalars['String']>;
  externalNumber?: Maybe<Scalars['String']>;
  internalNumber?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressUpdateFilter = {
  and?: Maybe<Array<AddressUpdateFilter>>;
  or?: Maybe<Array<AddressUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  street?: Maybe<StringFieldComparison>;
  externalNumber?: Maybe<StringFieldComparison>;
  internalNumber?: Maybe<StringFieldComparison>;
  neighborhood?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  zipCode?: Maybe<StringFieldComparison>;
  latitude?: Maybe<NumberFieldComparison>;
  longitude?: Maybe<NumberFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AddressWarehousesAggregateResponse = {
  __typename?: 'AddressWarehousesAggregateResponse';
  count?: Maybe<AddressWarehousesCountAggregate>;
  min?: Maybe<AddressWarehousesMinAggregate>;
  max?: Maybe<AddressWarehousesMaxAggregate>;
};

export type AddressWarehousesConnection = {
  __typename?: 'AddressWarehousesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Warehouse>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AddressWarehousesCountAggregate = {
  __typename?: 'AddressWarehousesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  isMain?: Maybe<Scalars['Int']>;
  warehouseTypeId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
  zoneIdLegacy?: Maybe<Scalars['Int']>;
  zoneNameLegacy?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type AddressWarehousesMaxAggregate = {
  __typename?: 'AddressWarehousesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressWarehousesMinAggregate = {
  __typename?: 'AddressWarehousesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type Alert = {
  __typename?: 'Alert';
  id: Scalars['ID'];
  description: Scalars['String'];
  attendedBy?: Maybe<Scalars['String']>;
  attended: Scalars['Boolean'];
  internalId: Scalars['String'];
  externalId: Scalars['String'];
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  alertTypeId: Scalars['String'];
  alertStatusId: Scalars['String'];
  alertStatusDescription: Scalars['String'];
  actionTypeId: Scalars['String'];
  actionTypeName: Scalars['String'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  action?: Maybe<Action>;
};

export type AlertAggregateFilter = {
  and?: Maybe<Array<AlertAggregateFilter>>;
  or?: Maybe<Array<AlertAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  description?: Maybe<StringFieldComparison>;
  attendedBy?: Maybe<StringFieldComparison>;
  attended?: Maybe<BooleanFieldComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  warehouseName?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  alertStatusId?: Maybe<StringFieldComparison>;
  alertStatusDescription?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type AlertAggregateResponse = {
  __typename?: 'AlertAggregateResponse';
  count?: Maybe<AlertCountAggregate>;
  min?: Maybe<AlertMinAggregate>;
  max?: Maybe<AlertMaxAggregate>;
};

export type AlertConnection = {
  __typename?: 'AlertConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Alert>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AlertCountAggregate = {
  __typename?: 'AlertCountAggregate';
  id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  attendedBy?: Maybe<Scalars['Int']>;
  attended?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseName?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  alertTypeId?: Maybe<Scalars['Int']>;
  alertStatusId?: Maybe<Scalars['Int']>;
  alertStatusDescription?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type AlertFilter = {
  and?: Maybe<Array<AlertFilter>>;
  or?: Maybe<Array<AlertFilter>>;
  id?: Maybe<IdFilterComparison>;
  description?: Maybe<StringFieldComparison>;
  attendedBy?: Maybe<StringFieldComparison>;
  attended?: Maybe<BooleanFieldComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  warehouseName?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  alertStatusId?: Maybe<StringFieldComparison>;
  alertStatusDescription?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type AlertInput = {
  description: Scalars['String'];
  attendedBy?: Maybe<Scalars['String']>;
  attended?: Maybe<Scalars['Boolean']>;
  internalId: Scalars['String'];
  externalId: Scalars['String'];
  action?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  alertTypeId: Scalars['String'];
  alertTypeDescription: Scalars['String'];
  alertStatusId: Scalars['String'];
  alertStatusDescription: Scalars['String'];
  actionTypeId: Scalars['String'];
  actionTypeName: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
};

export type AlertMaxAggregate = {
  __typename?: 'AlertMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  attendedBy?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  alertStatusId?: Maybe<Scalars['String']>;
  alertStatusDescription?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertMinAggregate = {
  __typename?: 'AlertMinAggregate';
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  attendedBy?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  alertStatusId?: Maybe<Scalars['String']>;
  alertStatusDescription?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertSort = {
  field: AlertSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum AlertSortFields {
  Id = 'id',
  Description = 'description',
  AttendedBy = 'attendedBy',
  Attended = 'attended',
  InternalId = 'internalId',
  ExternalId = 'externalId',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName',
  ZoneId = 'zoneId',
  ZoneName = 'zoneName',
  AlertTypeId = 'alertTypeId',
  AlertStatusId = 'alertStatusId',
  AlertStatusDescription = 'alertStatusDescription',
  ActionTypeId = 'actionTypeId',
  ActionTypeName = 'actionTypeName',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type AlertStatus = {
  __typename?: 'AlertStatus';
  id: Scalars['ID'];
  key: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertStatusAggregateFilter = {
  and?: Maybe<Array<AlertStatusAggregateFilter>>;
  or?: Maybe<Array<AlertStatusAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertStatusAggregateResponse = {
  __typename?: 'AlertStatusAggregateResponse';
  count?: Maybe<AlertStatusCountAggregate>;
  min?: Maybe<AlertStatusMinAggregate>;
  max?: Maybe<AlertStatusMaxAggregate>;
};

export type AlertStatusConnection = {
  __typename?: 'AlertStatusConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<AlertStatus>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AlertStatusCountAggregate = {
  __typename?: 'AlertStatusCountAggregate';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type AlertStatusDeleteFilter = {
  and?: Maybe<Array<AlertStatusDeleteFilter>>;
  or?: Maybe<Array<AlertStatusDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertStatusFilter = {
  and?: Maybe<Array<AlertStatusFilter>>;
  or?: Maybe<Array<AlertStatusFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertStatusInput = {
  key: Scalars['String'];
  description: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type AlertStatusMaxAggregate = {
  __typename?: 'AlertStatusMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertStatusMinAggregate = {
  __typename?: 'AlertStatusMinAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertStatusSort = {
  field: AlertStatusSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum AlertStatusSortFields {
  Id = 'id',
  Key = 'key',
  Description = 'description',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type AlertStatusUpdate = {
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertStatusUpdateFilter = {
  and?: Maybe<Array<AlertStatusUpdateFilter>>;
  or?: Maybe<Array<AlertStatusUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertType = {
  __typename?: 'AlertType';
  id: Scalars['ID'];
  key: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  actionTypesAggregate: AlertTypeActionTypesAggregateResponse;
  actionTypes?: Maybe<AlertTypeActionTypesConnection>;
};


export type AlertTypeActionTypesAggregateArgs = {
  filter?: Maybe<ActionTypeAggregateFilter>;
};


export type AlertTypeActionTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionTypeFilter>;
  sorting?: Maybe<Array<ActionTypeSort>>;
};

export type AlertTypeActionTypesAggregateResponse = {
  __typename?: 'AlertTypeActionTypesAggregateResponse';
  count?: Maybe<AlertTypeActionTypesCountAggregate>;
  sum?: Maybe<AlertTypeActionTypesSumAggregate>;
  avg?: Maybe<AlertTypeActionTypesAvgAggregate>;
  min?: Maybe<AlertTypeActionTypesMinAggregate>;
  max?: Maybe<AlertTypeActionTypesMaxAggregate>;
};

export type AlertTypeActionTypesAvgAggregate = {
  __typename?: 'AlertTypeActionTypesAvgAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type AlertTypeActionTypesConnection = {
  __typename?: 'AlertTypeActionTypesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ActionType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AlertTypeActionTypesCountAggregate = {
  __typename?: 'AlertTypeActionTypesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  triggerAlert?: Maybe<Scalars['Int']>;
  alertTypeId?: Maybe<Scalars['Int']>;
  actionTypeCategoryId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type AlertTypeActionTypesMaxAggregate = {
  __typename?: 'AlertTypeActionTypesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertTypeActionTypesMinAggregate = {
  __typename?: 'AlertTypeActionTypesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  actionTypeCategoryId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertTypeActionTypesSumAggregate = {
  __typename?: 'AlertTypeActionTypesSumAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type AlertTypeAggregateFilter = {
  and?: Maybe<Array<AlertTypeAggregateFilter>>;
  or?: Maybe<Array<AlertTypeAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertTypeAggregateResponse = {
  __typename?: 'AlertTypeAggregateResponse';
  count?: Maybe<AlertTypeCountAggregate>;
  min?: Maybe<AlertTypeMinAggregate>;
  max?: Maybe<AlertTypeMaxAggregate>;
};

export type AlertTypeConnection = {
  __typename?: 'AlertTypeConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<AlertType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AlertTypeCountAggregate = {
  __typename?: 'AlertTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type AlertTypeDeleteFilter = {
  and?: Maybe<Array<AlertTypeDeleteFilter>>;
  or?: Maybe<Array<AlertTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertTypeFilter = {
  and?: Maybe<Array<AlertTypeFilter>>;
  or?: Maybe<Array<AlertTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  actionTypes?: Maybe<AlertTypeFilterActionTypeFilter>;
};

export type AlertTypeFilterActionTypeFilter = {
  and?: Maybe<Array<AlertTypeFilterActionTypeFilter>>;
  or?: Maybe<Array<AlertTypeFilterActionTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertTypeInput = {
  key: Scalars['String'];
  description: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type AlertTypeMaxAggregate = {
  __typename?: 'AlertTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertTypeMinAggregate = {
  __typename?: 'AlertTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertTypeSort = {
  field: AlertTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum AlertTypeSortFields {
  Id = 'id',
  Key = 'key',
  Description = 'description',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type AlertTypeUpdate = {
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertTypeUpdateFilter = {
  and?: Maybe<Array<AlertTypeUpdateFilter>>;
  or?: Maybe<Array<AlertTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type AlertUpdate = {
  description?: Maybe<Scalars['String']>;
  attendedBy?: Maybe<Scalars['String']>;
  attended?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  alertTypeId?: Maybe<Scalars['String']>;
  alertTypeDescription?: Maybe<Scalars['String']>;
  alertStatusId?: Maybe<Scalars['String']>;
  alertStatusDescription?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
};

export type AlertUpdateFilter = {
  and?: Maybe<Array<AlertUpdateFilter>>;
  or?: Maybe<Array<AlertUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  description?: Maybe<StringFieldComparison>;
  attendedBy?: Maybe<StringFieldComparison>;
  attended?: Maybe<BooleanFieldComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  warehouseName?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  alertStatusId?: Maybe<StringFieldComparison>;
  alertStatusDescription?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type AuthorizationToken = {
  __typename?: 'AuthorizationToken';
  authToken: Scalars['String'];
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export type Cities = {
  __typename?: 'Cities';
  nodes: Array<City>;
};

export type City = {
  __typename?: 'City';
  name: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ClientResponse = {
  __typename?: 'ClientResponse';
  nodes?: Maybe<Array<Client>>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  name: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  fax?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  warehousesAggregate: ContactWarehousesAggregateResponse;
  warehouses?: Maybe<ContactWarehousesConnection>;
};


export type ContactWarehousesAggregateArgs = {
  filter?: Maybe<WarehouseAggregateFilter>;
};


export type ContactWarehousesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<WarehouseFilter>;
  sorting?: Maybe<Array<WarehouseSort>>;
};

export type ContactAggregateFilter = {
  and?: Maybe<Array<ContactAggregateFilter>>;
  or?: Maybe<Array<ContactAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  lastname?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  fax?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ContactAggregateResponse = {
  __typename?: 'ContactAggregateResponse';
  count?: Maybe<ContactCountAggregate>;
  min?: Maybe<ContactMinAggregate>;
  max?: Maybe<ContactMaxAggregate>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Contact>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContactCountAggregate = {
  __typename?: 'ContactCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  fax?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ContactDeleteFilter = {
  and?: Maybe<Array<ContactDeleteFilter>>;
  or?: Maybe<Array<ContactDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  lastname?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  fax?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ContactFilter = {
  and?: Maybe<Array<ContactFilter>>;
  or?: Maybe<Array<ContactFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  lastname?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  fax?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  warehouses?: Maybe<ContactFilterWarehouseFilter>;
};

export type ContactFilterWarehouseFilter = {
  and?: Maybe<Array<ContactFilterWarehouseFilter>>;
  or?: Maybe<Array<ContactFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ContactInput = {
  name: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  fax?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ContactMaxAggregate = {
  __typename?: 'ContactMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ContactMinAggregate = {
  __typename?: 'ContactMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ContactSort = {
  field: ContactSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ContactSortFields {
  Id = 'id',
  Name = 'name',
  Lastname = 'lastname',
  Email = 'email',
  Phone = 'phone',
  Fax = 'fax',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ContactUpdate = {
  name?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ContactUpdateFilter = {
  and?: Maybe<Array<ContactUpdateFilter>>;
  or?: Maybe<Array<ContactUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  lastname?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  fax?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ContactWarehousesAggregateResponse = {
  __typename?: 'ContactWarehousesAggregateResponse';
  count?: Maybe<ContactWarehousesCountAggregate>;
  min?: Maybe<ContactWarehousesMinAggregate>;
  max?: Maybe<ContactWarehousesMaxAggregate>;
};

export type ContactWarehousesConnection = {
  __typename?: 'ContactWarehousesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Warehouse>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContactWarehousesCountAggregate = {
  __typename?: 'ContactWarehousesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  isMain?: Maybe<Scalars['Int']>;
  warehouseTypeId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
  zoneIdLegacy?: Maybe<Scalars['Int']>;
  zoneNameLegacy?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ContactWarehousesMaxAggregate = {
  __typename?: 'ContactWarehousesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ContactWarehousesMinAggregate = {
  __typename?: 'ContactWarehousesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateManyActionAttachmentsInput = {
  /** Array of records to create */
  actionAttachments: Array<ActionAttachmentInput>;
};

export type CreateManyActionTypeCategoriesInput = {
  /** Array of records to create */
  actionTypeCategories: Array<ActionTypeCategoryInput>;
};

export type CreateManyActionTypesInput = {
  /** Array of records to create */
  actionTypes: Array<ActionTypeInput>;
};

export type CreateManyActionsInput = {
  warehouseId: Scalars['String'];
  warehouseName: Scalars['String'];
  messengerId?: Maybe<Scalars['String']>;
  messengerName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  transactions: Array<ActionTransactionsInput>;
};

export type CreateManyActionsResponse = {
  __typename?: 'CreateManyActionsResponse';
  manifest: Manifest;
};

export type CreateManyAddressesInput = {
  /** Array of records to create */
  addresses: Array<AddressInput>;
};

export type CreateManyAlertStatusesInput = {
  /** Array of records to create */
  alertStatuses: Array<AlertStatusInput>;
};

export type CreateManyAlertTypesInput = {
  /** Array of records to create */
  alertTypes: Array<AlertTypeInput>;
};

export type CreateManyAlertsInput = {
  /** Array of records to create */
  alerts: Array<AlertInput>;
};

export type CreateManyContactsInput = {
  /** Array of records to create */
  contacts: Array<ContactInput>;
};

export type CreateManyManifestsInput = {
  /** Array of records to create */
  manifests: Array<ManifestInput>;
};

export type CreateManyPrintGatewaysInput = {
  /** Array of records to create */
  printGateways: Array<PrintGatewayInput>;
};

export type CreateManyPrintersInput = {
  /** Array of records to create */
  printers: Array<PrinterInput>;
};

export type CreateManyStocksInput = {
  /** Array of records to create */
  stocks: Array<StockInput>;
};

export type CreateManyUserWarehousesInput = {
  /** Array of records to create */
  userWarehouses: Array<UserWarehouseInput>;
};

export type CreateManyWarehouseTypesInput = {
  /** Array of records to create */
  warehouseTypes: Array<WarehouseTypeInput>;
};

export type CreateManyWarehousesInput = {
  /** Array of records to create */
  warehouses: Array<WarehouseInput>;
};

export type CreateManyZoneActionTypeBlacklistsInput = {
  /** Array of records to create */
  zoneActionTypeBlacklists: Array<ZoneActionTypeBlacklistInput>;
};

export type CreateManyZoneBlacklistsInput = {
  /** Array of records to create */
  zoneBlacklists: Array<ZoneBlacklistInput>;
};

export type CreateManyZoneTypesInput = {
  /** Array of records to create */
  zoneTypes: Array<ZoneTypeInput>;
};

export type CreateManyZonesInput = {
  /** Array of records to create */
  zones: Array<ZoneInput>;
};

export type CreateOneActionAttachmentInput = {
  /** The record to create */
  actionAttachment: ActionAttachmentInput;
};

export type CreateOneActionInput = {
  /** The record to create */
  action: ActionInput;
};

export type CreateOneActionTypeCategoryInput = {
  /** The record to create */
  actionTypeCategory: ActionTypeCategoryInput;
};

export type CreateOneActionTypeInput = {
  /** The record to create */
  actionType: ActionTypeInput;
};

export type CreateOneAddressInput = {
  /** The record to create */
  address: AddressInput;
};

export type CreateOneAlertInput = {
  /** The record to create */
  alert: AlertInput;
};

export type CreateOneAlertStatusInput = {
  /** The record to create */
  alertStatus: AlertStatusInput;
};

export type CreateOneAlertTypeInput = {
  /** The record to create */
  alertType: AlertTypeInput;
};

export type CreateOneContactInput = {
  /** The record to create */
  contact: ContactInput;
};

export type CreateOneManifestInput = {
  /** The record to create */
  manifest: ManifestInput;
};

export type CreateOnePrintGatewayInput = {
  /** The record to create */
  printGateway: PrintGatewayInput;
};

export type CreateOnePrinterInput = {
  /** The record to create */
  printer: PrinterInput;
};

export type CreateOneStockInput = {
  /** The record to create */
  stock: StockInput;
};

export type CreateOneUserWarehouseInput = {
  /** The record to create */
  userWarehouse: UserWarehouseInput;
};

export type CreateOneWarehouseInput = {
  /** The record to create */
  warehouse: WarehouseInput;
};

export type CreateOneWarehouseTypeInput = {
  /** The record to create */
  warehouseType: WarehouseTypeInput;
};

export type CreateOneZoneActionTypeBlacklistInput = {
  /** The record to create */
  zoneActionTypeBlacklist: ZoneActionTypeBlacklistInput;
};

export type CreateOneZoneBlacklistInput = {
  /** The record to create */
  zoneBlacklist: ZoneBlacklistInput;
};

export type CreateOneZoneInput = {
  /** The record to create */
  zone: ZoneInput;
};

export type CreateOneZoneTypeInput = {
  /** The record to create */
  zoneType: ZoneTypeInput;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};


export type DeleteManyActionTypeCategoriesInput = {
  /** Filter to find records to delete */
  filter: ActionTypeCategoryDeleteFilter;
};

export type DeleteManyActionTypesInput = {
  /** Filter to find records to delete */
  filter: ActionTypeDeleteFilter;
};

export type DeleteManyAddressesInput = {
  /** Filter to find records to delete */
  filter: AddressDeleteFilter;
};

export type DeleteManyAlertStatusesInput = {
  /** Filter to find records to delete */
  filter: AlertStatusDeleteFilter;
};

export type DeleteManyAlertTypesInput = {
  /** Filter to find records to delete */
  filter: AlertTypeDeleteFilter;
};

export type DeleteManyContactsInput = {
  /** Filter to find records to delete */
  filter: ContactDeleteFilter;
};

export type DeleteManyManifestsInput = {
  /** Filter to find records to delete */
  filter: ManifestDeleteFilter;
};

export type DeleteManyPrintGatewaysInput = {
  /** Filter to find records to delete */
  filter: PrintGatewayDeleteFilter;
};

export type DeleteManyPrintersInput = {
  /** Filter to find records to delete */
  filter: PrinterDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManyStocksInput = {
  /** Filter to find records to delete */
  filter: StockDeleteFilter;
};

export type DeleteManyUserWarehousesInput = {
  /** Filter to find records to delete */
  filter: UserWarehouseDeleteFilter;
};

export type DeleteManyWarehouseTypesInput = {
  /** Filter to find records to delete */
  filter: WarehouseTypeDeleteFilter;
};

export type DeleteManyWarehousesInput = {
  /** Filter to find records to delete */
  filter: WarehouseDeleteFilter;
};

export type DeleteManyZoneActionTypeBlacklistsInput = {
  /** Filter to find records to delete */
  filter: ZoneActionTypeBlacklistDeleteFilter;
};

export type DeleteManyZoneBlacklistsInput = {
  /** Filter to find records to delete */
  filter: ZoneBlacklistDeleteFilter;
};

export type DeleteManyZoneTypesInput = {
  /** Filter to find records to delete */
  filter: ZoneTypeDeleteFilter;
};

export type DeleteManyZonesInput = {
  /** Filter to find records to delete */
  filter: ZoneDeleteFilter;
};

export type DeleteOneInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneResponse = {
  __typename?: 'DeleteOneResponse';
  status: Scalars['String'];
};

export type IdFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['ID']>;
  neq?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  like?: Maybe<Scalars['ID']>;
  notLike?: Maybe<Scalars['ID']>;
  iLike?: Maybe<Scalars['ID']>;
  notILike?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  notIn?: Maybe<Array<Scalars['ID']>>;
};


export type JsonFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['JSON']>;
  neq?: Maybe<Scalars['JSON']>;
  gt?: Maybe<Scalars['JSON']>;
  gte?: Maybe<Scalars['JSON']>;
  lt?: Maybe<Scalars['JSON']>;
  lte?: Maybe<Scalars['JSON']>;
  like?: Maybe<Scalars['JSON']>;
  notLike?: Maybe<Scalars['JSON']>;
  iLike?: Maybe<Scalars['JSON']>;
  notILike?: Maybe<Scalars['JSON']>;
  in?: Maybe<Array<Scalars['JSON']>>;
  notIn?: Maybe<Array<Scalars['JSON']>>;
};

export type Manifest = {
  __typename?: 'Manifest';
  id: Scalars['ID'];
  warehouseId: Scalars['String'];
  messengerId?: Maybe<Scalars['String']>;
  messengerName?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  authorizedByName?: Maybe<Scalars['String']>;
  executedById: Scalars['String'];
  executedByName: Scalars['String'];
  packages?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  warehouse?: Maybe<Warehouse>;
};

export type ManifestAggregateFilter = {
  and?: Maybe<Array<ManifestAggregateFilter>>;
  or?: Maybe<Array<ManifestAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  messengerId?: Maybe<StringFieldComparison>;
  messengerName?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  authorizedByName?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  executedByName?: Maybe<StringFieldComparison>;
  packages?: Maybe<JsonFilterComparison>;
  comments?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ManifestAggregateResponse = {
  __typename?: 'ManifestAggregateResponse';
  count?: Maybe<ManifestCountAggregate>;
  min?: Maybe<ManifestMinAggregate>;
  max?: Maybe<ManifestMaxAggregate>;
};

export type ManifestConnection = {
  __typename?: 'ManifestConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Manifest>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ManifestCountAggregate = {
  __typename?: 'ManifestCountAggregate';
  id?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  messengerId?: Maybe<Scalars['Int']>;
  messengerName?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  authorizedByName?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  executedByName?: Maybe<Scalars['Int']>;
  packages?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ManifestDeleteFilter = {
  and?: Maybe<Array<ManifestDeleteFilter>>;
  or?: Maybe<Array<ManifestDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  messengerId?: Maybe<StringFieldComparison>;
  messengerName?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  authorizedByName?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  executedByName?: Maybe<StringFieldComparison>;
  packages?: Maybe<JsonFilterComparison>;
  comments?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ManifestFilter = {
  and?: Maybe<Array<ManifestFilter>>;
  or?: Maybe<Array<ManifestFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  messengerId?: Maybe<StringFieldComparison>;
  messengerName?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  authorizedByName?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  executedByName?: Maybe<StringFieldComparison>;
  packages?: Maybe<JsonFilterComparison>;
  comments?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  warehouse?: Maybe<ManifestFilterWarehouseFilter>;
};

export type ManifestFilterWarehouseFilter = {
  and?: Maybe<Array<ManifestFilterWarehouseFilter>>;
  or?: Maybe<Array<ManifestFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ManifestInput = {
  warehouseId: Scalars['String'];
  messengerId?: Maybe<Scalars['String']>;
  messengerName?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  authorizedByName?: Maybe<Scalars['String']>;
  executedById: Scalars['String'];
  executedByName: Scalars['String'];
  packages?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ManifestMaxAggregate = {
  __typename?: 'ManifestMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  messengerId?: Maybe<Scalars['String']>;
  messengerName?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  authorizedByName?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  executedByName?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ManifestMinAggregate = {
  __typename?: 'ManifestMinAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  messengerId?: Maybe<Scalars['String']>;
  messengerName?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  authorizedByName?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  executedByName?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ManifestSort = {
  field: ManifestSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ManifestSortFields {
  Id = 'id',
  WarehouseId = 'warehouseId',
  MessengerId = 'messengerId',
  MessengerName = 'messengerName',
  AuthorizedById = 'authorizedById',
  AuthorizedByName = 'authorizedByName',
  ExecutedById = 'executedById',
  ExecutedByName = 'executedByName',
  Packages = 'packages',
  Comments = 'comments',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ManifestUpdate = {
  warehouseId: Scalars['String'];
  messengerId?: Maybe<Scalars['String']>;
  messengerName?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  authorizedByName?: Maybe<Scalars['String']>;
  executedById: Scalars['String'];
  executedByName: Scalars['String'];
  packages?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ManifestUpdateFilter = {
  and?: Maybe<Array<ManifestUpdateFilter>>;
  or?: Maybe<Array<ManifestUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  messengerId?: Maybe<StringFieldComparison>;
  messengerName?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  authorizedByName?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  executedByName?: Maybe<StringFieldComparison>;
  packages?: Maybe<JsonFilterComparison>;
  comments?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addWarehousesToWarehouseType: WarehouseType;
  removeWarehousesFromWarehouseType: WarehouseType;
  createOneWarehouseType: WarehouseType;
  createManyWarehouseTypes: Array<WarehouseType>;
  updateOneWarehouseType: WarehouseType;
  updateManyWarehouseTypes: UpdateManyResponse;
  deleteOneWarehouseType?: Maybe<DeleteOneResponse>;
  deleteManyWarehouseTypes?: Maybe<DeleteManyResponse>;
  restoreOneWarehouseType?: Maybe<WarehouseType>;
  restoreManyWarehouseTypes?: Maybe<UpdateManyResponse>;
  setWarehouseTypeOnWarehouse: Warehouse;
  setAddressOnWarehouse: Warehouse;
  setContactOnWarehouse: Warehouse;
  addZonesToWarehouse: Warehouse;
  addStocksToWarehouse: Warehouse;
  addDestinationStocksToWarehouse: Warehouse;
  addOriginStocksToWarehouse: Warehouse;
  addUsersToWarehouse: Warehouse;
  removeWarehouseTypeFromWarehouse: Warehouse;
  removeAddressFromWarehouse: Warehouse;
  removeContactFromWarehouse: Warehouse;
  removeZonesFromWarehouse: Warehouse;
  removeStocksFromWarehouse: Warehouse;
  removeDestinationStocksFromWarehouse: Warehouse;
  removeOriginStocksFromWarehouse: Warehouse;
  removeUsersFromWarehouse: Warehouse;
  createOneWarehouse: Warehouse;
  createManyWarehouses: Array<Warehouse>;
  updateOneWarehouse: Warehouse;
  updateManyWarehouses: UpdateManyResponse;
  deleteOneWarehouse?: Maybe<DeleteOneResponse>;
  deleteManyWarehouses?: Maybe<DeleteManyResponse>;
  restoreOneWarehouse?: Maybe<Warehouse>;
  restoreManyWarehouses?: Maybe<UpdateManyResponse>;
  setZoneTypeOnZone: Zone;
  setWarehouseOnZone: Zone;
  setParentZoneOnZone: Zone;
  addForbiddenZonesToZone: Zone;
  addAsForbiddenZonesToZone: Zone;
  addForbiddenActionTypesToZone: Zone;
  addStocksToZone: Zone;
  addSubZonesToZone: Zone;
  removeZoneTypeFromZone: Zone;
  removeWarehouseFromZone: Zone;
  removeParentZoneFromZone: Zone;
  removeForbiddenZonesFromZone: Zone;
  removeAsForbiddenZonesFromZone: Zone;
  removeForbiddenActionTypesFromZone: Zone;
  removeStocksFromZone: Zone;
  removeSubZonesFromZone: Zone;
  createOneZone: Zone;
  createManyZones: Array<Zone>;
  updateOneZone: Zone;
  updateManyZones: UpdateManyResponse;
  deleteOneZone?: Maybe<DeleteOneResponse>;
  deleteManyZones?: Maybe<DeleteManyResponse>;
  restoreOneZone?: Maybe<Zone>;
  restoreManyZones?: Maybe<UpdateManyResponse>;
  setWarehouseOnManifest: Manifest;
  removeWarehouseFromManifest: Manifest;
  createOneManifest: Manifest;
  createManyManifests: Array<Manifest>;
  updateOneManifest: Manifest;
  updateManyManifests: UpdateManyResponse;
  deleteOneManifest?: Maybe<DeleteOneResponse>;
  deleteManyManifests?: Maybe<DeleteManyResponse>;
  restoreOneManifest?: Maybe<Manifest>;
  restoreManyManifests?: Maybe<UpdateManyResponse>;
  setWarehouseOnUserWarehouse: UserWarehouse;
  removeWarehouseFromUserWarehouse: UserWarehouse;
  createOneUserWarehouse: UserWarehouse;
  createManyUserWarehouses: Array<UserWarehouse>;
  updateOneUserWarehouse: UserWarehouse;
  updateManyUserWarehouses: UpdateManyResponse;
  deleteOneUserWarehouse?: Maybe<DeleteOneResponse>;
  deleteManyUserWarehouses?: Maybe<DeleteManyResponse>;
  restoreOneUserWarehouse?: Maybe<UserWarehouse>;
  restoreManyUserWarehouses?: Maybe<UpdateManyResponse>;
  setWarehouseOnPrinter: Printer;
  removeWarehouseFromPrinter: Printer;
  createOnePrinter: Printer;
  createManyPrinters: Array<Printer>;
  updateOnePrinter: Printer;
  updateManyPrinters: UpdateManyResponse;
  deleteOnePrinter?: Maybe<DeleteOneResponse>;
  deleteManyPrinters?: Maybe<DeleteManyResponse>;
  restoreOnePrinter?: Maybe<Printer>;
  restoreManyPrinters?: Maybe<UpdateManyResponse>;
  setWarehouseOnPrintGateway: PrintGateway;
  removeWarehouseFromPrintGateway: PrintGateway;
  createOnePrintGateway: PrintGateway;
  createManyPrintGateways: Array<PrintGateway>;
  updateOnePrintGateway: PrintGateway;
  updateManyPrintGateways: UpdateManyResponse;
  deleteOnePrintGateway?: Maybe<DeleteOneResponse>;
  deleteManyPrintGateways?: Maybe<DeleteManyResponse>;
  restoreOnePrintGateway?: Maybe<PrintGateway>;
  restoreManyPrintGateways?: Maybe<UpdateManyResponse>;
  setZoneOnZoneBlacklist: ZoneBlacklist;
  setForbiddenZoneOnZoneBlacklist: ZoneBlacklist;
  removeZoneFromZoneBlacklist: ZoneBlacklist;
  removeForbiddenZoneFromZoneBlacklist: ZoneBlacklist;
  createOneZoneBlacklist: ZoneBlacklist;
  createManyZoneBlacklists: Array<ZoneBlacklist>;
  updateOneZoneBlacklist: ZoneBlacklist;
  updateManyZoneBlacklists: UpdateManyResponse;
  deleteOneZoneBlacklist?: Maybe<DeleteOneResponse>;
  deleteManyZoneBlacklists?: Maybe<DeleteManyResponse>;
  restoreOneZoneBlacklist?: Maybe<ZoneBlacklist>;
  restoreManyZoneBlacklists?: Maybe<UpdateManyResponse>;
  setZoneOnZoneActionTypeBlacklist: ZoneActionTypeBlacklist;
  setActionTypeOnZoneActionTypeBlacklist: ZoneActionTypeBlacklist;
  removeZoneFromZoneActionTypeBlacklist: ZoneActionTypeBlacklist;
  removeActionTypeFromZoneActionTypeBlacklist: ZoneActionTypeBlacklist;
  createOneZoneActionTypeBlacklist: ZoneActionTypeBlacklist;
  createManyZoneActionTypeBlacklists: Array<ZoneActionTypeBlacklist>;
  updateOneZoneActionTypeBlacklist: ZoneActionTypeBlacklist;
  updateManyZoneActionTypeBlacklists: UpdateManyResponse;
  deleteOneZoneActionTypeBlacklist?: Maybe<DeleteOneResponse>;
  deleteManyZoneActionTypeBlacklists?: Maybe<DeleteManyResponse>;
  restoreOneZoneActionTypeBlacklist?: Maybe<ZoneActionTypeBlacklist>;
  restoreManyZoneActionTypeBlacklists?: Maybe<UpdateManyResponse>;
  setZoneOnStock: Stock;
  setSubZoneOnStock: Stock;
  setWarehouseOnStock: Stock;
  setOriginWarehouseOnStock: Stock;
  setDestinationWarehouseOnStock: Stock;
  setActionTypeOnStock: Stock;
  removeZoneFromStock: Stock;
  removeSubZoneFromStock: Stock;
  removeWarehouseFromStock: Stock;
  removeOriginWarehouseFromStock: Stock;
  removeDestinationWarehouseFromStock: Stock;
  removeActionTypeFromStock: Stock;
  createOneStock: Stock;
  createManyStocks: Array<Stock>;
  updateOneStock: Stock;
  updateManyStocks: UpdateManyResponse;
  upsertOneStock?: Maybe<Stock>;
  deleteOneStock?: Maybe<DeleteOneResponse>;
  deleteManyStocks?: Maybe<DeleteManyResponse>;
  restoreOneStock?: Maybe<Stock>;
  restoreManyStocks?: Maybe<UpdateManyResponse>;
  addWarehousesToAddress: Address;
  removeWarehousesFromAddress: Address;
  createOneAddress: Address;
  createManyAddresses: Array<Address>;
  updateOneAddress: Address;
  updateManyAddresses: UpdateManyResponse;
  deleteOneAddress?: Maybe<DeleteOneResponse>;
  deleteManyAddresses?: Maybe<DeleteManyResponse>;
  restoreOneAddress?: Maybe<Address>;
  restoreManyAddresses?: Maybe<UpdateManyResponse>;
  addWarehousesToContact: Contact;
  removeWarehousesFromContact: Contact;
  createOneContact: Contact;
  createManyContacts: Array<Contact>;
  updateOneContact: Contact;
  updateManyContacts: UpdateManyResponse;
  deleteOneContact?: Maybe<DeleteOneResponse>;
  deleteManyContacts?: Maybe<DeleteManyResponse>;
  restoreOneContact?: Maybe<Contact>;
  restoreManyContacts?: Maybe<UpdateManyResponse>;
  addZonesToZoneType: ZoneType;
  removeZonesFromZoneType: ZoneType;
  createOneZoneType: ZoneType;
  createManyZoneTypes: Array<ZoneType>;
  updateOneZoneType: ZoneType;
  updateManyZoneTypes: UpdateManyResponse;
  deleteOneZoneType?: Maybe<DeleteOneResponse>;
  deleteManyZoneTypes?: Maybe<DeleteManyResponse>;
  restoreOneZoneType?: Maybe<ZoneType>;
  restoreManyZoneTypes?: Maybe<UpdateManyResponse>;
  setAlertTypeOnActionType: ActionType;
  setActionTypeCategoryOnActionType: ActionType;
  addForbiddenZonesToActionType: ActionType;
  addStocksToActionType: ActionType;
  removeAlertTypeFromActionType: ActionType;
  removeActionTypeCategoryFromActionType: ActionType;
  removeForbiddenZonesFromActionType: ActionType;
  removeStocksFromActionType: ActionType;
  createOneActionType: ActionType;
  createManyActionTypes: Array<ActionType>;
  updateOneActionType: ActionType;
  updateManyActionTypes: UpdateManyResponse;
  deleteOneActionType?: Maybe<DeleteOneResponse>;
  deleteManyActionTypes?: Maybe<DeleteManyResponse>;
  restoreOneActionType?: Maybe<ActionType>;
  restoreManyActionTypes?: Maybe<UpdateManyResponse>;
  addActionTypesToAlertType: AlertType;
  removeActionTypesFromAlertType: AlertType;
  createOneAlertType: AlertType;
  createManyAlertTypes: Array<AlertType>;
  updateOneAlertType: AlertType;
  updateManyAlertTypes: UpdateManyResponse;
  deleteOneAlertType?: Maybe<DeleteOneResponse>;
  deleteManyAlertTypes?: Maybe<DeleteManyResponse>;
  restoreOneAlertType?: Maybe<AlertType>;
  restoreManyAlertTypes?: Maybe<UpdateManyResponse>;
  createOneAlertStatus: AlertStatus;
  createManyAlertStatuses: Array<AlertStatus>;
  updateOneAlertStatus: AlertStatus;
  updateManyAlertStatuses: UpdateManyResponse;
  deleteOneAlertStatus?: Maybe<DeleteOneResponse>;
  deleteManyAlertStatuses?: Maybe<DeleteManyResponse>;
  restoreOneAlertStatus?: Maybe<AlertStatus>;
  restoreManyAlertStatuses?: Maybe<UpdateManyResponse>;
  setActionOnAlert: Alert;
  createOneAlert: Alert;
  createManyAlerts: Array<Alert>;
  updateOneAlert: Alert;
  updateManyAlerts: UpdateManyResponse;
  addActionsToActionAttachment: ActionAttachment;
  createOneActionAttachment: ActionAttachment;
  createManyActionAttachments: Array<ActionAttachment>;
  updateOneActionAttachment: ActionAttachment;
  updateManyActionAttachments: UpdateManyResponse;
  createOneAction?: Maybe<Action>;
  createManyActions?: Maybe<CreateManyActionsResponse>;
  setEvidenceOnAction: Action;
  addAlertsToAction: Action;
  updateOneAction: Action;
  updateManyActions: UpdateManyResponse;
  addActionTypesToActionTypeCategory: ActionTypeCategory;
  removeActionTypesFromActionTypeCategory: ActionTypeCategory;
  createOneActionTypeCategory: ActionTypeCategory;
  createManyActionTypeCategories: Array<ActionTypeCategory>;
  updateOneActionTypeCategory: ActionTypeCategory;
  updateManyActionTypeCategories: UpdateManyResponse;
  deleteOneActionTypeCategory?: Maybe<DeleteOneResponse>;
  deleteManyActionTypeCategories?: Maybe<DeleteManyResponse>;
  restoreOneActionTypeCategory?: Maybe<ActionTypeCategory>;
  restoreManyActionTypeCategories?: Maybe<UpdateManyResponse>;
};


export type MutationAddWarehousesToWarehouseTypeArgs = {
  input: RelationsInput;
};


export type MutationRemoveWarehousesFromWarehouseTypeArgs = {
  input: RelationsInput;
};


export type MutationCreateOneWarehouseTypeArgs = {
  input: CreateOneWarehouseTypeInput;
};


export type MutationCreateManyWarehouseTypesArgs = {
  input: CreateManyWarehouseTypesInput;
};


export type MutationUpdateOneWarehouseTypeArgs = {
  input: UpdateOneWarehouseTypeInput;
};


export type MutationUpdateManyWarehouseTypesArgs = {
  input: UpdateManyWarehouseTypesInput;
};


export type MutationDeleteOneWarehouseTypeArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyWarehouseTypesArgs = {
  input: DeleteManyWarehouseTypesInput;
};


export type MutationRestoreOneWarehouseTypeArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyWarehouseTypesArgs = {
  input: WarehouseTypeFilter;
};


export type MutationSetWarehouseTypeOnWarehouseArgs = {
  input: RelationInput;
};


export type MutationSetAddressOnWarehouseArgs = {
  input: RelationInput;
};


export type MutationSetContactOnWarehouseArgs = {
  input: RelationInput;
};


export type MutationAddZonesToWarehouseArgs = {
  input: RelationsInput;
};


export type MutationAddStocksToWarehouseArgs = {
  input: RelationsInput;
};


export type MutationAddDestinationStocksToWarehouseArgs = {
  input: RelationsInput;
};


export type MutationAddOriginStocksToWarehouseArgs = {
  input: RelationsInput;
};


export type MutationAddUsersToWarehouseArgs = {
  input: RelationsInput;
};


export type MutationRemoveWarehouseTypeFromWarehouseArgs = {
  input: RelationInput;
};


export type MutationRemoveAddressFromWarehouseArgs = {
  input: RelationInput;
};


export type MutationRemoveContactFromWarehouseArgs = {
  input: RelationInput;
};


export type MutationRemoveZonesFromWarehouseArgs = {
  input: RelationsInput;
};


export type MutationRemoveStocksFromWarehouseArgs = {
  input: RelationsInput;
};


export type MutationRemoveDestinationStocksFromWarehouseArgs = {
  input: RelationsInput;
};


export type MutationRemoveOriginStocksFromWarehouseArgs = {
  input: RelationsInput;
};


export type MutationRemoveUsersFromWarehouseArgs = {
  input: RelationsInput;
};


export type MutationCreateOneWarehouseArgs = {
  input: CreateOneWarehouseInput;
};


export type MutationCreateManyWarehousesArgs = {
  input: CreateManyWarehousesInput;
};


export type MutationUpdateOneWarehouseArgs = {
  input: UpdateOneWarehouseInput;
};


export type MutationUpdateManyWarehousesArgs = {
  input: UpdateManyWarehousesInput;
};


export type MutationDeleteOneWarehouseArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyWarehousesArgs = {
  input: DeleteManyWarehousesInput;
};


export type MutationRestoreOneWarehouseArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyWarehousesArgs = {
  input: WarehouseFilter;
};


export type MutationSetZoneTypeOnZoneArgs = {
  input: RelationInput;
};


export type MutationSetWarehouseOnZoneArgs = {
  input: RelationInput;
};


export type MutationSetParentZoneOnZoneArgs = {
  input: RelationInput;
};


export type MutationAddForbiddenZonesToZoneArgs = {
  input: RelationsInput;
};


export type MutationAddAsForbiddenZonesToZoneArgs = {
  input: RelationsInput;
};


export type MutationAddForbiddenActionTypesToZoneArgs = {
  input: RelationsInput;
};


export type MutationAddStocksToZoneArgs = {
  input: RelationsInput;
};


export type MutationAddSubZonesToZoneArgs = {
  input: RelationsInput;
};


export type MutationRemoveZoneTypeFromZoneArgs = {
  input: RelationInput;
};


export type MutationRemoveWarehouseFromZoneArgs = {
  input: RelationInput;
};


export type MutationRemoveParentZoneFromZoneArgs = {
  input: RelationInput;
};


export type MutationRemoveForbiddenZonesFromZoneArgs = {
  input: RelationsInput;
};


export type MutationRemoveAsForbiddenZonesFromZoneArgs = {
  input: RelationsInput;
};


export type MutationRemoveForbiddenActionTypesFromZoneArgs = {
  input: RelationsInput;
};


export type MutationRemoveStocksFromZoneArgs = {
  input: RelationsInput;
};


export type MutationRemoveSubZonesFromZoneArgs = {
  input: RelationsInput;
};


export type MutationCreateOneZoneArgs = {
  input: CreateOneZoneInput;
};


export type MutationCreateManyZonesArgs = {
  input: CreateManyZonesInput;
};


export type MutationUpdateOneZoneArgs = {
  input: UpdateOneZoneInput;
};


export type MutationUpdateManyZonesArgs = {
  input: UpdateManyZonesInput;
};


export type MutationDeleteOneZoneArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyZonesArgs = {
  input: DeleteManyZonesInput;
};


export type MutationRestoreOneZoneArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyZonesArgs = {
  input: ZoneFilter;
};


export type MutationSetWarehouseOnManifestArgs = {
  input: RelationInput;
};


export type MutationRemoveWarehouseFromManifestArgs = {
  input: RelationInput;
};


export type MutationCreateOneManifestArgs = {
  input: CreateOneManifestInput;
};


export type MutationCreateManyManifestsArgs = {
  input: CreateManyManifestsInput;
};


export type MutationUpdateOneManifestArgs = {
  input: UpdateOneManifestInput;
};


export type MutationUpdateManyManifestsArgs = {
  input: UpdateManyManifestsInput;
};


export type MutationDeleteOneManifestArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyManifestsArgs = {
  input: DeleteManyManifestsInput;
};


export type MutationRestoreOneManifestArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyManifestsArgs = {
  input: ManifestFilter;
};


export type MutationSetWarehouseOnUserWarehouseArgs = {
  input: RelationInput;
};


export type MutationRemoveWarehouseFromUserWarehouseArgs = {
  input: RelationInput;
};


export type MutationCreateOneUserWarehouseArgs = {
  input: CreateOneUserWarehouseInput;
};


export type MutationCreateManyUserWarehousesArgs = {
  input: CreateManyUserWarehousesInput;
};


export type MutationUpdateOneUserWarehouseArgs = {
  input: UpdateOneUserWarehouseInput;
};


export type MutationUpdateManyUserWarehousesArgs = {
  input: UpdateManyUserWarehousesInput;
};


export type MutationDeleteOneUserWarehouseArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyUserWarehousesArgs = {
  input: DeleteManyUserWarehousesInput;
};


export type MutationRestoreOneUserWarehouseArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyUserWarehousesArgs = {
  input: UserWarehouseFilter;
};


export type MutationSetWarehouseOnPrinterArgs = {
  input: RelationInput;
};


export type MutationRemoveWarehouseFromPrinterArgs = {
  input: RelationInput;
};


export type MutationCreateOnePrinterArgs = {
  input: CreateOnePrinterInput;
};


export type MutationCreateManyPrintersArgs = {
  input: CreateManyPrintersInput;
};


export type MutationUpdateOnePrinterArgs = {
  input: UpdateOnePrinterInput;
};


export type MutationUpdateManyPrintersArgs = {
  input: UpdateManyPrintersInput;
};


export type MutationDeleteOnePrinterArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyPrintersArgs = {
  input: DeleteManyPrintersInput;
};


export type MutationRestoreOnePrinterArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyPrintersArgs = {
  input: PrinterFilter;
};


export type MutationSetWarehouseOnPrintGatewayArgs = {
  input: RelationInput;
};


export type MutationRemoveWarehouseFromPrintGatewayArgs = {
  input: RelationInput;
};


export type MutationCreateOnePrintGatewayArgs = {
  input: CreateOnePrintGatewayInput;
};


export type MutationCreateManyPrintGatewaysArgs = {
  input: CreateManyPrintGatewaysInput;
};


export type MutationUpdateOnePrintGatewayArgs = {
  input: UpdateOnePrintGatewayInput;
};


export type MutationUpdateManyPrintGatewaysArgs = {
  input: UpdateManyPrintGatewaysInput;
};


export type MutationDeleteOnePrintGatewayArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyPrintGatewaysArgs = {
  input: DeleteManyPrintGatewaysInput;
};


export type MutationRestoreOnePrintGatewayArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyPrintGatewaysArgs = {
  input: PrintGatewayFilter;
};


export type MutationSetZoneOnZoneBlacklistArgs = {
  input: RelationInput;
};


export type MutationSetForbiddenZoneOnZoneBlacklistArgs = {
  input: RelationInput;
};


export type MutationRemoveZoneFromZoneBlacklistArgs = {
  input: RelationInput;
};


export type MutationRemoveForbiddenZoneFromZoneBlacklistArgs = {
  input: RelationInput;
};


export type MutationCreateOneZoneBlacklistArgs = {
  input: CreateOneZoneBlacklistInput;
};


export type MutationCreateManyZoneBlacklistsArgs = {
  input: CreateManyZoneBlacklistsInput;
};


export type MutationUpdateOneZoneBlacklistArgs = {
  input: UpdateOneZoneBlacklistInput;
};


export type MutationUpdateManyZoneBlacklistsArgs = {
  input: UpdateManyZoneBlacklistsInput;
};


export type MutationDeleteOneZoneBlacklistArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyZoneBlacklistsArgs = {
  input: DeleteManyZoneBlacklistsInput;
};


export type MutationRestoreOneZoneBlacklistArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyZoneBlacklistsArgs = {
  input: ZoneBlacklistFilter;
};


export type MutationSetZoneOnZoneActionTypeBlacklistArgs = {
  input: RelationInput;
};


export type MutationSetActionTypeOnZoneActionTypeBlacklistArgs = {
  input: RelationInput;
};


export type MutationRemoveZoneFromZoneActionTypeBlacklistArgs = {
  input: RelationInput;
};


export type MutationRemoveActionTypeFromZoneActionTypeBlacklistArgs = {
  input: RelationInput;
};


export type MutationCreateOneZoneActionTypeBlacklistArgs = {
  input: CreateOneZoneActionTypeBlacklistInput;
};


export type MutationCreateManyZoneActionTypeBlacklistsArgs = {
  input: CreateManyZoneActionTypeBlacklistsInput;
};


export type MutationUpdateOneZoneActionTypeBlacklistArgs = {
  input: UpdateOneZoneActionTypeBlacklistInput;
};


export type MutationUpdateManyZoneActionTypeBlacklistsArgs = {
  input: UpdateManyZoneActionTypeBlacklistsInput;
};


export type MutationDeleteOneZoneActionTypeBlacklistArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyZoneActionTypeBlacklistsArgs = {
  input: DeleteManyZoneActionTypeBlacklistsInput;
};


export type MutationRestoreOneZoneActionTypeBlacklistArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyZoneActionTypeBlacklistsArgs = {
  input: ZoneActionTypeBlacklistFilter;
};


export type MutationSetZoneOnStockArgs = {
  input: RelationInput;
};


export type MutationSetSubZoneOnStockArgs = {
  input: RelationInput;
};


export type MutationSetWarehouseOnStockArgs = {
  input: RelationInput;
};


export type MutationSetOriginWarehouseOnStockArgs = {
  input: RelationInput;
};


export type MutationSetDestinationWarehouseOnStockArgs = {
  input: RelationInput;
};


export type MutationSetActionTypeOnStockArgs = {
  input: RelationInput;
};


export type MutationRemoveZoneFromStockArgs = {
  input: RelationInput;
};


export type MutationRemoveSubZoneFromStockArgs = {
  input: RelationInput;
};


export type MutationRemoveWarehouseFromStockArgs = {
  input: RelationInput;
};


export type MutationRemoveOriginWarehouseFromStockArgs = {
  input: RelationInput;
};


export type MutationRemoveDestinationWarehouseFromStockArgs = {
  input: RelationInput;
};


export type MutationRemoveActionTypeFromStockArgs = {
  input: RelationInput;
};


export type MutationCreateOneStockArgs = {
  input: CreateOneStockInput;
};


export type MutationCreateManyStocksArgs = {
  input: CreateManyStocksInput;
};


export type MutationUpdateOneStockArgs = {
  input: UpdateOneStockInput;
};


export type MutationUpdateManyStocksArgs = {
  input: UpdateManyStocksInput;
};


export type MutationUpsertOneStockArgs = {
  input: StockUpdate;
};


export type MutationDeleteOneStockArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyStocksArgs = {
  input: DeleteManyStocksInput;
};


export type MutationRestoreOneStockArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyStocksArgs = {
  input: StockFilter;
};


export type MutationAddWarehousesToAddressArgs = {
  input: RelationsInput;
};


export type MutationRemoveWarehousesFromAddressArgs = {
  input: RelationsInput;
};


export type MutationCreateOneAddressArgs = {
  input: CreateOneAddressInput;
};


export type MutationCreateManyAddressesArgs = {
  input: CreateManyAddressesInput;
};


export type MutationUpdateOneAddressArgs = {
  input: UpdateOneAddressInput;
};


export type MutationUpdateManyAddressesArgs = {
  input: UpdateManyAddressesInput;
};


export type MutationDeleteOneAddressArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyAddressesArgs = {
  input: DeleteManyAddressesInput;
};


export type MutationRestoreOneAddressArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyAddressesArgs = {
  input: AddressFilter;
};


export type MutationAddWarehousesToContactArgs = {
  input: RelationsInput;
};


export type MutationRemoveWarehousesFromContactArgs = {
  input: RelationsInput;
};


export type MutationCreateOneContactArgs = {
  input: CreateOneContactInput;
};


export type MutationCreateManyContactsArgs = {
  input: CreateManyContactsInput;
};


export type MutationUpdateOneContactArgs = {
  input: UpdateOneContactInput;
};


export type MutationUpdateManyContactsArgs = {
  input: UpdateManyContactsInput;
};


export type MutationDeleteOneContactArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyContactsArgs = {
  input: DeleteManyContactsInput;
};


export type MutationRestoreOneContactArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyContactsArgs = {
  input: ContactFilter;
};


export type MutationAddZonesToZoneTypeArgs = {
  input: RelationsInput;
};


export type MutationRemoveZonesFromZoneTypeArgs = {
  input: RelationsInput;
};


export type MutationCreateOneZoneTypeArgs = {
  input: CreateOneZoneTypeInput;
};


export type MutationCreateManyZoneTypesArgs = {
  input: CreateManyZoneTypesInput;
};


export type MutationUpdateOneZoneTypeArgs = {
  input: UpdateOneZoneTypeInput;
};


export type MutationUpdateManyZoneTypesArgs = {
  input: UpdateManyZoneTypesInput;
};


export type MutationDeleteOneZoneTypeArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyZoneTypesArgs = {
  input: DeleteManyZoneTypesInput;
};


export type MutationRestoreOneZoneTypeArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyZoneTypesArgs = {
  input: ZoneTypeFilter;
};


export type MutationSetAlertTypeOnActionTypeArgs = {
  input: RelationInput;
};


export type MutationSetActionTypeCategoryOnActionTypeArgs = {
  input: RelationInput;
};


export type MutationAddForbiddenZonesToActionTypeArgs = {
  input: RelationsInput;
};


export type MutationAddStocksToActionTypeArgs = {
  input: RelationsInput;
};


export type MutationRemoveAlertTypeFromActionTypeArgs = {
  input: RelationInput;
};


export type MutationRemoveActionTypeCategoryFromActionTypeArgs = {
  input: RelationInput;
};


export type MutationRemoveForbiddenZonesFromActionTypeArgs = {
  input: RelationsInput;
};


export type MutationRemoveStocksFromActionTypeArgs = {
  input: RelationsInput;
};


export type MutationCreateOneActionTypeArgs = {
  input: CreateOneActionTypeInput;
};


export type MutationCreateManyActionTypesArgs = {
  input: CreateManyActionTypesInput;
};


export type MutationUpdateOneActionTypeArgs = {
  input: UpdateOneActionTypeInput;
};


export type MutationUpdateManyActionTypesArgs = {
  input: UpdateManyActionTypesInput;
};


export type MutationDeleteOneActionTypeArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyActionTypesArgs = {
  input: DeleteManyActionTypesInput;
};


export type MutationRestoreOneActionTypeArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyActionTypesArgs = {
  input: ActionTypeFilter;
};


export type MutationAddActionTypesToAlertTypeArgs = {
  input: RelationsInput;
};


export type MutationRemoveActionTypesFromAlertTypeArgs = {
  input: RelationsInput;
};


export type MutationCreateOneAlertTypeArgs = {
  input: CreateOneAlertTypeInput;
};


export type MutationCreateManyAlertTypesArgs = {
  input: CreateManyAlertTypesInput;
};


export type MutationUpdateOneAlertTypeArgs = {
  input: UpdateOneAlertTypeInput;
};


export type MutationUpdateManyAlertTypesArgs = {
  input: UpdateManyAlertTypesInput;
};


export type MutationDeleteOneAlertTypeArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyAlertTypesArgs = {
  input: DeleteManyAlertTypesInput;
};


export type MutationRestoreOneAlertTypeArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyAlertTypesArgs = {
  input: AlertTypeFilter;
};


export type MutationCreateOneAlertStatusArgs = {
  input: CreateOneAlertStatusInput;
};


export type MutationCreateManyAlertStatusesArgs = {
  input: CreateManyAlertStatusesInput;
};


export type MutationUpdateOneAlertStatusArgs = {
  input: UpdateOneAlertStatusInput;
};


export type MutationUpdateManyAlertStatusesArgs = {
  input: UpdateManyAlertStatusesInput;
};


export type MutationDeleteOneAlertStatusArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyAlertStatusesArgs = {
  input: DeleteManyAlertStatusesInput;
};


export type MutationRestoreOneAlertStatusArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyAlertStatusesArgs = {
  input: AlertStatusFilter;
};


export type MutationSetActionOnAlertArgs = {
  input: RelationInput;
};


export type MutationCreateOneAlertArgs = {
  input: CreateOneAlertInput;
};


export type MutationCreateManyAlertsArgs = {
  input: CreateManyAlertsInput;
};


export type MutationUpdateOneAlertArgs = {
  input: UpdateOneAlertInput;
};


export type MutationUpdateManyAlertsArgs = {
  input: UpdateManyAlertsInput;
};


export type MutationAddActionsToActionAttachmentArgs = {
  input: RelationsInput;
};


export type MutationCreateOneActionAttachmentArgs = {
  input: CreateOneActionAttachmentInput;
};


export type MutationCreateManyActionAttachmentsArgs = {
  input: CreateManyActionAttachmentsInput;
};


export type MutationUpdateOneActionAttachmentArgs = {
  input: UpdateOneActionAttachmentInput;
};


export type MutationUpdateManyActionAttachmentsArgs = {
  input: UpdateManyActionAttachmentsInput;
};


export type MutationCreateOneActionArgs = {
  authToken: Scalars['String'];
  input: CreateOneActionInput;
};


export type MutationCreateManyActionsArgs = {
  authToken: Scalars['String'];
  input: CreateManyActionsInput;
};


export type MutationSetEvidenceOnActionArgs = {
  input: RelationInput;
};


export type MutationAddAlertsToActionArgs = {
  input: RelationsInput;
};


export type MutationUpdateOneActionArgs = {
  input: UpdateOneActionInput;
};


export type MutationUpdateManyActionsArgs = {
  input: UpdateManyActionsInput;
};


export type MutationAddActionTypesToActionTypeCategoryArgs = {
  input: RelationsInput;
};


export type MutationRemoveActionTypesFromActionTypeCategoryArgs = {
  input: RelationsInput;
};


export type MutationCreateOneActionTypeCategoryArgs = {
  input: CreateOneActionTypeCategoryInput;
};


export type MutationCreateManyActionTypeCategoriesArgs = {
  input: CreateManyActionTypeCategoriesInput;
};


export type MutationUpdateOneActionTypeCategoryArgs = {
  input: UpdateOneActionTypeCategoryInput;
};


export type MutationUpdateManyActionTypeCategoriesArgs = {
  input: UpdateManyActionTypeCategoriesInput;
};


export type MutationDeleteOneActionTypeCategoryArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyActionTypeCategoriesArgs = {
  input: DeleteManyActionTypeCategoriesInput;
};


export type MutationRestoreOneActionTypeCategoryArgs = {
  input: DeleteOneInput;
};


export type MutationRestoreManyActionTypeCategoriesArgs = {
  input: ActionTypeCategoryFilter;
};

export type NumberFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  between?: Maybe<NumberFieldComparisonBetween>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: Maybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: Maybe<Scalars['Int']>;
};

export type Package = {
  __typename?: 'Package';
  id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  deliveryId?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  sizeKey?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  volumetricWeight?: Maybe<Scalars['Float']>;
  packageStatusKey?: Maybe<Scalars['Float']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  zoneHub?: Maybe<Scalars['String']>;
  zoneCoverage?: Maybe<Scalars['String']>;
  deliveryStatusId?: Maybe<Scalars['String']>;
  deliveryStatusName?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  routeStatusId?: Maybe<Scalars['String']>;
  routeStatusName?: Maybe<Scalars['String']>;
  routeSequence?: Maybe<Scalars['String']>;
  multiguideSequence?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  cancelationReasonId?: Maybe<Scalars['String']>;
  cancelationReasonName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PackageInput = {
  internalId: Scalars['String'];
  externalId: Scalars['String'];
  deliveryId?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  deliveryStatusId?: Maybe<Scalars['String']>;
  deliveryStatusName?: Maybe<Scalars['String']>;
  routeStatusId?: Maybe<Scalars['String']>;
  routeStatusName?: Maybe<Scalars['String']>;
  routeSequence?: Maybe<Scalars['String']>;
  multiguideSequence?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  volumetricWeight?: Maybe<Scalars['Float']>;
  packageStatusKey?: Maybe<Scalars['Float']>;
};

export type PrintGateway = {
  __typename?: 'PrintGateway';
  id: Scalars['ID'];
  name: Scalars['String'];
  deviceName: Scalars['String'];
  status: Scalars['Boolean'];
  warehouseId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  warehouse?: Maybe<Warehouse>;
};

export type PrintGatewayAggregateFilter = {
  and?: Maybe<Array<PrintGatewayAggregateFilter>>;
  or?: Maybe<Array<PrintGatewayAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  deviceName?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type PrintGatewayAggregateResponse = {
  __typename?: 'PrintGatewayAggregateResponse';
  count?: Maybe<PrintGatewayCountAggregate>;
  min?: Maybe<PrintGatewayMinAggregate>;
  max?: Maybe<PrintGatewayMaxAggregate>;
};

export type PrintGatewayConnection = {
  __typename?: 'PrintGatewayConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<PrintGateway>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PrintGatewayCountAggregate = {
  __typename?: 'PrintGatewayCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  deviceName?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type PrintGatewayDeleteFilter = {
  and?: Maybe<Array<PrintGatewayDeleteFilter>>;
  or?: Maybe<Array<PrintGatewayDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  deviceName?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type PrintGatewayFilter = {
  and?: Maybe<Array<PrintGatewayFilter>>;
  or?: Maybe<Array<PrintGatewayFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  deviceName?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  warehouse?: Maybe<PrintGatewayFilterWarehouseFilter>;
};

export type PrintGatewayFilterWarehouseFilter = {
  and?: Maybe<Array<PrintGatewayFilterWarehouseFilter>>;
  or?: Maybe<Array<PrintGatewayFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type PrintGatewayInput = {
  name: Scalars['String'];
  deviceName: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
  warehouseId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type PrintGatewayMaxAggregate = {
  __typename?: 'PrintGatewayMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PrintGatewayMinAggregate = {
  __typename?: 'PrintGatewayMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PrintGatewaySort = {
  field: PrintGatewaySortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PrintGatewaySortFields {
  Id = 'id',
  Name = 'name',
  DeviceName = 'deviceName',
  Status = 'status',
  WarehouseId = 'warehouseId',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type PrintGatewayUpdate = {
  name?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  warehouseId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PrintGatewayUpdateFilter = {
  and?: Maybe<Array<PrintGatewayUpdateFilter>>;
  or?: Maybe<Array<PrintGatewayUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  deviceName?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type Printer = {
  __typename?: 'Printer';
  id: Scalars['ID'];
  name: Scalars['String'];
  ip: Scalars['String'];
  status: Scalars['Boolean'];
  warehouseId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  warehouse?: Maybe<Warehouse>;
};

export type PrinterAggregateFilter = {
  and?: Maybe<Array<PrinterAggregateFilter>>;
  or?: Maybe<Array<PrinterAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  ip?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type PrinterAggregateResponse = {
  __typename?: 'PrinterAggregateResponse';
  count?: Maybe<PrinterCountAggregate>;
  min?: Maybe<PrinterMinAggregate>;
  max?: Maybe<PrinterMaxAggregate>;
};

export type PrinterConnection = {
  __typename?: 'PrinterConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Printer>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PrinterCountAggregate = {
  __typename?: 'PrinterCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type PrinterDeleteFilter = {
  and?: Maybe<Array<PrinterDeleteFilter>>;
  or?: Maybe<Array<PrinterDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  ip?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type PrinterFilter = {
  and?: Maybe<Array<PrinterFilter>>;
  or?: Maybe<Array<PrinterFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  ip?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  warehouse?: Maybe<PrinterFilterWarehouseFilter>;
};

export type PrinterFilterWarehouseFilter = {
  and?: Maybe<Array<PrinterFilterWarehouseFilter>>;
  or?: Maybe<Array<PrinterFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type PrinterInput = {
  name: Scalars['String'];
  ip: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
  warehouseId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type PrinterMaxAggregate = {
  __typename?: 'PrinterMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PrinterMinAggregate = {
  __typename?: 'PrinterMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PrinterSort = {
  field: PrinterSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PrinterSortFields {
  Id = 'id',
  Name = 'name',
  Ip = 'ip',
  Status = 'status',
  WarehouseId = 'warehouseId',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type PrinterUpdate = {
  name?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  warehouseId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PrinterUpdateFilter = {
  and?: Maybe<Array<PrinterUpdateFilter>>;
  or?: Maybe<Array<PrinterUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  ip?: Maybe<StringFieldComparison>;
  status?: Maybe<BooleanFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  warehouseType?: Maybe<WarehouseType>;
  warehouseTypes: WarehouseTypeConnection;
  warehouseTypeAggregate: WarehouseTypeAggregateResponse;
  warehouse?: Maybe<Warehouse>;
  warehouses: WarehouseConnection;
  warehouseAggregate: WarehouseAggregateResponse;
  zone?: Maybe<Zone>;
  zones: ZoneConnection;
  manifest?: Maybe<Manifest>;
  manifests: ManifestConnection;
  userWarehouse?: Maybe<UserWarehouse>;
  userWarehouses: UserWarehouseConnection;
  printer?: Maybe<Printer>;
  printers: PrinterConnection;
  printGateway?: Maybe<PrintGateway>;
  printGateways: PrintGatewayConnection;
  zoneAggregate: ZoneAggregateResponse;
  zoneBlacklist?: Maybe<ZoneBlacklist>;
  zoneBlacklists: ZoneBlacklistConnection;
  zoneActionTypeBlacklist?: Maybe<ZoneActionTypeBlacklist>;
  zoneActionTypeBlacklists: ZoneActionTypeBlacklistConnection;
  stock?: Maybe<Stock>;
  stocks: StockConnection;
  zoneBlacklistAggregate: ZoneBlacklistAggregateResponse;
  zoneActionTypeBlacklistAggregate: ZoneActionTypeBlacklistAggregateResponse;
  stockAggregate: StockAggregateResponse;
  manifestAggregate: ManifestAggregateResponse;
  userWarehouseAggregate: UserWarehouseAggregateResponse;
  printerAggregate: PrinterAggregateResponse;
  printGatewayAggregate: PrintGatewayAggregateResponse;
  address?: Maybe<Address>;
  addresses: AddressConnection;
  getWarehouseCities?: Maybe<Cities>;
  getWarehouseStates?: Maybe<States>;
  addressAggregate: AddressAggregateResponse;
  contact?: Maybe<Contact>;
  contacts: ContactConnection;
  contactAggregate: ContactAggregateResponse;
  zoneType?: Maybe<ZoneType>;
  zoneTypes: ZoneTypeConnection;
  zoneTypeAggregate: ZoneTypeAggregateResponse;
  actionType?: Maybe<ActionType>;
  actionTypes: ActionTypeConnection;
  actionTypeAggregate: ActionTypeAggregateResponse;
  alertType?: Maybe<AlertType>;
  alertTypes: AlertTypeConnection;
  alertTypeAggregate: AlertTypeAggregateResponse;
  alertStatus?: Maybe<AlertStatus>;
  alertStatuses: AlertStatusConnection;
  alertStatusAggregate: AlertStatusAggregateResponse;
  alertAggregate: AlertAggregateResponse;
  alert?: Maybe<Alert>;
  alerts: AlertConnection;
  actionAttachmentAggregate: ActionAttachmentAggregateResponse;
  actionAttachment?: Maybe<ActionAttachment>;
  actionAttachments: ActionAttachmentConnection;
  actionClients?: Maybe<ClientResponse>;
  actionAggregate: ActionAggregateResponse;
  action?: Maybe<Action>;
  actions: ActionConnection;
  getAuthorizationToken?: Maybe<AuthorizationToken>;
  verifyAuthorizationToken?: Maybe<VerifyAuthorizationToken>;
  package?: Maybe<Package>;
  actionTypeCategory?: Maybe<ActionTypeCategory>;
  actionTypeCategories: ActionTypeCategoryConnection;
  actionTypeCategoryAggregate: ActionTypeCategoryAggregateResponse;
};


export type QueryWarehouseTypeArgs = {
  id: Scalars['ID'];
};


export type QueryWarehouseTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<WarehouseTypeFilter>;
  sorting?: Maybe<Array<WarehouseTypeSort>>;
};


export type QueryWarehouseTypeAggregateArgs = {
  filter?: Maybe<WarehouseTypeAggregateFilter>;
};


export type QueryWarehouseArgs = {
  id: Scalars['ID'];
};


export type QueryWarehousesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<WarehouseFilter>;
  sorting?: Maybe<Array<WarehouseSort>>;
};


export type QueryWarehouseAggregateArgs = {
  filter?: Maybe<WarehouseAggregateFilter>;
};


export type QueryZoneArgs = {
  id: Scalars['ID'];
};


export type QueryZonesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneFilter>;
  sorting?: Maybe<Array<ZoneSort>>;
};


export type QueryManifestArgs = {
  id: Scalars['ID'];
};


export type QueryManifestsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ManifestFilter>;
  sorting?: Maybe<Array<ManifestSort>>;
};


export type QueryUserWarehouseArgs = {
  id: Scalars['ID'];
};


export type QueryUserWarehousesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<UserWarehouseFilter>;
  sorting?: Maybe<Array<UserWarehouseSort>>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID'];
};


export type QueryPrintersArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PrinterFilter>;
  sorting?: Maybe<Array<PrinterSort>>;
};


export type QueryPrintGatewayArgs = {
  id: Scalars['ID'];
};


export type QueryPrintGatewaysArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PrintGatewayFilter>;
  sorting?: Maybe<Array<PrintGatewaySort>>;
};


export type QueryZoneAggregateArgs = {
  filter?: Maybe<ZoneAggregateFilter>;
};


export type QueryZoneBlacklistArgs = {
  id: Scalars['ID'];
};


export type QueryZoneBlacklistsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneBlacklistFilter>;
  sorting?: Maybe<Array<ZoneBlacklistSort>>;
};


export type QueryZoneActionTypeBlacklistArgs = {
  id: Scalars['ID'];
};


export type QueryZoneActionTypeBlacklistsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneActionTypeBlacklistFilter>;
  sorting?: Maybe<Array<ZoneActionTypeBlacklistSort>>;
};


export type QueryStockArgs = {
  id: Scalars['ID'];
};


export type QueryStocksArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<StockFilter>;
  sorting?: Maybe<Array<StockSort>>;
};


export type QueryZoneBlacklistAggregateArgs = {
  filter?: Maybe<ZoneBlacklistAggregateFilter>;
};


export type QueryZoneActionTypeBlacklistAggregateArgs = {
  filter?: Maybe<ZoneActionTypeBlacklistAggregateFilter>;
};


export type QueryStockAggregateArgs = {
  filter?: Maybe<StockAggregateFilter>;
};


export type QueryManifestAggregateArgs = {
  filter?: Maybe<ManifestAggregateFilter>;
};


export type QueryUserWarehouseAggregateArgs = {
  filter?: Maybe<UserWarehouseAggregateFilter>;
};


export type QueryPrinterAggregateArgs = {
  filter?: Maybe<PrinterAggregateFilter>;
};


export type QueryPrintGatewayAggregateArgs = {
  filter?: Maybe<PrintGatewayAggregateFilter>;
};


export type QueryAddressArgs = {
  id: Scalars['ID'];
};


export type QueryAddressesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<AddressFilter>;
  sorting?: Maybe<Array<AddressSort>>;
};


export type QueryAddressAggregateArgs = {
  filter?: Maybe<AddressAggregateFilter>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryContactsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ContactFilter>;
  sorting?: Maybe<Array<ContactSort>>;
};


export type QueryContactAggregateArgs = {
  filter?: Maybe<ContactAggregateFilter>;
};


export type QueryZoneTypeArgs = {
  id: Scalars['ID'];
};


export type QueryZoneTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneTypeFilter>;
  sorting?: Maybe<Array<ZoneTypeSort>>;
};


export type QueryZoneTypeAggregateArgs = {
  filter?: Maybe<ZoneTypeAggregateFilter>;
};


export type QueryActionTypeArgs = {
  id: Scalars['ID'];
};


export type QueryActionTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionTypeFilter>;
  sorting?: Maybe<Array<ActionTypeSort>>;
};


export type QueryActionTypeAggregateArgs = {
  filter?: Maybe<ActionTypeAggregateFilter>;
};


export type QueryAlertTypeArgs = {
  id: Scalars['ID'];
};


export type QueryAlertTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<AlertTypeFilter>;
  sorting?: Maybe<Array<AlertTypeSort>>;
};


export type QueryAlertTypeAggregateArgs = {
  filter?: Maybe<AlertTypeAggregateFilter>;
};


export type QueryAlertStatusArgs = {
  id: Scalars['ID'];
};


export type QueryAlertStatusesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<AlertStatusFilter>;
  sorting?: Maybe<Array<AlertStatusSort>>;
};


export type QueryAlertStatusAggregateArgs = {
  filter?: Maybe<AlertStatusAggregateFilter>;
};


export type QueryAlertAggregateArgs = {
  filter?: Maybe<AlertAggregateFilter>;
};


export type QueryAlertArgs = {
  id: Scalars['ID'];
};


export type QueryAlertsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<AlertFilter>;
  sorting?: Maybe<Array<AlertSort>>;
};


export type QueryActionAttachmentAggregateArgs = {
  filter?: Maybe<ActionAttachmentAggregateFilter>;
};


export type QueryActionAttachmentArgs = {
  id: Scalars['ID'];
};


export type QueryActionAttachmentsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionAttachmentFilter>;
  sorting?: Maybe<Array<ActionAttachmentSort>>;
};


export type QueryActionAggregateArgs = {
  filter?: Maybe<ActionAggregateFilter>;
};


export type QueryActionArgs = {
  id: Scalars['ID'];
};


export type QueryActionsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionFilter>;
  sorting?: Maybe<Array<ActionSort>>;
};


export type QueryVerifyAuthorizationTokenArgs = {
  authToken: Scalars['String'];
};


export type QueryPackageArgs = {
  trackingNumber: Scalars['String'];
};


export type QueryActionTypeCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryActionTypeCategoriesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ActionTypeCategoryFilter>;
  sorting?: Maybe<Array<ActionTypeCategorySort>>;
};


export type QueryActionTypeCategoryAggregateArgs = {
  filter?: Maybe<ActionTypeCategoryAggregateFilter>;
};

export type RelationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RelationsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type State = {
  __typename?: 'State';
  name: Scalars['String'];
};

export type States = {
  __typename?: 'States';
  nodes: Array<State>;
};

export type Stock = {
  __typename?: 'Stock';
  id: Scalars['ID'];
  internalId: Scalars['String'];
  externalId: Scalars['String'];
  routeId?: Maybe<Scalars['String']>;
  actionId: Scalars['String'];
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId: Scalars['String'];
  actionTypeName: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  zone?: Maybe<Zone>;
  subZone?: Maybe<Zone>;
  warehouse?: Maybe<Warehouse>;
  originWarehouse?: Maybe<Warehouse>;
  destinationWarehouse?: Maybe<Warehouse>;
  actionType: ActionType;
};

export type StockAggregateFilter = {
  and?: Maybe<Array<StockAggregateFilter>>;
  or?: Maybe<Array<StockAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionId?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  originWarehouseId?: Maybe<StringFieldComparison>;
  destinationWarehouseId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type StockAggregateResponse = {
  __typename?: 'StockAggregateResponse';
  count?: Maybe<StockCountAggregate>;
  min?: Maybe<StockMinAggregate>;
  max?: Maybe<StockMaxAggregate>;
};

export type StockConnection = {
  __typename?: 'StockConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Stock>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type StockCountAggregate = {
  __typename?: 'StockCountAggregate';
  id?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  originWarehouseId?: Maybe<Scalars['Int']>;
  destinationWarehouseId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type StockDeleteFilter = {
  and?: Maybe<Array<StockDeleteFilter>>;
  or?: Maybe<Array<StockDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionId?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  originWarehouseId?: Maybe<StringFieldComparison>;
  destinationWarehouseId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type StockFilter = {
  and?: Maybe<Array<StockFilter>>;
  or?: Maybe<Array<StockFilter>>;
  id?: Maybe<IdFilterComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionId?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  originWarehouseId?: Maybe<StringFieldComparison>;
  destinationWarehouseId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  actionType?: Maybe<StockFilterActionTypeFilter>;
  destinationWarehouse?: Maybe<StockFilterWarehouseFilter>;
  originWarehouse?: Maybe<StockFilterWarehouseFilter>;
  warehouse?: Maybe<StockFilterWarehouseFilter>;
  subZone?: Maybe<StockFilterZoneFilter>;
  zone?: Maybe<StockFilterZoneFilter>;
};

export type StockFilterActionTypeFilter = {
  and?: Maybe<Array<StockFilterActionTypeFilter>>;
  or?: Maybe<Array<StockFilterActionTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type StockFilterWarehouseFilter = {
  and?: Maybe<Array<StockFilterWarehouseFilter>>;
  or?: Maybe<Array<StockFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type StockFilterZoneFilter = {
  and?: Maybe<Array<StockFilterZoneFilter>>;
  or?: Maybe<Array<StockFilterZoneFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type StockInput = {
  internalId: Scalars['String'];
  externalId: Scalars['String'];
  routeId?: Maybe<Scalars['String']>;
  actionId: Scalars['String'];
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId: Scalars['String'];
  actionTypeName: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type StockMaxAggregate = {
  __typename?: 'StockMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type StockMinAggregate = {
  __typename?: 'StockMinAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type StockSort = {
  field: StockSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum StockSortFields {
  Id = 'id',
  InternalId = 'internalId',
  ExternalId = 'externalId',
  RouteId = 'routeId',
  ActionId = 'actionId',
  ZoneId = 'zoneId',
  ZoneName = 'zoneName',
  SubZoneId = 'subZoneId',
  SubZoneName = 'subZoneName',
  WarehouseId = 'warehouseId',
  OriginWarehouseId = 'originWarehouseId',
  DestinationWarehouseId = 'destinationWarehouseId',
  AuthorizedBy = 'authorizedBy',
  AuthorizedById = 'authorizedById',
  ExecutedBy = 'executedBy',
  ExecutedById = 'executedById',
  ClientId = 'clientId',
  ClientName = 'clientName',
  ActionTypeId = 'actionTypeId',
  ActionTypeName = 'actionTypeName',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type StockUpdate = {
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type StockUpdateFilter = {
  and?: Maybe<Array<StockUpdateFilter>>;
  or?: Maybe<Array<StockUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionId?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  originWarehouseId?: Maybe<StringFieldComparison>;
  destinationWarehouseId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type StringFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  notLike?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  notILike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type UpdateManyActionAttachmentsInput = {
  /** Filter used to find fields to update */
  filter: ActionAttachmentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ActionAttachmentUpdate;
};

export type UpdateManyActionTypeCategoriesInput = {
  /** Filter used to find fields to update */
  filter: ActionTypeCategoryUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ActionTypeCategoryUpdate;
};

export type UpdateManyActionTypesInput = {
  /** Filter used to find fields to update */
  filter: ActionTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ActionTypeUpdate;
};

export type UpdateManyActionsInput = {
  /** Filter used to find fields to update */
  filter: ActionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ActionUpdate;
};

export type UpdateManyAddressesInput = {
  /** Filter used to find fields to update */
  filter: AddressUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AddressUpdate;
};

export type UpdateManyAlertStatusesInput = {
  /** Filter used to find fields to update */
  filter: AlertStatusUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AlertStatusUpdate;
};

export type UpdateManyAlertTypesInput = {
  /** Filter used to find fields to update */
  filter: AlertTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AlertTypeUpdate;
};

export type UpdateManyAlertsInput = {
  /** Filter used to find fields to update */
  filter: AlertUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AlertUpdate;
};

export type UpdateManyContactsInput = {
  /** Filter used to find fields to update */
  filter: ContactUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ContactUpdate;
};

export type UpdateManyManifestsInput = {
  /** Filter used to find fields to update */
  filter: ManifestUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ManifestUpdate;
};

export type UpdateManyPrintGatewaysInput = {
  /** Filter used to find fields to update */
  filter: PrintGatewayUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: PrintGatewayUpdate;
};

export type UpdateManyPrintersInput = {
  /** Filter used to find fields to update */
  filter: PrinterUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: PrinterUpdate;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManyStocksInput = {
  /** Filter used to find fields to update */
  filter: StockUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: StockUpdate;
};

export type UpdateManyUserWarehousesInput = {
  /** Filter used to find fields to update */
  filter: UserWarehouseUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UserWarehouseUpdate;
};

export type UpdateManyWarehouseTypesInput = {
  /** Filter used to find fields to update */
  filter: WarehouseTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: WarehouseTypeUpdate;
};

export type UpdateManyWarehousesInput = {
  /** Filter used to find fields to update */
  filter: WarehouseUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: WarehouseUpdate;
};

export type UpdateManyZoneActionTypeBlacklistsInput = {
  /** Filter used to find fields to update */
  filter: ZoneActionTypeBlacklistUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ZoneActionTypeBlacklistUpdate;
};

export type UpdateManyZoneBlacklistsInput = {
  /** Filter used to find fields to update */
  filter: ZoneBlacklistUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ZoneBlacklistUpdate;
};

export type UpdateManyZoneTypesInput = {
  /** Filter used to find fields to update */
  filter: ZoneTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ZoneTypeUpdate;
};

export type UpdateManyZonesInput = {
  /** Filter used to find fields to update */
  filter: ZoneUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ZoneUpdate;
};

export type UpdateOneActionAttachmentInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ActionAttachmentUpdate;
};

export type UpdateOneActionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ActionUpdate;
};

export type UpdateOneActionTypeCategoryInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ActionTypeCategoryUpdate;
};

export type UpdateOneActionTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ActionTypeUpdate;
};

export type UpdateOneAddressInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: AddressUpdate;
};

export type UpdateOneAlertInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: AlertUpdate;
};

export type UpdateOneAlertStatusInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: AlertStatusUpdate;
};

export type UpdateOneAlertTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: AlertTypeUpdate;
};

export type UpdateOneContactInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ContactUpdate;
};

export type UpdateOneManifestInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ManifestUpdate;
};

export type UpdateOnePrintGatewayInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: PrintGatewayUpdate;
};

export type UpdateOnePrinterInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: PrinterUpdate;
};

export type UpdateOneStockInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: StockUpdate;
};

export type UpdateOneUserWarehouseInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UserWarehouseUpdate;
};

export type UpdateOneWarehouseInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: WarehouseUpdate;
};

export type UpdateOneWarehouseTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: WarehouseTypeUpdate;
};

export type UpdateOneZoneActionTypeBlacklistInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ZoneActionTypeBlacklistUpdate;
};

export type UpdateOneZoneBlacklistInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ZoneBlacklistUpdate;
};

export type UpdateOneZoneInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ZoneUpdate;
};

export type UpdateOneZoneTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ZoneTypeUpdate;
};

export type UserWarehouse = {
  __typename?: 'UserWarehouse';
  id: Scalars['ID'];
  warehouseId: Scalars['String'];
  userId: Scalars['String'];
  userEmail: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  warehouse?: Maybe<Warehouse>;
};

export type UserWarehouseAggregateFilter = {
  and?: Maybe<Array<UserWarehouseAggregateFilter>>;
  or?: Maybe<Array<UserWarehouseAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  userEmail?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type UserWarehouseAggregateResponse = {
  __typename?: 'UserWarehouseAggregateResponse';
  count?: Maybe<UserWarehouseCountAggregate>;
  min?: Maybe<UserWarehouseMinAggregate>;
  max?: Maybe<UserWarehouseMaxAggregate>;
};

export type UserWarehouseConnection = {
  __typename?: 'UserWarehouseConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<UserWarehouse>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserWarehouseCountAggregate = {
  __typename?: 'UserWarehouseCountAggregate';
  id?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  userEmail?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type UserWarehouseDeleteFilter = {
  and?: Maybe<Array<UserWarehouseDeleteFilter>>;
  or?: Maybe<Array<UserWarehouseDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  userEmail?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type UserWarehouseFilter = {
  and?: Maybe<Array<UserWarehouseFilter>>;
  or?: Maybe<Array<UserWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  userEmail?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  warehouse?: Maybe<UserWarehouseFilterWarehouseFilter>;
};

export type UserWarehouseFilterWarehouseFilter = {
  and?: Maybe<Array<UserWarehouseFilterWarehouseFilter>>;
  or?: Maybe<Array<UserWarehouseFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type UserWarehouseInput = {
  warehouseId: Scalars['String'];
  userId: Scalars['String'];
  userEmail: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type UserWarehouseMaxAggregate = {
  __typename?: 'UserWarehouseMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type UserWarehouseMinAggregate = {
  __typename?: 'UserWarehouseMinAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type UserWarehouseSort = {
  field: UserWarehouseSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserWarehouseSortFields {
  Id = 'id',
  WarehouseId = 'warehouseId',
  UserId = 'userId',
  UserEmail = 'userEmail',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type UserWarehouseUpdate = {
  warehouseId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type UserWarehouseUpdateFilter = {
  and?: Maybe<Array<UserWarehouseUpdateFilter>>;
  or?: Maybe<Array<UserWarehouseUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  userEmail?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type VerifyAuthorizationToken = {
  __typename?: 'VerifyAuthorizationToken';
  valid: Scalars['Boolean'];
};

export type Warehouse = {
  __typename?: 'Warehouse';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  isMain: Scalars['Boolean'];
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy: Scalars['String'];
  zoneNameLegacy: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  zonesAggregate: WarehouseZonesAggregateResponse;
  stocksAggregate: WarehouseStocksAggregateResponse;
  destinationStocksAggregate: WarehouseDestinationStocksAggregateResponse;
  originStocksAggregate: WarehouseOriginStocksAggregateResponse;
  usersAggregate: WarehouseUsersAggregateResponse;
  warehouseType?: Maybe<WarehouseType>;
  address?: Maybe<Address>;
  contact?: Maybe<Contact>;
  zones?: Maybe<WarehouseZonesConnection>;
  stocks?: Maybe<WarehouseStocksConnection>;
  destinationStocks?: Maybe<WarehouseDestinationStocksConnection>;
  originStocks?: Maybe<WarehouseOriginStocksConnection>;
  users?: Maybe<WarehouseUsersConnection>;
};


export type WarehouseZonesAggregateArgs = {
  filter?: Maybe<ZoneAggregateFilter>;
};


export type WarehouseStocksAggregateArgs = {
  filter?: Maybe<StockAggregateFilter>;
};


export type WarehouseDestinationStocksAggregateArgs = {
  filter?: Maybe<StockAggregateFilter>;
};


export type WarehouseOriginStocksAggregateArgs = {
  filter?: Maybe<StockAggregateFilter>;
};


export type WarehouseUsersAggregateArgs = {
  filter?: Maybe<UserWarehouseAggregateFilter>;
};


export type WarehouseZonesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneFilter>;
  sorting?: Maybe<Array<ZoneSort>>;
};


export type WarehouseStocksArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<StockFilter>;
  sorting?: Maybe<Array<StockSort>>;
};


export type WarehouseDestinationStocksArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<StockFilter>;
  sorting?: Maybe<Array<StockSort>>;
};


export type WarehouseOriginStocksArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<StockFilter>;
  sorting?: Maybe<Array<StockSort>>;
};


export type WarehouseUsersArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<UserWarehouseFilter>;
  sorting?: Maybe<Array<UserWarehouseSort>>;
};

export type WarehouseAggregateFilter = {
  and?: Maybe<Array<WarehouseAggregateFilter>>;
  or?: Maybe<Array<WarehouseAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseAggregateResponse = {
  __typename?: 'WarehouseAggregateResponse';
  count?: Maybe<WarehouseCountAggregate>;
  min?: Maybe<WarehouseMinAggregate>;
  max?: Maybe<WarehouseMaxAggregate>;
};

export type WarehouseConnection = {
  __typename?: 'WarehouseConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Warehouse>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseCountAggregate = {
  __typename?: 'WarehouseCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  isMain?: Maybe<Scalars['Int']>;
  warehouseTypeId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
  zoneIdLegacy?: Maybe<Scalars['Int']>;
  zoneNameLegacy?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseDeleteFilter = {
  and?: Maybe<Array<WarehouseDeleteFilter>>;
  or?: Maybe<Array<WarehouseDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseDestinationStocksAggregateResponse = {
  __typename?: 'WarehouseDestinationStocksAggregateResponse';
  count?: Maybe<WarehouseDestinationStocksCountAggregate>;
  min?: Maybe<WarehouseDestinationStocksMinAggregate>;
  max?: Maybe<WarehouseDestinationStocksMaxAggregate>;
};

export type WarehouseDestinationStocksConnection = {
  __typename?: 'WarehouseDestinationStocksConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Stock>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseDestinationStocksCountAggregate = {
  __typename?: 'WarehouseDestinationStocksCountAggregate';
  id?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  originWarehouseId?: Maybe<Scalars['Int']>;
  destinationWarehouseId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseDestinationStocksMaxAggregate = {
  __typename?: 'WarehouseDestinationStocksMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseDestinationStocksMinAggregate = {
  __typename?: 'WarehouseDestinationStocksMinAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseFilter = {
  and?: Maybe<Array<WarehouseFilter>>;
  or?: Maybe<Array<WarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  contact?: Maybe<WarehouseFilterContactFilter>;
  address?: Maybe<WarehouseFilterAddressFilter>;
  warehouseType?: Maybe<WarehouseFilterWarehouseTypeFilter>;
  users?: Maybe<WarehouseFilterUserWarehouseFilter>;
  originStocks?: Maybe<WarehouseFilterStockFilter>;
  destinationStocks?: Maybe<WarehouseFilterStockFilter>;
  stocks?: Maybe<WarehouseFilterStockFilter>;
  zones?: Maybe<WarehouseFilterZoneFilter>;
};

export type WarehouseFilterAddressFilter = {
  and?: Maybe<Array<WarehouseFilterAddressFilter>>;
  or?: Maybe<Array<WarehouseFilterAddressFilter>>;
  id?: Maybe<IdFilterComparison>;
  street?: Maybe<StringFieldComparison>;
  externalNumber?: Maybe<StringFieldComparison>;
  internalNumber?: Maybe<StringFieldComparison>;
  neighborhood?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  zipCode?: Maybe<StringFieldComparison>;
  latitude?: Maybe<NumberFieldComparison>;
  longitude?: Maybe<NumberFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseFilterContactFilter = {
  and?: Maybe<Array<WarehouseFilterContactFilter>>;
  or?: Maybe<Array<WarehouseFilterContactFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  lastname?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  fax?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseFilterStockFilter = {
  and?: Maybe<Array<WarehouseFilterStockFilter>>;
  or?: Maybe<Array<WarehouseFilterStockFilter>>;
  id?: Maybe<IdFilterComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionId?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  originWarehouseId?: Maybe<StringFieldComparison>;
  destinationWarehouseId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseFilterUserWarehouseFilter = {
  and?: Maybe<Array<WarehouseFilterUserWarehouseFilter>>;
  or?: Maybe<Array<WarehouseFilterUserWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  userEmail?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseFilterWarehouseTypeFilter = {
  and?: Maybe<Array<WarehouseFilterWarehouseTypeFilter>>;
  or?: Maybe<Array<WarehouseFilterWarehouseTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseFilterZoneFilter = {
  and?: Maybe<Array<WarehouseFilterZoneFilter>>;
  or?: Maybe<Array<WarehouseFilterZoneFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  isMain: Scalars['Boolean'];
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy: Scalars['String'];
  zoneNameLegacy: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type WarehouseMaxAggregate = {
  __typename?: 'WarehouseMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseMinAggregate = {
  __typename?: 'WarehouseMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseOriginStocksAggregateResponse = {
  __typename?: 'WarehouseOriginStocksAggregateResponse';
  count?: Maybe<WarehouseOriginStocksCountAggregate>;
  min?: Maybe<WarehouseOriginStocksMinAggregate>;
  max?: Maybe<WarehouseOriginStocksMaxAggregate>;
};

export type WarehouseOriginStocksConnection = {
  __typename?: 'WarehouseOriginStocksConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Stock>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseOriginStocksCountAggregate = {
  __typename?: 'WarehouseOriginStocksCountAggregate';
  id?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  originWarehouseId?: Maybe<Scalars['Int']>;
  destinationWarehouseId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseOriginStocksMaxAggregate = {
  __typename?: 'WarehouseOriginStocksMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseOriginStocksMinAggregate = {
  __typename?: 'WarehouseOriginStocksMinAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseSort = {
  field: WarehouseSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum WarehouseSortFields {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  Code = 'code',
  IsMain = 'isMain',
  WarehouseTypeId = 'warehouseTypeId',
  AddressId = 'addressId',
  ContactId = 'contactId',
  ZoneIdLegacy = 'zoneIdLegacy',
  ZoneNameLegacy = 'zoneNameLegacy',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type WarehouseStocksAggregateResponse = {
  __typename?: 'WarehouseStocksAggregateResponse';
  count?: Maybe<WarehouseStocksCountAggregate>;
  min?: Maybe<WarehouseStocksMinAggregate>;
  max?: Maybe<WarehouseStocksMaxAggregate>;
};

export type WarehouseStocksConnection = {
  __typename?: 'WarehouseStocksConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Stock>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseStocksCountAggregate = {
  __typename?: 'WarehouseStocksCountAggregate';
  id?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  originWarehouseId?: Maybe<Scalars['Int']>;
  destinationWarehouseId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseStocksMaxAggregate = {
  __typename?: 'WarehouseStocksMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseStocksMinAggregate = {
  __typename?: 'WarehouseStocksMinAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseType = {
  __typename?: 'WarehouseType';
  id: Scalars['ID'];
  key: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  warehousesAggregate: WarehouseTypeWarehousesAggregateResponse;
  warehouses?: Maybe<WarehouseTypeWarehousesConnection>;
};


export type WarehouseTypeWarehousesAggregateArgs = {
  filter?: Maybe<WarehouseAggregateFilter>;
};


export type WarehouseTypeWarehousesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<WarehouseFilter>;
  sorting?: Maybe<Array<WarehouseSort>>;
};

export type WarehouseTypeAggregateFilter = {
  and?: Maybe<Array<WarehouseTypeAggregateFilter>>;
  or?: Maybe<Array<WarehouseTypeAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseTypeAggregateResponse = {
  __typename?: 'WarehouseTypeAggregateResponse';
  count?: Maybe<WarehouseTypeCountAggregate>;
  min?: Maybe<WarehouseTypeMinAggregate>;
  max?: Maybe<WarehouseTypeMaxAggregate>;
};

export type WarehouseTypeConnection = {
  __typename?: 'WarehouseTypeConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<WarehouseType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseTypeCountAggregate = {
  __typename?: 'WarehouseTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseTypeDeleteFilter = {
  and?: Maybe<Array<WarehouseTypeDeleteFilter>>;
  or?: Maybe<Array<WarehouseTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseTypeFilter = {
  and?: Maybe<Array<WarehouseTypeFilter>>;
  or?: Maybe<Array<WarehouseTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  warehouses?: Maybe<WarehouseTypeFilterWarehouseFilter>;
};

export type WarehouseTypeFilterWarehouseFilter = {
  and?: Maybe<Array<WarehouseTypeFilterWarehouseFilter>>;
  or?: Maybe<Array<WarehouseTypeFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseTypeInput = {
  key: Scalars['String'];
  description: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type WarehouseTypeMaxAggregate = {
  __typename?: 'WarehouseTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseTypeMinAggregate = {
  __typename?: 'WarehouseTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseTypeSort = {
  field: WarehouseTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum WarehouseTypeSortFields {
  Id = 'id',
  Key = 'key',
  Description = 'description',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type WarehouseTypeUpdate = {
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseTypeUpdateFilter = {
  and?: Maybe<Array<WarehouseTypeUpdateFilter>>;
  or?: Maybe<Array<WarehouseTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseTypeWarehousesAggregateResponse = {
  __typename?: 'WarehouseTypeWarehousesAggregateResponse';
  count?: Maybe<WarehouseTypeWarehousesCountAggregate>;
  min?: Maybe<WarehouseTypeWarehousesMinAggregate>;
  max?: Maybe<WarehouseTypeWarehousesMaxAggregate>;
};

export type WarehouseTypeWarehousesConnection = {
  __typename?: 'WarehouseTypeWarehousesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Warehouse>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseTypeWarehousesCountAggregate = {
  __typename?: 'WarehouseTypeWarehousesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  isMain?: Maybe<Scalars['Int']>;
  warehouseTypeId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
  zoneIdLegacy?: Maybe<Scalars['Int']>;
  zoneNameLegacy?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseTypeWarehousesMaxAggregate = {
  __typename?: 'WarehouseTypeWarehousesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseTypeWarehousesMinAggregate = {
  __typename?: 'WarehouseTypeWarehousesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseUpdate = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  isMain?: Maybe<Scalars['Boolean']>;
  warehouseTypeId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  zoneIdLegacy?: Maybe<Scalars['String']>;
  zoneNameLegacy?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseUpdateFilter = {
  and?: Maybe<Array<WarehouseUpdateFilter>>;
  or?: Maybe<Array<WarehouseUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type WarehouseUsersAggregateResponse = {
  __typename?: 'WarehouseUsersAggregateResponse';
  count?: Maybe<WarehouseUsersCountAggregate>;
  min?: Maybe<WarehouseUsersMinAggregate>;
  max?: Maybe<WarehouseUsersMaxAggregate>;
};

export type WarehouseUsersConnection = {
  __typename?: 'WarehouseUsersConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<UserWarehouse>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseUsersCountAggregate = {
  __typename?: 'WarehouseUsersCountAggregate';
  id?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  userEmail?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseUsersMaxAggregate = {
  __typename?: 'WarehouseUsersMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseUsersMinAggregate = {
  __typename?: 'WarehouseUsersMinAggregate';
  id?: Maybe<Scalars['ID']>;
  warehouseId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseZonesAggregateResponse = {
  __typename?: 'WarehouseZonesAggregateResponse';
  count?: Maybe<WarehouseZonesCountAggregate>;
  sum?: Maybe<WarehouseZonesSumAggregate>;
  avg?: Maybe<WarehouseZonesAvgAggregate>;
  min?: Maybe<WarehouseZonesMinAggregate>;
  max?: Maybe<WarehouseZonesMaxAggregate>;
};

export type WarehouseZonesAvgAggregate = {
  __typename?: 'WarehouseZonesAvgAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type WarehouseZonesConnection = {
  __typename?: 'WarehouseZonesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Zone>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WarehouseZonesCountAggregate = {
  __typename?: 'WarehouseZonesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  zoneTypeId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  parentZoneId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type WarehouseZonesMaxAggregate = {
  __typename?: 'WarehouseZonesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseZonesMinAggregate = {
  __typename?: 'WarehouseZonesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseZonesSumAggregate = {
  __typename?: 'WarehouseZonesSumAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type Zone = {
  __typename?: 'Zone';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId: Scalars['String'];
  parentZoneId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  sort?: Maybe<Scalars['Float']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  forbiddenZonesAggregate: ZoneForbiddenZonesAggregateResponse;
  asForbiddenZonesAggregate: ZoneAsForbiddenZonesAggregateResponse;
  forbiddenActionTypesAggregate: ZoneForbiddenActionTypesAggregateResponse;
  stocksAggregate: ZoneStocksAggregateResponse;
  subZonesAggregate: ZoneSubZonesAggregateResponse;
  zoneType?: Maybe<ZoneType>;
  warehouse?: Maybe<Warehouse>;
  parentZone?: Maybe<Zone>;
  forbiddenZones?: Maybe<ZoneForbiddenZonesConnection>;
  asForbiddenZones?: Maybe<ZoneAsForbiddenZonesConnection>;
  forbiddenActionTypes?: Maybe<ZoneForbiddenActionTypesConnection>;
  stocks?: Maybe<ZoneStocksConnection>;
  subZones?: Maybe<ZoneSubZonesConnection>;
};


export type ZoneForbiddenZonesAggregateArgs = {
  filter?: Maybe<ZoneBlacklistAggregateFilter>;
};


export type ZoneAsForbiddenZonesAggregateArgs = {
  filter?: Maybe<ZoneBlacklistAggregateFilter>;
};


export type ZoneForbiddenActionTypesAggregateArgs = {
  filter?: Maybe<ZoneActionTypeBlacklistAggregateFilter>;
};


export type ZoneStocksAggregateArgs = {
  filter?: Maybe<StockAggregateFilter>;
};


export type ZoneSubZonesAggregateArgs = {
  filter?: Maybe<ZoneAggregateFilter>;
};


export type ZoneForbiddenZonesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneBlacklistFilter>;
  sorting?: Maybe<Array<ZoneBlacklistSort>>;
};


export type ZoneAsForbiddenZonesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneBlacklistFilter>;
  sorting?: Maybe<Array<ZoneBlacklistSort>>;
};


export type ZoneForbiddenActionTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneActionTypeBlacklistFilter>;
  sorting?: Maybe<Array<ZoneActionTypeBlacklistSort>>;
};


export type ZoneStocksArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<StockFilter>;
  sorting?: Maybe<Array<StockSort>>;
};


export type ZoneSubZonesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneFilter>;
  sorting?: Maybe<Array<ZoneSort>>;
};

export type ZoneActionTypeBlacklist = {
  __typename?: 'ZoneActionTypeBlacklist';
  id: Scalars['ID'];
  zoneId: Scalars['String'];
  actionTypeId: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  zone: Zone;
  actionType: ActionType;
};

export type ZoneActionTypeBlacklistAggregateFilter = {
  and?: Maybe<Array<ZoneActionTypeBlacklistAggregateFilter>>;
  or?: Maybe<Array<ZoneActionTypeBlacklistAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneActionTypeBlacklistAggregateResponse = {
  __typename?: 'ZoneActionTypeBlacklistAggregateResponse';
  count?: Maybe<ZoneActionTypeBlacklistCountAggregate>;
  min?: Maybe<ZoneActionTypeBlacklistMinAggregate>;
  max?: Maybe<ZoneActionTypeBlacklistMaxAggregate>;
};

export type ZoneActionTypeBlacklistConnection = {
  __typename?: 'ZoneActionTypeBlacklistConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ZoneActionTypeBlacklist>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneActionTypeBlacklistCountAggregate = {
  __typename?: 'ZoneActionTypeBlacklistCountAggregate';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneActionTypeBlacklistDeleteFilter = {
  and?: Maybe<Array<ZoneActionTypeBlacklistDeleteFilter>>;
  or?: Maybe<Array<ZoneActionTypeBlacklistDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneActionTypeBlacklistFilter = {
  and?: Maybe<Array<ZoneActionTypeBlacklistFilter>>;
  or?: Maybe<Array<ZoneActionTypeBlacklistFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  actionType?: Maybe<ZoneActionTypeBlacklistFilterActionTypeFilter>;
  zone?: Maybe<ZoneActionTypeBlacklistFilterZoneFilter>;
};

export type ZoneActionTypeBlacklistFilterActionTypeFilter = {
  and?: Maybe<Array<ZoneActionTypeBlacklistFilterActionTypeFilter>>;
  or?: Maybe<Array<ZoneActionTypeBlacklistFilterActionTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  triggerAlert?: Maybe<BooleanFieldComparison>;
  alertTypeId?: Maybe<StringFieldComparison>;
  actionTypeCategoryId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneActionTypeBlacklistFilterZoneFilter = {
  and?: Maybe<Array<ZoneActionTypeBlacklistFilterZoneFilter>>;
  or?: Maybe<Array<ZoneActionTypeBlacklistFilterZoneFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneActionTypeBlacklistInput = {
  zoneId: Scalars['String'];
  actionTypeId: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type ZoneActionTypeBlacklistMaxAggregate = {
  __typename?: 'ZoneActionTypeBlacklistMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneActionTypeBlacklistMinAggregate = {
  __typename?: 'ZoneActionTypeBlacklistMinAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneActionTypeBlacklistSort = {
  field: ZoneActionTypeBlacklistSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ZoneActionTypeBlacklistSortFields {
  Id = 'id',
  ZoneId = 'zoneId',
  ActionTypeId = 'actionTypeId',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ZoneActionTypeBlacklistUpdate = {
  zoneId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneActionTypeBlacklistUpdateFilter = {
  and?: Maybe<Array<ZoneActionTypeBlacklistUpdateFilter>>;
  or?: Maybe<Array<ZoneActionTypeBlacklistUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneAggregateFilter = {
  and?: Maybe<Array<ZoneAggregateFilter>>;
  or?: Maybe<Array<ZoneAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneAggregateResponse = {
  __typename?: 'ZoneAggregateResponse';
  count?: Maybe<ZoneCountAggregate>;
  sum?: Maybe<ZoneSumAggregate>;
  avg?: Maybe<ZoneAvgAggregate>;
  min?: Maybe<ZoneMinAggregate>;
  max?: Maybe<ZoneMaxAggregate>;
};

export type ZoneAsForbiddenZonesAggregateResponse = {
  __typename?: 'ZoneAsForbiddenZonesAggregateResponse';
  count?: Maybe<ZoneAsForbiddenZonesCountAggregate>;
  min?: Maybe<ZoneAsForbiddenZonesMinAggregate>;
  max?: Maybe<ZoneAsForbiddenZonesMaxAggregate>;
};

export type ZoneAsForbiddenZonesConnection = {
  __typename?: 'ZoneAsForbiddenZonesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ZoneBlacklist>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneAsForbiddenZonesCountAggregate = {
  __typename?: 'ZoneAsForbiddenZonesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  forbiddenZoneId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneAsForbiddenZonesMaxAggregate = {
  __typename?: 'ZoneAsForbiddenZonesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  forbiddenZoneId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneAsForbiddenZonesMinAggregate = {
  __typename?: 'ZoneAsForbiddenZonesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  forbiddenZoneId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneAvgAggregate = {
  __typename?: 'ZoneAvgAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ZoneBlacklist = {
  __typename?: 'ZoneBlacklist';
  id: Scalars['ID'];
  zoneId: Scalars['String'];
  forbiddenZoneId: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  zone: Zone;
  forbiddenZone: Zone;
};

export type ZoneBlacklistAggregateFilter = {
  and?: Maybe<Array<ZoneBlacklistAggregateFilter>>;
  or?: Maybe<Array<ZoneBlacklistAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  forbiddenZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneBlacklistAggregateResponse = {
  __typename?: 'ZoneBlacklistAggregateResponse';
  count?: Maybe<ZoneBlacklistCountAggregate>;
  min?: Maybe<ZoneBlacklistMinAggregate>;
  max?: Maybe<ZoneBlacklistMaxAggregate>;
};

export type ZoneBlacklistConnection = {
  __typename?: 'ZoneBlacklistConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ZoneBlacklist>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneBlacklistCountAggregate = {
  __typename?: 'ZoneBlacklistCountAggregate';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  forbiddenZoneId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneBlacklistDeleteFilter = {
  and?: Maybe<Array<ZoneBlacklistDeleteFilter>>;
  or?: Maybe<Array<ZoneBlacklistDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  forbiddenZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneBlacklistFilter = {
  and?: Maybe<Array<ZoneBlacklistFilter>>;
  or?: Maybe<Array<ZoneBlacklistFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  forbiddenZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  forbiddenZone?: Maybe<ZoneBlacklistFilterZoneFilter>;
  zone?: Maybe<ZoneBlacklistFilterZoneFilter>;
};

export type ZoneBlacklistFilterZoneFilter = {
  and?: Maybe<Array<ZoneBlacklistFilterZoneFilter>>;
  or?: Maybe<Array<ZoneBlacklistFilterZoneFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneBlacklistInput = {
  zoneId: Scalars['String'];
  forbiddenZoneId: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type ZoneBlacklistMaxAggregate = {
  __typename?: 'ZoneBlacklistMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  forbiddenZoneId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneBlacklistMinAggregate = {
  __typename?: 'ZoneBlacklistMinAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  forbiddenZoneId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneBlacklistSort = {
  field: ZoneBlacklistSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ZoneBlacklistSortFields {
  Id = 'id',
  ZoneId = 'zoneId',
  ForbiddenZoneId = 'forbiddenZoneId',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ZoneBlacklistUpdate = {
  zoneId?: Maybe<Scalars['String']>;
  forbiddenZoneId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneBlacklistUpdateFilter = {
  and?: Maybe<Array<ZoneBlacklistUpdateFilter>>;
  or?: Maybe<Array<ZoneBlacklistUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  forbiddenZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneConnection = {
  __typename?: 'ZoneConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Zone>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneCountAggregate = {
  __typename?: 'ZoneCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  zoneTypeId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  parentZoneId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneDeleteFilter = {
  and?: Maybe<Array<ZoneDeleteFilter>>;
  or?: Maybe<Array<ZoneDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneFilter = {
  and?: Maybe<Array<ZoneFilter>>;
  or?: Maybe<Array<ZoneFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  parentZone?: Maybe<ZoneFilterZoneFilter>;
  warehouse?: Maybe<ZoneFilterWarehouseFilter>;
  zoneType?: Maybe<ZoneFilterZoneTypeFilter>;
  subZones?: Maybe<ZoneFilterZoneFilter>;
  stocks?: Maybe<ZoneFilterStockFilter>;
  forbiddenActionTypes?: Maybe<ZoneFilterZoneActionTypeBlacklistFilter>;
  asForbiddenZones?: Maybe<ZoneFilterZoneBlacklistFilter>;
  forbiddenZones?: Maybe<ZoneFilterZoneBlacklistFilter>;
};

export type ZoneFilterStockFilter = {
  and?: Maybe<Array<ZoneFilterStockFilter>>;
  or?: Maybe<Array<ZoneFilterStockFilter>>;
  id?: Maybe<IdFilterComparison>;
  internalId?: Maybe<StringFieldComparison>;
  externalId?: Maybe<StringFieldComparison>;
  routeId?: Maybe<StringFieldComparison>;
  actionId?: Maybe<StringFieldComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  zoneName?: Maybe<StringFieldComparison>;
  subZoneId?: Maybe<StringFieldComparison>;
  subZoneName?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  originWarehouseId?: Maybe<StringFieldComparison>;
  destinationWarehouseId?: Maybe<StringFieldComparison>;
  authorizedBy?: Maybe<StringFieldComparison>;
  authorizedById?: Maybe<StringFieldComparison>;
  executedBy?: Maybe<StringFieldComparison>;
  executedById?: Maybe<StringFieldComparison>;
  clientId?: Maybe<StringFieldComparison>;
  clientName?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  actionTypeName?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneFilterWarehouseFilter = {
  and?: Maybe<Array<ZoneFilterWarehouseFilter>>;
  or?: Maybe<Array<ZoneFilterWarehouseFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  isMain?: Maybe<BooleanFieldComparison>;
  warehouseTypeId?: Maybe<StringFieldComparison>;
  addressId?: Maybe<StringFieldComparison>;
  contactId?: Maybe<StringFieldComparison>;
  zoneIdLegacy?: Maybe<StringFieldComparison>;
  zoneNameLegacy?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneFilterZoneActionTypeBlacklistFilter = {
  and?: Maybe<Array<ZoneFilterZoneActionTypeBlacklistFilter>>;
  or?: Maybe<Array<ZoneFilterZoneActionTypeBlacklistFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  actionTypeId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneFilterZoneBlacklistFilter = {
  and?: Maybe<Array<ZoneFilterZoneBlacklistFilter>>;
  or?: Maybe<Array<ZoneFilterZoneBlacklistFilter>>;
  id?: Maybe<IdFilterComparison>;
  zoneId?: Maybe<StringFieldComparison>;
  forbiddenZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneFilterZoneFilter = {
  and?: Maybe<Array<ZoneFilterZoneFilter>>;
  or?: Maybe<Array<ZoneFilterZoneFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneFilterZoneTypeFilter = {
  and?: Maybe<Array<ZoneFilterZoneTypeFilter>>;
  or?: Maybe<Array<ZoneFilterZoneTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneForbiddenActionTypesAggregateResponse = {
  __typename?: 'ZoneForbiddenActionTypesAggregateResponse';
  count?: Maybe<ZoneForbiddenActionTypesCountAggregate>;
  min?: Maybe<ZoneForbiddenActionTypesMinAggregate>;
  max?: Maybe<ZoneForbiddenActionTypesMaxAggregate>;
};

export type ZoneForbiddenActionTypesConnection = {
  __typename?: 'ZoneForbiddenActionTypesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ZoneActionTypeBlacklist>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneForbiddenActionTypesCountAggregate = {
  __typename?: 'ZoneForbiddenActionTypesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneForbiddenActionTypesMaxAggregate = {
  __typename?: 'ZoneForbiddenActionTypesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneForbiddenActionTypesMinAggregate = {
  __typename?: 'ZoneForbiddenActionTypesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneForbiddenZonesAggregateResponse = {
  __typename?: 'ZoneForbiddenZonesAggregateResponse';
  count?: Maybe<ZoneForbiddenZonesCountAggregate>;
  min?: Maybe<ZoneForbiddenZonesMinAggregate>;
  max?: Maybe<ZoneForbiddenZonesMaxAggregate>;
};

export type ZoneForbiddenZonesConnection = {
  __typename?: 'ZoneForbiddenZonesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ZoneBlacklist>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneForbiddenZonesCountAggregate = {
  __typename?: 'ZoneForbiddenZonesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  forbiddenZoneId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneForbiddenZonesMaxAggregate = {
  __typename?: 'ZoneForbiddenZonesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  forbiddenZoneId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneForbiddenZonesMinAggregate = {
  __typename?: 'ZoneForbiddenZonesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
  forbiddenZoneId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId: Scalars['String'];
  parentZoneId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Float']>;
};

export type ZoneMaxAggregate = {
  __typename?: 'ZoneMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneMinAggregate = {
  __typename?: 'ZoneMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneSort = {
  field: ZoneSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ZoneSortFields {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  ZoneTypeId = 'zoneTypeId',
  WarehouseId = 'warehouseId',
  ParentZoneId = 'parentZoneId',
  Active = 'active',
  Sort = 'sort',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ZoneStocksAggregateResponse = {
  __typename?: 'ZoneStocksAggregateResponse';
  count?: Maybe<ZoneStocksCountAggregate>;
  min?: Maybe<ZoneStocksMinAggregate>;
  max?: Maybe<ZoneStocksMaxAggregate>;
};

export type ZoneStocksConnection = {
  __typename?: 'ZoneStocksConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Stock>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneStocksCountAggregate = {
  __typename?: 'ZoneStocksCountAggregate';
  id?: Maybe<Scalars['Int']>;
  internalId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['Int']>;
  subZoneId?: Maybe<Scalars['Int']>;
  subZoneName?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  originWarehouseId?: Maybe<Scalars['Int']>;
  destinationWarehouseId?: Maybe<Scalars['Int']>;
  authorizedBy?: Maybe<Scalars['Int']>;
  authorizedById?: Maybe<Scalars['Int']>;
  executedBy?: Maybe<Scalars['Int']>;
  executedById?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['Int']>;
  actionTypeId?: Maybe<Scalars['Int']>;
  actionTypeName?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneStocksMaxAggregate = {
  __typename?: 'ZoneStocksMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneStocksMinAggregate = {
  __typename?: 'ZoneStocksMinAggregate';
  id?: Maybe<Scalars['ID']>;
  internalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
  subZoneId?: Maybe<Scalars['String']>;
  subZoneName?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  originWarehouseId?: Maybe<Scalars['String']>;
  destinationWarehouseId?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedById?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  executedById?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  actionTypeId?: Maybe<Scalars['String']>;
  actionTypeName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneSubZonesAggregateResponse = {
  __typename?: 'ZoneSubZonesAggregateResponse';
  count?: Maybe<ZoneSubZonesCountAggregate>;
  sum?: Maybe<ZoneSubZonesSumAggregate>;
  avg?: Maybe<ZoneSubZonesAvgAggregate>;
  min?: Maybe<ZoneSubZonesMinAggregate>;
  max?: Maybe<ZoneSubZonesMaxAggregate>;
};

export type ZoneSubZonesAvgAggregate = {
  __typename?: 'ZoneSubZonesAvgAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ZoneSubZonesConnection = {
  __typename?: 'ZoneSubZonesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Zone>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneSubZonesCountAggregate = {
  __typename?: 'ZoneSubZonesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  zoneTypeId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  parentZoneId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneSubZonesMaxAggregate = {
  __typename?: 'ZoneSubZonesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneSubZonesMinAggregate = {
  __typename?: 'ZoneSubZonesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneSubZonesSumAggregate = {
  __typename?: 'ZoneSubZonesSumAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ZoneSumAggregate = {
  __typename?: 'ZoneSumAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ZoneType = {
  __typename?: 'ZoneType';
  id: Scalars['ID'];
  key: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  deletedBy?: Maybe<Scalars['String']>;
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  deletedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  zonesAggregate: ZoneTypeZonesAggregateResponse;
  zones?: Maybe<ZoneTypeZonesConnection>;
};


export type ZoneTypeZonesAggregateArgs = {
  filter?: Maybe<ZoneAggregateFilter>;
};


export type ZoneTypeZonesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ZoneFilter>;
  sorting?: Maybe<Array<ZoneSort>>;
};

export type ZoneTypeAggregateFilter = {
  and?: Maybe<Array<ZoneTypeAggregateFilter>>;
  or?: Maybe<Array<ZoneTypeAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneTypeAggregateResponse = {
  __typename?: 'ZoneTypeAggregateResponse';
  count?: Maybe<ZoneTypeCountAggregate>;
  min?: Maybe<ZoneTypeMinAggregate>;
  max?: Maybe<ZoneTypeMaxAggregate>;
};

export type ZoneTypeConnection = {
  __typename?: 'ZoneTypeConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<ZoneType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneTypeCountAggregate = {
  __typename?: 'ZoneTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneTypeDeleteFilter = {
  and?: Maybe<Array<ZoneTypeDeleteFilter>>;
  or?: Maybe<Array<ZoneTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneTypeFilter = {
  and?: Maybe<Array<ZoneTypeFilter>>;
  or?: Maybe<Array<ZoneTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  zones?: Maybe<ZoneTypeFilterZoneFilter>;
};

export type ZoneTypeFilterZoneFilter = {
  and?: Maybe<Array<ZoneTypeFilterZoneFilter>>;
  or?: Maybe<Array<ZoneTypeFilterZoneFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneTypeInput = {
  key: Scalars['String'];
  description: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type ZoneTypeMaxAggregate = {
  __typename?: 'ZoneTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneTypeMinAggregate = {
  __typename?: 'ZoneTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneTypeSort = {
  field: ZoneTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ZoneTypeSortFields {
  Id = 'id',
  Key = 'key',
  Description = 'description',
  Active = 'active',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  DeletedBy = 'deletedBy',
  CreatedById = 'createdById',
  UpdatedById = 'updatedById',
  DeletedById = 'deletedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt'
}

export type ZoneTypeUpdate = {
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneTypeUpdateFilter = {
  and?: Maybe<Array<ZoneTypeUpdateFilter>>;
  or?: Maybe<Array<ZoneTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  key?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ZoneTypeZonesAggregateResponse = {
  __typename?: 'ZoneTypeZonesAggregateResponse';
  count?: Maybe<ZoneTypeZonesCountAggregate>;
  sum?: Maybe<ZoneTypeZonesSumAggregate>;
  avg?: Maybe<ZoneTypeZonesAvgAggregate>;
  min?: Maybe<ZoneTypeZonesMinAggregate>;
  max?: Maybe<ZoneTypeZonesMaxAggregate>;
};

export type ZoneTypeZonesAvgAggregate = {
  __typename?: 'ZoneTypeZonesAvgAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ZoneTypeZonesConnection = {
  __typename?: 'ZoneTypeZonesConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Zone>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ZoneTypeZonesCountAggregate = {
  __typename?: 'ZoneTypeZonesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  zoneTypeId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  parentZoneId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ZoneTypeZonesMaxAggregate = {
  __typename?: 'ZoneTypeZonesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneTypeZonesMinAggregate = {
  __typename?: 'ZoneTypeZonesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneTypeZonesSumAggregate = {
  __typename?: 'ZoneTypeZonesSumAggregate';
  sort?: Maybe<Scalars['Float']>;
};

export type ZoneUpdate = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zoneTypeId?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['String']>;
  parentZoneId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ZoneUpdateFilter = {
  and?: Maybe<Array<ZoneUpdateFilter>>;
  or?: Maybe<Array<ZoneUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  zoneTypeId?: Maybe<StringFieldComparison>;
  warehouseId?: Maybe<StringFieldComparison>;
  parentZoneId?: Maybe<StringFieldComparison>;
  active?: Maybe<BooleanFieldComparison>;
  sort?: Maybe<NumberFieldComparison>;
  createdBy?: Maybe<StringFieldComparison>;
  updatedBy?: Maybe<StringFieldComparison>;
  deletedBy?: Maybe<StringFieldComparison>;
  createdById?: Maybe<StringFieldComparison>;
  updatedById?: Maybe<StringFieldComparison>;
  deletedById?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type _Service = {
  __typename?: '_Service';
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars['String']>;
};

export type CreateManyActionsMutationVariables = Exact<{
  input: CreateManyActionsInput;
  authToken: Scalars['String'];
}>;


export type CreateManyActionsMutation = (
  { __typename?: 'Mutation' }
  & { createManyActions?: Maybe<(
    { __typename?: 'CreateManyActionsResponse' }
    & { manifest: (
      { __typename?: 'Manifest' }
      & Pick<Manifest, 'id' | 'packages'>
    ) }
  )> }
);

export type GetAuthorizationTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthorizationTokenQuery = (
  { __typename?: 'Query' }
  & { getAuthorizationToken?: Maybe<(
    { __typename?: 'AuthorizationToken' }
    & Pick<AuthorizationToken, 'authToken'>
  )> }
);

export type GetCatalogZonesAndActionsQueryVariables = Exact<{
  filter: ZoneFilter;
  paging?: Maybe<OffsetPaging>;
}>;


export type GetCatalogZonesAndActionsQuery = (
  { __typename?: 'Query' }
  & { zones: (
    { __typename?: 'ZoneConnection' }
    & { nodes: Array<(
      { __typename?: 'Zone' }
      & Pick<Zone, 'id' | 'name'>
      & { parentZone?: Maybe<(
        { __typename?: 'Zone' }
        & Pick<Zone, 'name'>
      )>, warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'name'>
      )>, forbiddenActionTypes?: Maybe<(
        { __typename?: 'ZoneForbiddenActionTypesConnection' }
        & { nodes: Array<(
          { __typename?: 'ZoneActionTypeBlacklist' }
          & Pick<ZoneActionTypeBlacklist, 'id'>
          & { actionType: (
            { __typename?: 'ActionType' }
            & Pick<ActionType, 'name'>
          ) }
        )> }
      )>, subZones?: Maybe<(
        { __typename?: 'ZoneSubZonesConnection' }
        & { nodes: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'id' | 'name'>
        )> }
      )>, forbiddenZones?: Maybe<(
        { __typename?: 'ZoneForbiddenZonesConnection' }
        & { nodes: Array<(
          { __typename?: 'ZoneBlacklist' }
          & Pick<ZoneBlacklist, 'id' | 'zoneId' | 'forbiddenZoneId'>
        )> }
      )> }
    )> }
  ), actionTypes: (
    { __typename?: 'ActionTypeConnection' }
    & { nodes: Array<(
      { __typename?: 'ActionType' }
      & Pick<ActionType, 'id' | 'name'>
    )> }
  ) }
);

export type GetManifestsQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type GetManifestsQuery = (
  { __typename?: 'Query' }
  & { manifests: (
    { __typename?: 'ManifestConnection' }
    & { nodes: Array<(
      { __typename?: 'Manifest' }
      & Pick<Manifest, 'id' | 'createdAt' | 'packages' | 'authorizedByName' | 'comments'>
      & { warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'name'>
      )> }
    )> }
  ) }
);

export type GetPackageInfoQueryVariables = Exact<{
  trackingNumber: Scalars['String'];
}>;


export type GetPackageInfoQuery = (
  { __typename?: 'Query' }
  & { package?: Maybe<(
    { __typename?: 'Package' }
    & Pick<Package, 'deliveryId' | 'deliveryStatusId' | 'deliveryStatusName' | 'trackingNumber' | 'referenceId' | 'zipCode' | 'routeId' | 'routeStatusId' | 'routeStatusName' | 'zoneId' | 'zoneName' | 'zoneHub' | 'zoneCoverage' | 'clientId' | 'clientName' | 'routeSequence' | 'multiguideSequence' | 'cancelationReasonId' | 'cancelationReasonName'>
  )> }
);

export type GetPrintersAndGatewaysQueryVariables = Exact<{
  warehouseId: Scalars['String'];
}>;


export type GetPrintersAndGatewaysQuery = (
  { __typename?: 'Query' }
  & { printGateways: (
    { __typename?: 'PrintGatewayConnection' }
    & { nodes: Array<(
      { __typename?: 'PrintGateway' }
      & Pick<PrintGateway, 'id' | 'name' | 'deviceName'>
    )> }
  ), printers: (
    { __typename?: 'PrinterConnection' }
    & { nodes: Array<(
      { __typename?: 'Printer' }
      & Pick<Printer, 'id' | 'name' | 'ip'>
    )> }
  ) }
);

export type GetUnfinishedMovementsQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type GetUnfinishedMovementsQuery = (
  { __typename?: 'Query' }
  & { stockAggregate: (
    { __typename?: 'StockAggregateResponse' }
    & { count?: Maybe<(
      { __typename?: 'StockCountAggregate' }
      & Pick<StockCountAggregate, 'id'>
    )> }
  ) }
);

export type GetWarehousesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWarehousesQuery = (
  { __typename?: 'Query' }
  & { warehouses: (
    { __typename?: 'WarehouseConnection' }
    & { nodes: Array<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name'>
    )> }
  ) }
);


export const CreateManyActionsDocument = `
    mutation createManyActions($input: CreateManyActionsInput!, $authToken: String!) {
  createManyActions(authToken: $authToken, input: $input) {
    manifest {
      id
      packages
    }
  }
}
    `;
export const useCreateManyActionsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateManyActionsMutation, TError, CreateManyActionsMutationVariables, TContext>
    ) =>
    useMutation<CreateManyActionsMutation, TError, CreateManyActionsMutationVariables, TContext>(
      (variables?: CreateManyActionsMutationVariables) => fetcher<CreateManyActionsMutation, CreateManyActionsMutationVariables>(client, CreateManyActionsDocument, variables)(),
      options
    );
export const GetAuthorizationTokenDocument = `
    query getAuthorizationToken {
  getAuthorizationToken {
    authToken
  }
}
    `;
export const useGetAuthorizationTokenQuery = <
      TData = GetAuthorizationTokenQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAuthorizationTokenQueryVariables,
      options?: UseQueryOptions<GetAuthorizationTokenQuery, TError, TData>
    ) =>
    useQuery<GetAuthorizationTokenQuery, TError, TData>(
      ['getAuthorizationToken', variables],
      fetcher<GetAuthorizationTokenQuery, GetAuthorizationTokenQueryVariables>(client, GetAuthorizationTokenDocument, variables),
      options
    );
export const GetCatalogZonesAndActionsDocument = `
    query getCatalogZonesAndActions($filter: ZoneFilter!, $paging: OffsetPaging) {
  zones(filter: $filter, paging: $paging) {
    nodes {
      id
      name
      parentZone {
        name
      }
      warehouse {
        name
      }
      forbiddenActionTypes {
        nodes {
          id
          actionType {
            name
          }
        }
      }
      subZones {
        nodes {
          id
          name
        }
      }
      forbiddenZones {
        nodes {
          id
          zoneId
          forbiddenZoneId
        }
      }
    }
  }
  actionTypes(filter: {active: {is: true}}) {
    nodes {
      id
      name
    }
  }
}
    `;
export const useGetCatalogZonesAndActionsQuery = <
      TData = GetCatalogZonesAndActionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCatalogZonesAndActionsQueryVariables,
      options?: UseQueryOptions<GetCatalogZonesAndActionsQuery, TError, TData>
    ) =>
    useQuery<GetCatalogZonesAndActionsQuery, TError, TData>(
      ['getCatalogZonesAndActions', variables],
      fetcher<GetCatalogZonesAndActionsQuery, GetCatalogZonesAndActionsQueryVariables>(client, GetCatalogZonesAndActionsDocument, variables),
      options
    );
export const GetManifestsDocument = `
    query getManifests($email: String) {
  manifests(
    filter: {createdBy: {eq: $email}}
    sorting: [{field: createdAt, direction: DESC}]
  ) {
    nodes {
      id
      createdAt
      packages
      authorizedByName
      comments
      warehouse {
        name
      }
    }
  }
}
    `;
export const useGetManifestsQuery = <
      TData = GetManifestsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetManifestsQueryVariables,
      options?: UseQueryOptions<GetManifestsQuery, TError, TData>
    ) =>
    useQuery<GetManifestsQuery, TError, TData>(
      ['getManifests', variables],
      fetcher<GetManifestsQuery, GetManifestsQueryVariables>(client, GetManifestsDocument, variables),
      options
    );
export const GetPackageInfoDocument = `
    query getPackageInfo($trackingNumber: String!) {
  package(trackingNumber: $trackingNumber) {
    deliveryId
    deliveryStatusId
    deliveryStatusName
    trackingNumber
    referenceId
    zipCode
    routeId
    routeStatusId
    routeStatusName
    zoneId
    zoneName
    zoneHub
    zoneCoverage
    clientId
    clientName
    routeSequence
    multiguideSequence
    cancelationReasonId
    cancelationReasonName
  }
}
    `;
export const useGetPackageInfoQuery = <
      TData = GetPackageInfoQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetPackageInfoQueryVariables,
      options?: UseQueryOptions<GetPackageInfoQuery, TError, TData>
    ) =>
    useQuery<GetPackageInfoQuery, TError, TData>(
      ['getPackageInfo', variables],
      fetcher<GetPackageInfoQuery, GetPackageInfoQueryVariables>(client, GetPackageInfoDocument, variables),
      options
    );
export const GetPrintersAndGatewaysDocument = `
    query getPrintersAndGateways($warehouseId: String!) {
  printGateways(filter: {warehouseId: {eq: $warehouseId}}) {
    nodes {
      id
      name
      deviceName
    }
  }
  printers(filter: {warehouseId: {eq: $warehouseId}}) {
    nodes {
      id
      name
      ip
    }
  }
}
    `;
export const useGetPrintersAndGatewaysQuery = <
      TData = GetPrintersAndGatewaysQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetPrintersAndGatewaysQueryVariables,
      options?: UseQueryOptions<GetPrintersAndGatewaysQuery, TError, TData>
    ) =>
    useQuery<GetPrintersAndGatewaysQuery, TError, TData>(
      ['getPrintersAndGateways', variables],
      fetcher<GetPrintersAndGatewaysQuery, GetPrintersAndGatewaysQueryVariables>(client, GetPrintersAndGatewaysDocument, variables),
      options
    );
export const GetUnfinishedMovementsDocument = `
    query getUnfinishedMovements($email: String) {
  stockAggregate(
    filter: {and: [{actionTypeName: {eq: "PENDIENTE"}}, {createdBy: {eq: $email}}, {deletedAt: {is: null}}]}
  ) {
    count {
      id
    }
  }
}
    `;
export const useGetUnfinishedMovementsQuery = <
      TData = GetUnfinishedMovementsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUnfinishedMovementsQueryVariables,
      options?: UseQueryOptions<GetUnfinishedMovementsQuery, TError, TData>
    ) =>
    useQuery<GetUnfinishedMovementsQuery, TError, TData>(
      ['getUnfinishedMovements', variables],
      fetcher<GetUnfinishedMovementsQuery, GetUnfinishedMovementsQueryVariables>(client, GetUnfinishedMovementsDocument, variables),
      options
    );
export const GetWarehousesDocument = `
    query getWarehouses {
  warehouses {
    nodes {
      id
      name
    }
  }
}
    `;
export const useGetWarehousesQuery = <
      TData = GetWarehousesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetWarehousesQueryVariables,
      options?: UseQueryOptions<GetWarehousesQuery, TError, TData>
    ) =>
    useQuery<GetWarehousesQuery, TError, TData>(
      ['getWarehouses', variables],
      fetcher<GetWarehousesQuery, GetWarehousesQueryVariables>(client, GetWarehousesDocument, variables),
      options
    );
