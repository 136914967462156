import React, {useEffect, useState} from 'react';
import {storage} from '../storage';

interface AppContextProps {
  children: React.ReactNode;
}

const context = {
  packages: [],
  whId: '',
  whTime: '',
  token: '',
  manifestData: {
    ids: [],
    count: '',
    guides: [],
  },
  movementObj: {
    warehouseId: '2C0rlWPF_mUJvRnGE7PEPuir',
    warehouseName: 'Hub Mex',
    comments: '',
    transactions: [
      {
        zoneId: '_XX69xmzeSuxpKgJtYFeHrld',
        zoneName: 'Recolecciones',
        actionTypeId: '5dBfS-Fv3yL48Q1QesBQR3UM',
        actionTypeName: 'ENTRY_TO_WAREHOUSE',
        comments: '',
        packages: [
          {
            trackingNumber: '00187456GHT6789',
            referenceId: 'aa',
            clientId: 'aa',
          },
          {
            trackingNumber: '00187456GHT6790',
            referenceId: 'ab',
            clientId: 'aa',
          },
          {
            trackingNumber: '00187456GHT6791',
            referenceId: 'ac',
            clientId: 'aa',
          },
        ],
      },
      {
        zoneId: 'cjxymwHX-25ksl7UEcLJlxfn',
        zoneName: 'Rutas',
        actionTypeId: 'eEETxPt7uFqlPIQHOZXn_1en',
        actionTypeName: 'DEPARTURE',
        comments: '',
        packages: [
          {
            trackingNumber: '00187456GHT6782',
            referenceId: 'ba',
            clientId: 'bb',
          },
          {
            trackingNumber: '00187456GHT6783',
            referenceId: 'bb',
            clientId: 'bb',
          },
          {
            trackingNumber: '00187456GHT6784',
            referenceId: 'bc',
            clientId: 'bb',
          },
        ],
      },
      {
        zoneId: 'Xj4grt2IkKGIVYmUcg-JmrUO',
        zoneName: 'Resguardos',
        actionTypeId: 'EkgOknIHjHvBuuWtYnlMcxnu',
        actionTypeName: 'ENTRY_TO_ZONE',
        comments: '',
        packages: [
          {
            trackingNumber: '00187456GHT6221',
            referenceId: 'ca',
            clientId: 'cc',
          },
          {
            trackingNumber: '00187456GHT6222',
            referenceId: 'cb',
            clientId: 'cc',
          },
          {
            trackingNumber: '00187456GHT6223',
            referenceId: 'cc',
            clientId: 'cc',
          },
        ],
      },
    ],
  },
  subFootOptions: {
    printer: '',
    zonePrint: '',
  },
  printOption: false,
  insertOption: false,
  keepings: false,
  manifestExceeds: [],
  manifestMissing: [],
  errorsButton: false, // TO DO: Verificar si se le modifica y limpia correctamente.
};
const BaseContext = React.createContext(context);
const BaseProvider = BaseContext.Provider;

const AppContext = ({children}: AppContextProps) => {
  const [packages, setPackages] = useState(context.packages);
  const [whId, setWarehouseId] = useState(context.whId);
  const [whTime, setWarehouseTime] = useState(context.whTime);
  const [token, setToken] = useState(context.token);
  const [manifestData, setManifestData] = useState(context.manifestData);
  const [movementObj, setMovementObj] = useState(context.movementObj);
  const [subFootOptions, setSubFootOptions] = useState(context.subFootOptions);
  const [printOption, setPrintOption] = useState(context.printOption);
  const [insertOption, setInsertOption] = useState(context.printOption);
  const [manifestExceeds, setManifestExceeds] = useState(
    context.manifestExceeds,
  );
  const [manifestMissing, setManifestMissing] = useState(
    context.manifestMissing,
  );
  const [errorsButton, setErrorsButton] = useState(context.errorsButton);
  const [keepings, setKeepings] = useState(context.keepings);
  useEffect(() => {
    storage
      .load({
        key: 'packagesList',
      })
      .then((r) => {
        if (r) {
          setPackages(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'packagesList',
              data: context.packages,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'whId',
      })
      .then((r) => {
        if (r) {
          setWarehouseId(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'whId',
              data: context.whId,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'whTime',
      })
      .then((r) => {
        if (r) {
          setWarehouseTime(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'whTime',
              data: context.whTime,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'token',
      })
      .then((r) => {
        if (r) {
          setToken(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'token',
              data: context.token,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'movementObj',
      })
      .then((r) => {
        if (r) {
          setMovementObj(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'movementObj',
              data: context.movementObj,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'subFootOptions',
      })
      .then((r) => {
        if (r) {
          setSubFootOptions(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'subFootOptions',
              data: context.subFootOptions,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    // HERE ###################
    storage
      .load({
        key: 'manifestData',
      })
      .then((r) => {
        if (r && r.ids) {
          setManifestData(r);
        } else {
          let e = new Error('NotFoundError'); // e.name es 'Error'
          e.name = 'NotFoundError';
          throw e;
        }
      })
      .catch((err) => {
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'manifestData',
              data: context.manifestData,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'printOption',
      })
      .then((r) => {
        if (r) {
          setPrintOption(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'printOption',
              data: context.printOption,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'insertOption',
      })
      .then((r) => {
        if (r) {
          setInsertOption(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'insertOption',
              data: context.insertOption,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });

    storage
      .load({
        key: 'keepings',
      })
      .then((r) => {
        if (r) {
          setKeepings(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'keepings',
              data: context.keepings,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });

    storage
      .load({
        key: 'manifestExceeds',
      })
      .then((r) => {
        if (r) {
          setManifestExceeds(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'manifestExceeds',
              data: context.manifestExceeds,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });

    storage
      .load({
        key: 'manifestMissing',
      })
      .then((r) => {
        if (r) {
          setManifestMissing(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'manifestMissing',
              data: context.manifestMissing,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
    storage
      .load({
        key: 'errorsButton',
      })
      .then((r) => {
        if (r) {
          setErrorsButton(r);
        }
      })
      .catch((err) => {
        console.warn(err.message);
        switch (err.name) {
          case 'NotFoundError':
            storage.save({
              key: 'errorsButton',
              data: context.errorsButton,
              expires: null,
            });
            break;
          case 'ExpiredError':
            // TODO
            break;
        }
      });
  }, []);

  return (
    <BaseProvider
      value={{
        packages,
        whId,
        whTime,
        token,
        manifestData,
        movementObj,
        subFootOptions,
        insertOption,
        printOption,
        manifestExceeds,
        manifestMissing,
        errorsButton,
        keepings,
      }}>
      {children}
    </BaseProvider>
  );
};

export {BaseContext, AppContext};
