import {StyleSheet} from 'react-native';

export const makeStyles = (): any => {
  return StyleSheet.create({
    container: {
      width: '100%',
      maxWidth: 450,
      height: 'auto',
      alignItems: 'center',
      alignSelf: 'center',
    },
    headerContainer: {
      width: 'fit-content',
      height: 'auto',
    },
    headerText: {
      fontSize: 13,
      fontWeight: '500',
      color: 'grey',
    },
    fastModeSelectsContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginVertical: 20,
    },
    fastModeSelects: {
      width: 'fit-content',
      height: '100%',
      margin: 0,
      alignItems: 'center',
    },
    selectsLabel: {
      marginBottom: 5,
      fontSize: 14,
      fontWeight: 700,
    },
  });
};
