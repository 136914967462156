import {GraphQLClient, gql} from 'graphql-request';
import {SCHEMA_PATH_URL, GET_PACKAGE_URL} from '../../../config';
import {storage} from '../../../storage';

//@ts-ignore
const token = storage.cache.token;
const client: GraphQLClient = new GraphQLClient(SCHEMA_PATH_URL);

client.setHeader('Content-Type', 'application/json');
client.setHeader(
  'ivoy-user',
  '{"userId":"xQUcfs0w8Mo2Xf3CFzspC4DP","userEmail":"angel.romero@ivoy.mx","userRole":"user","permissions":[{"action":"all","subject":"all.all"}]}',
);
export default client;

export async function getPackageInfo(trackingNumber: string) {
  const query = gql`
    query getPackageInfo($trackingNumber: String!) {
      package(trackingNumber: $trackingNumber) {
        cancelationReasonId
        cancelationReasonName
        deliveryId
        deliveryStatusId
        deliveryStatusName
        trackingNumber
        referenceId
        zipCode
        routeId
        routeStatusId
        routeStatusName
        zoneId
        zoneName
        zoneHub
        zoneCoverage
        clientId
        zoneHub
        clientName
        routeSequence
        multiguideSequence
      }
    }
  `;

  const variables = {
    trackingNumber,
  };
  const data = await client.request(query, variables);
  return data;
}

export const fetchPackageInfo = async (trackingNumber: string) => {
  const url = `${GET_PACKAGE_URL}${trackingNumber}`;
  const response = await fetch(url)
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err));
  return response;
};

export async function getManifestByIdInfo(id: string) {
  const query = gql`
    query getManifestById($id: ID!) {
      manifest(id: $id) {
        packages
      }
    }
  `;
  const variables = {
    id,
  };
  const data = await client.request(query, variables);
  return data;
}
