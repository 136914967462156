import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  color: ColorValue;
}

export const makeStyles = ({color}: StyleProps): any => {
  return StyleSheet.create({
    modal: {
      paddingTop: 30,
      paddingHorizontal: 20,
      width: '50%',
      minWidth: 270,
      maxWidth: 400,
      minHeight: 200,
      height: 'fit-content',
      maxHeight: 800,
      paddingBottom: 15,
      textAlign: 'center',
    },
    containerLoader: {
      flex: 1,
    },
    iconContainer: {
      width: 60,
      height: 60,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#1e336a',
      borderRadius: 50,
      position: 'absolute',
      top: -30,
      zIndex: 1,
    },
    icon: {
      color: '#fff',
      fontWeight: '700',
    },
    indicator: {
      width: '100%',
      height: 7,
      backgroundColor: '#1e336a',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
    },
    header: {
      fontSize: 24,
      fontWeight: '700',
      color: '#383434',
    },
    textInstruction: {
      color: color,
    },
    manifestsContainer: {
      width: '100%',
      maxWidth: 280,
      height: 'fit-content',
      marginVertical: 5,
    },
    manifestListTitle: {
      color: '#383434',
      fontSize: 12,
    },
    manifestsHeaderContainer: {
      width: '100%',
      height: 'fit-content',
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: 10,
    },
    cleanButtonCont: {
      position: 'absolute',
      right: 0,
      top: -5,
    },
    cleanManifestsButton: {
      flex: 1,
      borderRadius: 4,
      padding: 10,
      marginBottom: 2,
      backgroundColor: '#000',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cleanButtonStyleCont: {
      width: 'fit-content',
    },
    cleanButtonStyle: {
      flex: 1,
      paddingHorizontal: 7,
    },
    cleanButtonTitle: {
      marginTop: 0,
      fontSize: 11,
    },
    scrollManifestsContainer: {
      width: '100%',
      minHeight: 20,
      height: 'fit-content',
      maxHeight: 100,
      marginVertical: 5,
    },
    manifestContainer: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 4,
      paddingHorizontal: 10,
      paddingVertical: 3,
      borderRadius: 4,
      backgroundColor: '#eef0f7',
    },
    manifestIcon: {
      color: '#D0021B',
    },
    searchContainer: {
      height: 'fit-content',
      width: '100%',
      maxWidth: 280,
      justifyContent: 'space-around',
      marginTop: 5,
      marginBottom: 10,
    },
    inputSearchContainer: {
      flexDirection: 'row',
      width: '100%',
      height: 32,
      marginTop: 10,
      alignItems: 'center',
      justifyContent: 'space-around',
      borderColor: color,
      borderWidth: 1,
      borderRadius: 4,
    },
    input: {
      width: '80%',
      borderRadius: 4,
      textAlignVertical: 'center',
      height: 26,
      color: 'black',
      fontSize: 11,
      fontWeight: 'medium',
      textAlign: 'left',
    },
    searchButtonContainer: {
      width: 32,
      height: 32,
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchIcon: {
      color: '#000',
    },
    continueButtonContainer: {
      width: 100,
      height: 32,
    },
    continueButton: {
      flex: 1,
      backgroundColor: '#000',
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
    },
    continueButtonTitle: {
      marginTop: 0,
    },
  });
};
