import React, {useState, useCallback, useEffect} from 'react';

import {
  Footer,
  Header,
  ContainerScreen,
  SideMenu,
  PrimaryIconLink,
} from '../../components';
import {useHistory} from 'react-router';
import {Platform, Alert} from 'react-native';
import {ScanCategoriesContent} from '../../components';
import {makeStyles} from './index.styles';
import {saveOnStorage, getStorageItem} from '../../common/utils';
import * as Permissions from 'expo-permissions';

export const ScanCategories = () => {
  const whId = getStorageItem('whId');
  const whTime = getStorageItem('whTime');
  const keepingsData = getStorageItem('keepings');
  const history = useHistory();
  const [sideMenu, setSideMenu] = useState(false);
  const styles = makeStyles({backgroundColor: '#DEE3F3'});
  const sideMenuOptions = [{name: 'Home', route: '/home', icon: 'ios-home'}];
  const optionsFooter = [
    {name: 'Home', icon: 'ios-home', route: '/home'},
    {
      name: 'Siguiente',
      icon: 'ios-arrow-forward',
      route: '/movement',
    },
  ];

  const handlePressKeepings = () => {
    if (!keepingsData) {
      saveOnStorage('packagesList', []);
    }
    saveOnStorage('keepings', true);
    history.push('/search/fast-search');
  };

  const options = [
    {
      name: 'Rápida',
      icon: 'ios-flash',
      style: styles.options,
      iconStyle: styles.iconStyle,
      onPress: () => {
        history.push('/search/fast-search');
        saveOnStorage('keepings', false);
      },
    },
    {
      name: 'Movimientos',
      icon: 'md-barcode-outline',
      style: styles.options,
      iconStyle: styles.iconStyle,
      route: '/search/consolidated-search',
    },
    {
      name: 'Registro de personal',
      icon: 'md-card-outline',
      style: styles.options,
      iconStyle: styles.iconStyle,
      route: '/personnel-record',
    },
    {
      name: 'Resguardos',
      icon: 'archive',
      style: styles.options,
      iconStyle: styles.iconStyle,
      onPress: handlePressKeepings,
    },
  ];

  const iconInfo = {
    iconName: 'apps-outline',
    iconColor: '#942E83',
    iconSize: 16,
    iconStyle: styles.categoryIcon,
  };

  const textInfo = {
    textContent: 'Categorías iVoy',
    textColor: '#121212',
    fontSize: 16,
    fontWeight: 700,
  };

  const handleSideMenu = () => {
    setSideMenu(!sideMenu);
  };

  const isWhInvalid: () => boolean = useCallback((): boolean => {
    const whMinsDuration: number = 60;
    const isNotWhSelected: boolean = whId === '';
    const isWhExpired: boolean =
      !whTime || Date.now() - whTime >= whMinsDuration * 60 * 1000;

    return isNotWhSelected || isWhExpired;
  }, [whId, whTime]);

  useEffect(() => {
    if (isWhInvalid()) {
      const message: string =
        'No se ha seleccionado un almacen o su última selección expiró. Seleccione el almacen donde va a operar.';

      Platform.OS === ('android' || 'ios')
        ? Alert.alert('Almacen', message)
        : //@ts-ignore
          alert(message);

      saveOnStorage('whId', '');
      history.push('/home');
    }
  }, [isWhInvalid, history]);

  return (
    <>
      {sideMenu && (
        <SideMenu
          sideMenuOptions={sideMenuOptions}
          onPress={() => console.log('pressed')}
          onPressOutside={() => handleSideMenu()}
        />
      )}
      <ContainerScreen
        header={
          <Header
            title={'Escaneo'}
            style={styles.header}
            complement={
              <PrimaryIconLink
                onPress={handleSideMenu}
                style={styles.menuIconStyle}
                iconName={'menu'}
                iconSize={28}
              />
            }
          />
        }
        footer={<Footer options={optionsFooter} />}>
        <ScanCategoriesContent
          options={options}
          iconInfo={iconInfo}
          textInfo={textInfo}
        />
      </ContainerScreen>
    </>
  );
};
