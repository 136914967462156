import {StyleSheet, ColorValue} from 'react-native';

interface StyleProps {
  backgroundColor?: ColorValue;
}

export const makeStyles = ({backgroundColor}: StyleProps): any => {
  return StyleSheet.create({
    header: {
      backgroundColor,
    },
    menuIconStyle: {
      position: 'absolute',
      right: 15,
    },
    searchButton: {
      width: 37,
      height: 37,
      backgroundColor: '#000',
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchIcon: {
      color: '#fff',
      fontWeight: '700',
    },
    categoryIcon: {
      marginRight: 7,
    },
  });
};
