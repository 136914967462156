import {StyleSheet, Dimensions} from 'react-native';
const ScreenHeight = Dimensions.get('window').height;

interface StyleProps {}

export const makeStyles = ({}: StyleProps): any => {
  return StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      height: ScreenHeight,
    },
  });
};
