import React, {useState} from 'react';
import {View, ColorValue} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

import {
  DropdownList,
  Modal,
  PrimaryButton,
  PrimaryIndicator,
  PrimaryText,
} from '../../';
import {makeStyles} from './index.styles';

interface ModalKeepingsProps {
  currentCancelStatus: string;
  cancelId: string;
  onChange: (id: string) => void;
  handleSave: () => Promise<{errors: boolean}>;
  handlePressOutside?: () => void;
}

export const ModalKeepingsSelection = ({
  currentCancelStatus,
  cancelId,
  onChange,
  handleSave,
  handlePressOutside,
}: ModalKeepingsProps) => {
  const [outputColor, setOutputColor] = useState<ColorValue>(
    currentCancelStatus ? '#f8ecf0' : '#eef0f7',
  );
  const [outputText, setOutputText] = useState<string>(
    currentCancelStatus
      ? 'El paquete ya cuenta con razón de cancelación preseleccionada.'
      : 'Indique a continuación la sub zona del paquete.',
  );
  const [borderColor, setBorderColor] = useState<ColorValue>('#fff');
  const styles = makeStyles({color: outputColor, borderColor});

  const optionCancelation = [
    {id: '0', name: ''},
    {id: '4', name: 'CLIENTE AUSENTE'},
    {id: '5', name: 'REF'},
    {id: '6', name: 'PUNTERO MALO'},
  ];

  const handleSaveModal = async () => {
    await handleSave()
      .then((r: {errors: boolean}) => {
        const {errors} = r;
        if (errors) {
          setOutputColor('#f87b83');
          setOutputText('Por favor seleccione sub zona válida.');
          setBorderColor('#D0021B');
        } else {
          setOutputColor('#ace0a5');
          setOutputText('Se ha guardado la sub zona. Redireccionando...');
          setBorderColor('#48BF4D');
        }
      })
      .catch((er) => {
        console.error('Something went wrong while saving. Error:', er);
        setOutputColor('#f87b83');
        setOutputText('Ha habido un error. Por favor recargue la página.');
        setBorderColor('#D0021B');
      });
  };

  return (
    <Modal onPressOutside={handlePressOutside}>
      <View style={styles.iconContainer}>
        <Icon name={'archive'} size={32} style={styles.icon} />
      </View>
      <PrimaryIndicator style={styles.indicator} />
      <PrimaryText content={'Resguardos'} style={styles.header} />
      <View style={styles.container}>
        <PrimaryText style={styles.highlightedText} content={outputText} />
        <View style={styles.subZoneContainer}>
          <PrimaryText content={'Sub zona'} style={styles.labelText} />
          <View style={styles.dropdownContainer}>
            <DropdownList
              backgroundColor={'#000'}
              options={optionCancelation}
              selectedValue={cancelId}
              onChange={(id: string) => onChange(id)}
            />
          </View>
        </View>
        <View style={styles.buttonsContainer}>
          {handlePressOutside && (
            <View style={styles.buttonContainer}>
              <PrimaryButton
                styleButton={styles.continueButton}
                title={'Continuar'}
                onPress={handlePressOutside}
              />
            </View>
          )}
          <View style={styles.buttonContainer}>
            <PrimaryButton title={'Guardar'} onPress={handleSaveModal} />
          </View>
        </View>
      </View>
    </Modal>
  );
};
