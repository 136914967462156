import React from 'react';
import {View, Text, TextInput} from 'react-native';
import {makeStyles} from './index.styles';

interface ComentariesInputProps {
  style?: any;
  onChange(e: any): void;
  placeholder?: string;
  value?: string;
  type?: string;
  onKeyPress?: (args: any) => void;
  numberOfLines?: number;
  autofocus?: boolean;
}

export const Input = ({
  type,
  style,
  placeholder,
  value,
  onChange,
  onKeyPress,
  numberOfLines,
  autofocus,
}: ComentariesInputProps) => {
  const styles = makeStyles(style);

  if (type === 'textarea') {
    return (
      <View style={[styles.container, style]}>
        <TextInput
          style={styles.textarea}
          placeholder={placeholder}
          value={value}
          onChangeText={onChange}
          onKeyPress={onKeyPress}
          multiline={true}
          numberOfLines={numberOfLines}
          placeholderTextColor={styles.placeholder.color}
        />
      </View>
    );
  } else {
    return (
      <View style={[styles.containerLogin, style]}>
        <TextInput
          value={value}
          onChangeText={onChange}
          onKeyPress={onKeyPress}
          style={styles.passwordFiller}
          placeholder={placeholder}
          placeholderTextColor={styles.placeholder.color}
          numberOfLines={1}
          autoFocus={autofocus}
        />
      </View>
    );
  }
};

Input.defaultProps = {
  style: {
    borderWidth: 1,
    borderColor: '#000000',
    borderRadius: 4,
  },
  onChange: (e: any) => console.log(e),
  placeholder: 'Placeholder',
  type: 'textarea',
  title: '',
  autofocus: false,
};
